import React from "react";
import { Modal } from "antd";
import TabSearch from "../tabsearch";
import ModalSearchComponentWrapper from "./modalsearch";
import { useSelector, useDispatch } from "react-redux";
import { clearModalNewsSearchData } from "../../containers/app/actions";
import CloseIcon from "../../images/search/Close.svg";
import "./modalsearch.scss";

const ModalSearchComponent = ({ setIsSearchModalOpen, isSearchModalOpen }) => {
  // user to invoke redux actions
  const dispatch = useDispatch();
  //get data from redux store
  const modalSerachNewsData = useSelector(
    (state) => state.appReducer.modalSerachNewsData
  );
  const modalNewsCount = useSelector(
    (state) => state.appReducer.modalNewsCount
  );

  const modalLoader = useSelector((state) => state.appReducer.modalLoader);
  const handleOk = () => {
    setIsSearchModalOpen(false);
  };
  const handleCancel = () => {
    setIsSearchModalOpen(false);
    dispatch(clearModalNewsSearchData());
  };

  const onCloseModal = () => {
    setIsSearchModalOpen(false);
    dispatch(clearModalNewsSearchData());
  };

  return (
    <>
      <ModalSearchComponentWrapper>
        <div>
          <Modal
            className="search-modalsearch"
            open={isSearchModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width="100vw"
            zIndex={9999}
            style={{ zIndex: 9999 }}
            footer={null}
            closeIcon={
              <img
                src={CloseIcon}
                width={26}
                height={26}
                alt={"modal close icon"}
                className="close-icon"
              />
            }
          >
            <ModalSearchComponentWrapper>
              <div className="modalsearch-div">
                <TabSearch
                  modalSerachNewsData={modalSerachNewsData}
                  closeModal={() => onCloseModal()}
                  modalNewsCount={modalNewsCount}
                  modalLoader={modalLoader}
                />
              </div>
            </ModalSearchComponentWrapper>
          </Modal>
        </div>
      </ModalSearchComponentWrapper>
    </>
  );
};
export default ModalSearchComponent;
