import {
  GET_CONTACTPAGE_DATA,
  GET_CONTACTPAGE_DATA_SUCCESS,
  GET_CONTACTPAGE_DATA_FAIL,
} from "./constants";

export const getContactPageData = (data) => ({
  type: GET_CONTACTPAGE_DATA,
  data,
});

export const getContactPageDataSuccess = (data) => ({
  type: GET_CONTACTPAGE_DATA_SUCCESS,
  data,
});

export const getContactPageDataFail = (data) => ({
  type: GET_CONTACTPAGE_DATA_FAIL,
  data,
});
