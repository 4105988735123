import { takeEvery, put } from "redux-saga/effects";
import { GET_CAREERS_CONTACTUS_DATA, GET_CAREERS_DATA } from "./constants";
import { get } from "../../utils/api";
import {
  getCareersDataSuccess,
  getCareersDataFail,
  getCareersContactUsDataSuccess,
  getCareersContactUsDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getCareersData() {
  let data = yield get("/bwcms/page/careers")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const careersData = yield data.data;
    yield put(updateLanguageData(careersData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getCareersDataSuccess(careersData));
  } else {
    yield put(getCareersDataFail(data));
  }
}

function* getCareersContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getCareersContactUsDataSuccess(contactData));
  } else {
    yield put(getCareersContactUsDataFail(data));
  }
}

function* careersSaga() {
  yield takeEvery(GET_CAREERS_CONTACTUS_DATA, getCareersContactUs);
  yield takeEvery(GET_CAREERS_DATA, getCareersData);
}

export default careersSaga;
