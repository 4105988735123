import {
  GET_ABOUTUS_DATA,
  GET_ABOUTUS_DATA_SUCCESS,
  GET_ABOUTUS_DATA_FAIL,
  GET_ABOUTUS_CONTACTUS_DATA,
  GET_ABOUTUS_CONTACTUS_DATA_SUCCESS,
  GET_ABOUTUS_CONTACTUS_DATA_FAIL,
  GET_ABOUTUS_TESTIMONIALS_DATA,
  GET_ABOUTUS_TESTIMONIALS_DATA_SUCCESS,
  GET_ABOUTUS_TESTIMONIALS_DATA_FAIL,
} from "./constants";

export const getAboutUsData = () => ({
  type: GET_ABOUTUS_DATA,
});
export const getAboutUssDataSuccess = data => ({
  type: GET_ABOUTUS_DATA_SUCCESS,
  data,
});

export const getAboutUssDataFail = data => ({
  type: GET_ABOUTUS_DATA_FAIL,
  data,
});

export const getAboutUsContactUsData = () => ({
  type: GET_ABOUTUS_CONTACTUS_DATA,
});

export const getAboutUsContactUsDataSuccess = (data) => ({
  type: GET_ABOUTUS_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getAboutUsContactUsDataFail = (data) => ({
  type: GET_ABOUTUS_CONTACTUS_DATA_FAIL,
  data,
});

export const getAboutUsTestimonialsData = () => ({
  type: GET_ABOUTUS_TESTIMONIALS_DATA,
});

export const getAboutUsTestimonialsDataSuccess = (data) => ({
  type: GET_ABOUTUS_TESTIMONIALS_DATA_SUCCESS,
  data,
});

export const getAboutUsTestimonialsDataFail = (data) => ({
  type: GET_ABOUTUS_TESTIMONIALS_DATA_FAIL,
  data,
});
