import React from "react";
import LoaderWrapper from "./loader";
import { Row, Col } from "antd";
import "./loader.scss";

export const Loader = (data) => {
  return (
    <LoaderWrapper>
      <Row className="loader-cont">
        <Col span={24}>
          <div className="loader"></div>
        </Col>
        <Col className="loading-text" span={24}>
          Loading...
        </Col>
      </Row>
    </LoaderWrapper>
  );
};

export default Loader;
