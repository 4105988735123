import produce from "immer";

import {
  GET_NEWS_SUBCAT_DATA,
  GET_NEWS_SUBCAT_DATA_SUCCESS,
  GET_NEWS_SUBCAT_DATA_FAIL,
  GET_POPULAR_NEWS_SUBCAT_DATA,
  GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS,
  GET_POPULAR_NEWS_SUBCAT_DATA_FAIL,
  GET_NEWS_CAT_DATA,
  GET_NEWS_CAT_DATA_SUCCESS,
  GET_NEWS_CAT_DATA_FAIL,
  GET_NEWS_CAT_MEDIA_BANNER,
  GET_NEWS_CAT_MEDIA_BANNER_SUCCESS,
  GET_NEWS_CAT_MEDIA_BANNER_FAIL,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL,
  GET_NEWS_CAT_PAGE_DATA,
  GET_NEWS_CAT_PAGE_DATA_SUCCESS,
  GET_NEWS_CAT_PAGE_DATA_FAIL,
  GET_NEWS_MM_LANG_DATA,
  GET_NEWS_MM_LANG_DATA_SUCCESS,
  GET_NEWS_MM_LANG_DATA_FAIL,
  UPDATE_CAT_PAGE_NO,
  UPDATE_SUBCAT_PAGE_NO,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL,
  GET_NEWS_CAT_PRESSPASSDATA,
  GET_NEWS_CAT_PRESSPASSDATA_SUCCESS,
  GET_NEWS_CAT_PRESSPASSDATA_FAIL,
  UPDATE_SUBCAT_COUNT,
  UPDATE_LANG_PAGE_TITLE,
  UPDATE_DATE_TEXT_FILTER,
} from "./constants";

const initialState = {
  loading: false,
  newsSubCatData: [],
  popularNews: [],
  newsCatData: [],
  rightCatList: [],
  pageTitle: "",
  mediaBanner: [],
  signUpNewsLetter: [],
  pageData: [],
  catPageNo: 1,
  subCatPageNo: 1,
  popNewsLoad: false,
  newsCatLoad: false,
  newsSubCatLoad: false,
  followSocialData: [],
  presspassData: [],
  subCatCount: 0,
  pillData: [],
};

const newsSubCatReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_NEWS_SUBCAT_DATA:
        draft.newsSubCatLoad = true;
        break;
      case GET_NEWS_SUBCAT_DATA_SUCCESS:
        draft.pageTitle = action.data.name || action.data.description;
        draft.newsSubCatLoad = false;
        //need to update data here
        // draft.newsSubCatData = action.data;
        // draft.newsCatData = ;
        draft.rightCatList = action.data.childList;
        break;
      case GET_NEWS_SUBCAT_DATA_FAIL:
        draft.newsSubCatLoad = false;
        break;
      case GET_POPULAR_NEWS_SUBCAT_DATA:
        draft.popNewsLoad = true;
        break;
      case GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS:
        draft.popNewsLoad = false;
        draft.popularNews = action.data;
        break;
      case GET_POPULAR_NEWS_SUBCAT_DATA_FAIL:
        draft.popNewsLoad = false;
        break;
      case GET_NEWS_CAT_DATA:
        draft.newsCatLoad = true;
        draft.pageTitle = action.data;
        break;
      case GET_NEWS_CAT_DATA_SUCCESS:
        draft.newsCatLoad = false;
        draft.newsCatData = action.data;
        draft.rightCatList = action.data.childList;
        draft.newsSubCatData = [];
        break;
      case GET_NEWS_CAT_DATA_FAIL:
        draft.newsCatLoad = false;
        break;
      case GET_NEWS_CAT_MEDIA_BANNER:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_MEDIA_BANNER_SUCCESS:
        draft.loading = false;
        draft.mediaBanner = action.data;
        break;
      case GET_NEWS_CAT_MEDIA_BANNER_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_CAT_SIGN_UP_NEWS_LETTER:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS:
        draft.loading = false;
        draft.signUpNewsLetter = action.data;
        break;
      case GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_CAT_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.pageData = action.data;
        break;
      case GET_NEWS_CAT_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_MM_LANG_DATA:
        draft.newsSubCatLoad = true;
        draft.pageTitle = "Multimedia";
        break;
      case GET_NEWS_MM_LANG_DATA_SUCCESS:
        draft.newsSubCatLoad = false;
        draft.newsSubCatData = action.data;
        break;
      case GET_NEWS_MM_LANG_DATA_FAIL:
        draft.newsSubCatLoad = false;
        break;
      case UPDATE_CAT_PAGE_NO:
        draft.catPageNo = action.data;
        break;
      case UPDATE_SUBCAT_PAGE_NO:
        draft.subCatPageNo = action.data;
        break;
      case GET_NEWS_CAT_PRESSPASSDATA:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_PRESSPASSDATA_SUCCESS:
        draft.loading = false;
        draft.presspassData = action.data;
        break;
      case GET_NEWS_CAT_PRESSPASSDATA_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_CAT_FOLLOWSOCIAL_DATA:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS:
        draft.loading = false;
        draft.followSocialData = action.data;
        break;
      case GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_CAT_POPULAR_NEWS_DETAILS:
        draft.loading = true;
        break;
      case GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS:
        draft.loading = false;
        draft.popularNewsDetails = action.data;
        break;
      case GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL:
        draft.loading = false;
        break;
      case UPDATE_SUBCAT_COUNT:
        draft.subCatCount = action.data;
        break;
      case UPDATE_LANG_PAGE_TITLE:
        draft.pageTitle = action.data;
        break;
      case UPDATE_DATE_TEXT_FILTER:
        draft.pillData = action.data;
        break;
      default:
        return draft;
    }
  });

export default newsSubCatReducer;
