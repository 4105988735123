import { takeEvery, put } from "redux-saga/effects";
import {
  GET_ABOUTUS_DATA,
  GET_ABOUTUS_TESTIMONIALS_DATA,
  GET_ABOUTUS_CONTACTUS_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getAboutUssDataSuccess,
  getAboutUssDataFail,
  getAboutUsTestimonialsDataSuccess,
  getAboutUsTestimonialsDataFail,
  getAboutUsContactUsDataSuccess,
  getAboutUsContactUsDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getAboutUs() {
  let data = yield get("/bwcms/page/about-us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());

  if (data && data.status === 200) {
    const aboutUsPageData = yield data.data;
    yield put(getAboutUssDataSuccess(aboutUsPageData));
    yield put(updateLanguageData(aboutUsPageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
  } else {
    yield put(getAboutUssDataFail(data));
  }
}

function* getAboutUsContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getAboutUsContactUsDataSuccess(contactData));
  } else {
    yield put(getAboutUsContactUsDataFail(data));
  }
}

function* getAboutUsTestimonialsData() {
  let data = yield get("/bwcms/testimonials")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const testimonialData = yield data.data;
    yield put(getAboutUsTestimonialsDataSuccess(testimonialData[0]));
  } else {
    yield put(getAboutUsTestimonialsDataFail(data));
  }
}

function* aboutusSaga() {
  yield takeEvery(GET_ABOUTUS_DATA, getAboutUs);
  yield takeEvery(GET_ABOUTUS_TESTIMONIALS_DATA, getAboutUsTestimonialsData);
  yield takeEvery(GET_ABOUTUS_CONTACTUS_DATA, getAboutUsContactUs);
}

export default aboutusSaga;
