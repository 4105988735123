import produce from "immer";

import {
  GET_NEWS_DETAILS_DATA,
  GET_NEWS_DETAILS_DATA_SUCCESS,
  GET_NEWS_DETAILS_DATA_FAIL,
  GET_RELEASE_VERSION,
  GET_RELEASE_VERSION_SUCCESS,
  GET_RELEASE_VERSION_FAIL,
  GET_ENEVELOPE_DATA,
  GET_ENEVELOPE_DATA_SUCCESS,
  GET_ENEVELOPE_DATA_FAIL,
  GET_MORE_NEWS_DATA,
  GET_MORE_NEWS_DATA_SUCCESS,
  GET_MORE_NEWS_DATA_FAIL,
  GET_NEWS_NEWSLETTER_BANNER_DATA,
  GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS,
  GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL,
  GET_MEDIA_BANNER,
  GET_MEDIA_BANNER_SUCCESS,
  GET_MEDIA_BANNER_FAIL,
  GET_NEWS_DETAILS_RECOMMENDED,
  GET_NEWS_DETAILS_RECOMMENDED_SUCCESS,
  GET_NEWS_DETAILS_RECOMMENDED_FAIL,
  GET_NEWS_DETAILS_PAGE_DATA,
  GET_NEWS_DETAILS_PAGE_DATA_SUCCESS,
  GET_NEWS_DETAILS_PAGE_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  newsDetail: [],
  envelopeData: [],
  releaseVersion: [],
  moreNews: [],
  newsLetterBannerData: [],
  mediaBanner: [],
  newsLoad: false,
  relVerLoad: false,
  moreNewsLoad: false,
  recommendedNews: {
    description: "",
    display_columns: false,
    title: "",
    columns: [],
  },
  pageData: [],
};

const newsDetailReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_NEWS_DETAILS_DATA:
        draft.newsLoad = true;
        draft.newsDetail = [];
        draft.releaseVersion = [];
        draft.moreNews = [];
        break;
      case GET_NEWS_DETAILS_DATA_SUCCESS:
        draft.newsLoad = false;
        draft.newsDetail = action.data;
        break;
      case GET_NEWS_DETAILS_DATA_FAIL:
        draft.newsLoad = false;
        break;
      case GET_RELEASE_VERSION:
        draft.relVerLoad = true;
        break;
      case GET_RELEASE_VERSION_SUCCESS:
        draft.relVerLoad = false;
        draft.releaseVersion = action.data;
        break;
      case GET_RELEASE_VERSION_FAIL:
        draft.relVerLoad = false;
        break;
      case GET_ENEVELOPE_DATA:
        draft.loading = true;
        break;
      case GET_ENEVELOPE_DATA_SUCCESS:
        draft.loading = false;
        draft.envelopeData = action.data;
        break;
      case GET_ENEVELOPE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_MORE_NEWS_DATA:
        draft.moreNewsLoad = true;
        break;
      case GET_MORE_NEWS_DATA_SUCCESS:
        draft.moreNewsLoad = false;
        draft.moreNews = action.data;
        break;
      case GET_MORE_NEWS_DATA_FAIL:
        draft.moreNewsLoad = false;
        break;
      case GET_NEWS_NEWSLETTER_BANNER_DATA:
        draft.loading = true;
        break;
      case GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS:
        draft.loading = false;
        draft.newsLetterBannerData = action.data;
        break;
      case GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_MEDIA_BANNER:
        draft.loading = true;
        break;
      case GET_MEDIA_BANNER_SUCCESS:
        draft.loading = false;
        draft.mediaBanner = action.data;
        break;
      case GET_MEDIA_BANNER_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_DETAILS_RECOMMENDED:
        draft.loading = true;
        break;
      case GET_NEWS_DETAILS_RECOMMENDED_SUCCESS:
        draft.loading = false;
        draft.recommendedNews.columns = action.data;
        break;
      case GET_NEWS_DETAILS_RECOMMENDED_FAIL:
        draft.loading = false;
        break;
      case GET_NEWS_DETAILS_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_NEWS_DETAILS_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.pageData = action.data;
        if (action?.data?.recommendedBanner) {
          draft.recommendedNews.title = action.data.recommendedBanner.title;
          draft.recommendedNews.description =
            action.data.recommendedBanner.description;
          draft.recommendedNews.display_columns =
            action.data.recommendedBanner.display_columns;
        }
        break;
      case GET_NEWS_DETAILS_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      default:
        return draft;
    }
  });
export default newsDetailReducer;
