export const GET_POSTS_DATA = "GET_POSTS_DATA";
export const GET_POSTS_DATA_SUCCESS = "GET_POSTS_DATA_SUCCESS";
export const GET_POSTS_DATA_FAIL = "GET_POSTS_DATA_FAIL";
export const GET_MEDIA_NEWS_DATA = "GET_MEDIA_NEWS_DATA";
export const GET_MEDIA_NEWS_DATA_SUCCESS = "GET_MEDIA_NEWS_DATA_SUCCESS";
export const GET_MEDIA_NEWS_DATA_FAIL = "GET_MEDIA_NEWS_DATA_FAIL";
export const GET_GEO_DATA = "GET_GEO_DATA";
export const GET_GEO_DATA_SUCCESS = "GET_GEO_DATA_SUCCESS";
export const GET_GEO_DATA_FAIL = "GET_GEO_DATA_FAIL";

