import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";
const AdvanceSearchComponentWrapper = styled.div`
  .advance-search .radio-group .ant-radio-wrapper {
    color: ${colors["@field-text-label"]} !important;
  }

  .advance-search .radio-group .ant-radio-wrapper-checked {
    color: ${colors["@field-text-label"]};
  }

  .advance-search .radio-group .ant-radio-inner {
    border: 1.5px solid ${colors["@field-border"]};
  }

  .advance-search .ant-radio-checked .ant-radio-inner {
    border: 2px solid ${colors["@field-border-active"]};
    background-color: ${colors["@bg"]};
  }

  .advance-search .adv-search-input {
    background: ${colors["@field-bg"]};
  }

  .advance-search .search-title {
    color: ${colors["@field-text-label"]};
  }

  .advance-search .adv-search-btn {
    background: ${colors["@btn-primary"]};
  }

  .advance-search .adv-search-btn .search-text {
    color: ${colors["@btn-primary-text"]};
  }

  .advance-search .adv-search-btn:hover {
    background: ${colors["@btn-primary-hover"]};
  }

  .advance-search .ant-input-affix-wrapper-status-error .ant-input,
  .error-text {
    color: ${colors["@error-text"]};
  }

  .advance-search .adv-search-input {
    border: 1px solid ${colors["@field-border"]};
  }

  .advance-search .adv-search-input .ant-input {
    color: ${colors["@field-text"]};
  }
  .advance-search .adv-search-input .ant-input::placeholder {
    color: ${colors["@field-text-placeholder"]};
  }

  .advance-search .ant-radio-checked .ant-radio-inner::after {
    background-color: ${colors["@field-bg-select-accent"]};
  }

  .advance-search .adv-search-input:hover,
  .advance-search .adv-search-input:active {
    border: 1px solid ${colors["@field-border-active-accent"]};
  }
`;
export default AdvanceSearchComponentWrapper;
