import { takeEvery, put } from "redux-saga/effects";
import {
  GET_SETAIL_PAGE_DATA,
  GET_SETAIL_CONTACTUS_DATA,
  GET_SETAIL_SIGN_UP_DATA,
  GET_SETAIL_DEMO_BANNER_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getSetailPageDataSuccess,
  getSetailPageDataFail,
  getSetailContactUsDataSuccess,
  getSetailContactUsDataFail,
  getSetailSignUpDataSuccess,
  getSetailSignUpDataFail,
  getSetailDemoBannerDataSuccess,
  getSetailDemoBannerDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getSetailPageData(pageName) {
  let data = yield get("/bwcms/page/" + pageName.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;

    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(
      updateFooterLanguageData({
        description: "",
        id: 414,
        active: true,
        children: [
          {
            active: true,
            children: [],
            description: "",
            id: 417,
            language: "",
            target: "_blank",
            title: "Deustch",
            url: "https://services.businesswire.com/de/public-relations-services/press-release-distribution",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 418,
            language: "",
            target: "_blank",
            title: "Español",
            url: "https://services.businesswire.com/es/public-relations-services/press-release-distribution",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 419,
            language: "",
            target: "_blank",
            title: "Français",
            url: "https://services.businesswire.com/fr/public-relations-services/press-release-distribution",
          },
          // {
          //   active: true,
          //   children: [],
          //   description: "",
          //   id: 420,
          //   language: "",
          //   target: "_blank",
          //   title: "Italiano",
          //   url: "https://services.businesswire.com/it/public-relations-services/press-release-distribution",
          // },
          {
            active: true,
            children: [],
            description: "",
            id: 421,
            language: "",
            target: "_blank",
            title: "简体中文",
            url: "https://services.businesswire.com/zh-cn/public-relations-services/press-release-distribution",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 422,
            language: "",
            target: "_blank",
            title: "繁體中文",
            url: "https://services.businesswire.com/zh-hk/public-relations-services/press-release-distribution",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 423,
            language: "",
            target: "_blank",
            title: "日本語",
            url: "https://services.businesswire.com/ja/public-relations-services/press-release-distribution",
          },
        ],
        language: "",
        target: "_blank",
        title: "English",
        url: "https://services.businesswire.com/public-relations-services/press-release-distribution",
      })
    );
    yield put(getSetailPageDataSuccess(pageData));
  } else {
    yield put(getSetailPageDataFail(data));
  }
}

function* getSetailContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getSetailContactUsDataSuccess(contactData));
  } else {
    yield put(getSetailContactUsDataFail(data));
  }
}

function* getSetailSignUpData() {
  let data = yield get("/bwcms/globalcomponent/sign_up")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const signUpData = yield data.data;
    yield put(getSetailSignUpDataSuccess(signUpData));
  } else {
    yield put(getSetailSignUpDataFail(data));
  }
}

function* getSetailDemoBannerData() {
  let data = yield get("/bwcms/globalcomponent/demo")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const demoData = yield data.data;
    yield put(getSetailDemoBannerDataSuccess(demoData));
  } else {
    yield put(getSetailDemoBannerDataFail(data));
  }
}

function* setailSaga() {
  yield takeEvery(GET_SETAIL_PAGE_DATA, getSetailPageData);
  yield takeEvery(GET_SETAIL_SIGN_UP_DATA, getSetailSignUpData);
  yield takeEvery(GET_SETAIL_DEMO_BANNER_DATA, getSetailDemoBannerData);
  yield takeEvery(GET_SETAIL_CONTACTUS_DATA, getSetailContactUs);
}

export default setailSaga;
