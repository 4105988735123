import {
  GET_MENU_DATA,
  GET_MENU_DATA_SUCCESS,
  GET_MENU_DATA_FAIL,
  GET_LFOOTER_DATA,
  GET_LFOOTER_DATA_SUCCESS,
  GET_LFOOTER_DATA_FAIL,
  GET_MFOOTER_DATA,
  GET_MFOOTER_DATA_SUCCESS,
  GET_MFOOTER_DATA_FAIL,
  GET_BNAVIGATION_DATA,
  GET_BNAVIGATION_DATA_SUCCESS,
  GET_BNAVIGATION_DATA_FAIL,
  GET_LANGUAGE_DATA,
  GET_LANGUAGE_DATA_SUCCESS,
  GET_LANGUAGE_DATA_FAIL,
  GET_BLOG_DATA,
  GET_BLOG_DATA_SUCCESS,
  GET_BLOG_DATA_FAIL,
  GET_GLOBAL_SETTINGS,
  GET_GLOBAL_SETTINGS_SUCCESS,
  GET_GLOBAL_SETTINGS_FAIL,
  UPDATE_LANGUAGE_DATA,
  GET_MODAL_NEWS_SEARCH_DATA,
  GET_MODAL_NEWS_SEARCH_DATA_SUCCESS,
  GET_MODAL_NEWS_SEARCH_DATA_FAIL,
  CLEAR_MODAL_NEWS_SEARCH_DATA,
  GET_LOGIN_BTN_DATA,
  GET_LOGIN_BTN_DATA_SUCCESS,
  GET_LOGIN_BTN_DATA_FAIL,
  UPDATE_FOOTER_LANGUAGE_DATA,
  UPDATE_MODAL_SEARCH_NEWS_CNT,
} from "./constants";

export const getMenuData = () => ({
  type: GET_MENU_DATA,
});

export const getMenuDataSuccess = (data) => ({
  type: GET_MENU_DATA_SUCCESS,
  data,
});

export const getMenuDataFail = (data) => ({
  type: GET_MENU_DATA_FAIL,
  data,
});

export const getlfooterData = () => ({
  type: GET_LFOOTER_DATA,
});

export const getlfooterDataSuccess = (data) => ({
  type: GET_LFOOTER_DATA_SUCCESS,
  data,
});

export const getlfooterDataFail = (data) => ({
  type: GET_LFOOTER_DATA_FAIL,
});

export const getBnavigationData = () => ({
  type: GET_BNAVIGATION_DATA,
});

export const getBnavigationDataSuccess = (data) => ({
  type: GET_BNAVIGATION_DATA_SUCCESS,
  data,
});

export const getBnavigationDataFail = (data) => ({
  type: GET_BNAVIGATION_DATA_FAIL,
});
export const getMfooterData = () => ({
  type: GET_MFOOTER_DATA,
});

export const getMfooterDataSuccess = (data) => ({
  type: GET_MFOOTER_DATA_SUCCESS,
  data,
});

export const getMfooterDataFail = (data) => ({
  type: GET_MFOOTER_DATA_FAIL,
});

export const getLanguageData = () => ({
  type: GET_LANGUAGE_DATA,
});

export const getLanguageDataSuccess = (data) => ({
  type: GET_LANGUAGE_DATA_SUCCESS,
  data,
});

export const getLanguageDataFail = (data) => ({
  type: GET_LANGUAGE_DATA_FAIL,
});
export const getBlogData = () => ({
  type: GET_BLOG_DATA,
});

export const getBlogDataSuccess = (data) => ({
  type: GET_BLOG_DATA_SUCCESS,
  data,
});

export const getBlogDataFail = (data) => ({
  type: GET_BLOG_DATA_FAIL,
});

export const getGlobalSettings = () => ({
  type: GET_GLOBAL_SETTINGS,
});

export const getGlobalSettingsSuccess = (data) => ({
  type: GET_GLOBAL_SETTINGS_SUCCESS,
  data,
});

export const getGlobalSettingsFail = (data) => ({
  type: GET_GLOBAL_SETTINGS_FAIL,
  data,
});

export const updateLanguageData = (data) => ({
  type: UPDATE_LANGUAGE_DATA,
  data,
});
export const getModalNewsSearchData = (data) => ({
  type: GET_MODAL_NEWS_SEARCH_DATA,
  data,
});

export const getModalNewsSearchDataSuccess = (data) => ({
  type: GET_MODAL_NEWS_SEARCH_DATA_SUCCESS,
  data,
});
export const getModalNewsSearchDataFail = (data) => ({
  type: GET_MODAL_NEWS_SEARCH_DATA_FAIL,
  data,
});

export const clearModalNewsSearchData = () => ({
  type: CLEAR_MODAL_NEWS_SEARCH_DATA,
});

export const getLoginBtnData = () => ({
  type: GET_LOGIN_BTN_DATA,
});

export const getLoginBtnDataSuccess = (data) => ({
  type: GET_LOGIN_BTN_DATA_SUCCESS,
  data,
});

export const getLoginBtnDataFail = (data) => ({
  type: GET_LOGIN_BTN_DATA_FAIL,
  data,
});

//temp code for service page footer links
export const updateFooterLanguageData = (data) => ({
  type: UPDATE_FOOTER_LANGUAGE_DATA,
  data,
});

export const updateModalSearchNewsCnt = (data) => ({
  type: UPDATE_MODAL_SEARCH_NEWS_CNT,
  data,
});
