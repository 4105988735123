import {
  GET_SERVICE_PAGE_DATA,
  GET_SERVICE_PAGE_DATA_SUCCESS,
  GET_SERVICE_PAGE_DATA_FAIL,
  GET_SERVICE_CONTACTUS_DATA,
  GET_SERVICE_CONTACTUS_DATA_SUCCESS,
  GET_SERVICE_CONTACTUS_DATA_FAIL,
  GET_SERVICE_SIGN_UP_DATA,
  GET_SERVICE_SIGN_UP_DATA_SUCCESS,
  GET_SERVICE_SIGN_UP_DATA_FAIL,
  GET_SERVICE_DEMO_BANNER_DATA,
  GET_SERVICE_DEMO_BANNER_DATA_SUCCESS,
  GET_SERVICE_DEMO_BANNER_DATA_FAIL,
  GET_SERVICE_RECOMMEND_DATA,
  GET_SERVICE_RECOMMEND_DATA_SUCCESS,
  GET_SERVICE_RECOMMEND_DATA_FAIL,
} from "./constants";

export const getServicePageData = (data) => ({
  type: GET_SERVICE_PAGE_DATA,
  data,
});

export const getServicePageDataSuccess = (data) => ({
  type: GET_SERVICE_PAGE_DATA_SUCCESS,
  data,
});

export const getServicePageDataFail = (data) => ({
  type: GET_SERVICE_PAGE_DATA_FAIL,
  data,
});

export const getServiceContactUsData = () => ({
  type: GET_SERVICE_CONTACTUS_DATA,
});

export const getServiceContactUsDataSuccess = (data) => ({
  type: GET_SERVICE_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getServiceContactUsDataFail = (data) => ({
  type: GET_SERVICE_CONTACTUS_DATA_FAIL,
  data,
});

export const getServiceSignUpData = () => ({
  type: GET_SERVICE_SIGN_UP_DATA,
});

export const getServiceSignUpDataSuccess = (data) => ({
  type: GET_SERVICE_SIGN_UP_DATA_SUCCESS,
  data,
});

export const getServiceSignUpDataFail = (data) => ({
  type: GET_SERVICE_SIGN_UP_DATA_FAIL,
  data,
});

export const getServiceDemoBannerData = () => ({
  type: GET_SERVICE_DEMO_BANNER_DATA,
});

export const getServiceDemoBannerDataSuccess = (data) => ({
  type: GET_SERVICE_DEMO_BANNER_DATA_SUCCESS,
  data,
});

export const getServiceDemoBannerDataFail = (data) => ({
  type: GET_SERVICE_DEMO_BANNER_DATA_FAIL,
  data,
});
export const getServiceRecommendData = (data) => ({
  type: GET_SERVICE_RECOMMEND_DATA,
  data,
});

export const getServiceRecommendDataSuccess = (data) => ({
  type: GET_SERVICE_RECOMMEND_DATA_SUCCESS,
  data,
});

export const getServiceRecommendDataFail = (data) => ({
  type: GET_SERVICE_RECOMMEND_DATA_FAIL,
  data,
});
