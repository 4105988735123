import { takeEvery, put } from "redux-saga/effects";
import {
  GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA,
  GET_COPYRIGHT_INFORMATION_PAGE_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getCopyrightInoformationContactusSuccess,
  getCopyrightInoformationContactusFail,
  getCopyrightInoformationPageDataSuccess,
  getCopyrightInoformationPageDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getCopyrightInoformationContactus() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getCopyrightInoformationContactusSuccess(contactData));
  } else {
    yield put(getCopyrightInoformationContactusFail(data));
  }
}

function* getCopyrightInoformationPageData(pageName) {
  let data = yield get("/bwcms/page/copyright")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getCopyrightInoformationPageDataSuccess(pageData));
  } else {
    yield put(getCopyrightInoformationPageDataFail(data));
  }
}

function* copyrightInoformationSaga() {
  yield takeEvery(
    GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA,
    getCopyrightInoformationContactus
  );
  yield takeEvery(
    GET_COPYRIGHT_INFORMATION_PAGE_DATA,
    getCopyrightInoformationPageData
  );
}

export default copyrightInoformationSaga;
