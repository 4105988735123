import {
  GET_ALL_NEWS_DATA,
  GET_ALL_NEWS_DATA_SUCCESS,
  GET_ALL_NEWS_DATA_FAIL,
  GET_POPULAR_NEWS_DATA,
  GET_POPULAR_NEWS_DATA_SUCCESS,
  GET_POPULAR_NEWS_DATA_FAIL,
  GET_SUBCATEGORY_DATA,
  GET_SUBCATEGORY_DATA_SUCCESS,
  GET_SUBCATEGORY_DATA_FAIL,
  GET_ALL_NEWS_MEDIA_BANNER,
  GET_ALL_NEWS_MEDIA_BANNER_SUCCESS,
  GET_ALL_NEWS_MEDIA_BANNER_FAIL,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL,
  GET_ALL_NEWS_PAGE_DATA,
  GET_ALL_NEWS_PAGE_DATA_SUCCESS,
  GET_ALL_NEWS_PAGE_DATA_FAIL,
  GET_ALL_NEWS_PRESSPASSDATA,
  GET_ALL_NEWS_PRESSPASSDATA_SUCCESS,
  GET_ALL_NEWS_PRESSPASSDATA_FAIL,
  UPDATE_PAGE_NUMBER,
  UPDATE_TOTAL_NEWS_CNT,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL,
  UPDATE_FILTER_DATE,
} from "./constants";

export const getFollowSocialData = (data) => ({
  type: GET_ALL_NEWS_FOLLOWSOCIAL_DATA,
  data,
});
export const getFollowSocialSuccess = (data) => ({
  type: GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS,
  data,
});

export const getFollowSocialFail = (data) => ({
  type: GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL,
  data,
});
export const getPopularAllNewsDetails = (data) => ({
  type: GET_ALL_NEWS_POPULAR_NEWS_DETAILS,
  data,
});
export const getPopularAllNewsDetailsSuccess = (data) => ({
  type: GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS,
  data,
});

export const getPopularAllNewsDetailsFail = (data) => ({
  type: GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL,
  data,
});
export const getAllNewsPresspassData = (data) => ({
  type: GET_ALL_NEWS_PRESSPASSDATA,
  data,
});

export const getAllNewsPresspassDataSuccess = (data) => ({
  type: GET_ALL_NEWS_PRESSPASSDATA_SUCCESS,
  data,
});

export const getAllNewsPresspassDataFail = (data) => ({
  type: GET_ALL_NEWS_PRESSPASSDATA_FAIL,
  data,
});

export const getAllNewsData = (data) => ({
  type: GET_ALL_NEWS_DATA,
  data,
});

export const getAllNewsDataSuccess = (data) => ({
  type: GET_ALL_NEWS_DATA_SUCCESS,
  data,
});

export const getAllNewsDataFail = (data) => ({
  type: GET_ALL_NEWS_DATA_FAIL,
  data,
});

export const getPopularNewsData = (data) => ({
  type: GET_POPULAR_NEWS_DATA,
  data,
});

export const getPopularNewsDataSuccess = (data) => ({
  type: GET_POPULAR_NEWS_DATA_SUCCESS,
  data,
});

export const getPopularNewsDataFail = (data) => ({
  type: GET_POPULAR_NEWS_DATA_FAIL,
  data,
});

export const getSubCategorysData = (data) => ({
  type: GET_SUBCATEGORY_DATA,
  data,
});

export const getSubCategorysDataSuccess = (data) => ({
  type: GET_SUBCATEGORY_DATA_SUCCESS,
  data,
});

export const getSubCategorysDataFail = (data) => ({
  type: GET_SUBCATEGORY_DATA_FAIL,
  data,
});

export const getAllNewsMediaBanner = (data) => ({
  type: GET_ALL_NEWS_MEDIA_BANNER,
  data,
});

export const getAllNewsMediaBannerSuccess = (data) => ({
  type: GET_ALL_NEWS_MEDIA_BANNER_SUCCESS,
  data,
});

export const getAllNewsMediaBannerFail = (data) => ({
  type: GET_ALL_NEWS_MEDIA_BANNER_FAIL,
  data,
});

export const getAllNewsSignUpNewsLetter = (data) => ({
  type: GET_ALL_NEWS_SIGN_UP_NEWS_LETTER,
  data,
});

export const getAllNewsSignUpNewsLetterSuccess = (data) => ({
  type: GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS,
  data,
});

export const getAllNewsSignUpNewsLetterFail = (data) => ({
  type: GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL,
  data,
});

export const getAllNewsPageData = (data) => ({
  type: GET_ALL_NEWS_PAGE_DATA,
  data,
});

export const getAllNewsPageDataSuccess = (data) => ({
  type: GET_ALL_NEWS_PAGE_DATA_SUCCESS,
  data,
});

export const getAllNewsPageDataFail = (data) => ({
  type: GET_ALL_NEWS_PAGE_DATA_FAIL,
  data,
});

export const updatePageNumber = (data) => ({
  type: UPDATE_PAGE_NUMBER,
  data,
});

export const updateTotalNewsCnt = (data) => ({
  type: UPDATE_TOTAL_NEWS_CNT,
  data,
});

export const updateFilterDate = (data) => ({
  type: UPDATE_FILTER_DATE,
  data,
});
