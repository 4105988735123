export const REACT_APP_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://businesswirdev.wpengine.com/wp-json";

export const NEWS_API_URL = process.env.REACT_APP_NEWS_API_URL
  ? process.env.REACT_APP_NEWS_API_URL
  : "https://platform.businesswire.com/api/v1";

export const REACT_APP_CAT_IMAGE_URL = process.env.REACT_APP_CAT_IMAGE_URL
  ? process.env.REACT_APP_CAT_IMAGE_URL
  : "https://businesswirdev.wpengine.com/wp-content/uploads/category_images/images/";

export const NEWS_PUBLIC_API_URL = process.env.REACT_APP_NEWS_PUBLIC_API_URL
  ? process.env.REACT_APP_NEWS_PUBLIC_API_URL
  : "https://platform.businesswire.com/";
