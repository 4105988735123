import produce from "immer";

import {
  GET_PRIVACY_POLICY_CONTACTUS,
  GET_PRIVACY_POLICY_CONTACTUS_SUCCESS,
  GET_PRIVACY_POLICY_CONTACTUS_FAIL,
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  privacyPolicyPageData: [],
  privacyPolicycontact: [],
};

const privacyPolicyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_PRIVACY_POLICY_CONTACTUS:
        draft.loading = true;
        break;
      case GET_PRIVACY_POLICY_CONTACTUS_SUCCESS:
        draft.loading = false;
        draft.privacyPolicycontact = action.data;
        break;
      case GET_PRIVACY_POLICY_CONTACTUS_FAIL:
        draft.loading = false;
        break;
      case GET_PRIVACY_POLICY:
        draft.loading = true;
        break;
      case GET_PRIVACY_POLICY_SUCCESS:
        draft.loading = false;
        draft.privacyPolicyPageData = action.data;
        break;
      case GET_PRIVACY_POLICY_FAIL:
        draft.loading = false;
        break;

      default:
        return draft;
    }
  });

export default privacyPolicyReducer;
