export const GET_SERVICE_PAGE_DATA = "GET_SERVICE_PAGE_DATA";
export const GET_SERVICE_PAGE_DATA_SUCCESS = "GET_SERVICE_PAGE_DATA_SUCCESS";
export const GET_SERVICE_PAGE_DATA_FAIL = "GET_SERVICE_PAGE_DATA_FAIL";
export const GET_SERVICE_CONTACTUS_DATA = "GET_SERVICE_CONTACTUS_DATA";
export const GET_SERVICE_CONTACTUS_DATA_SUCCESS =
  "GET_SERVICE_CONTACTUS_DATA_SUCCESS";
export const GET_SERVICE_CONTACTUS_DATA_FAIL =
  "GET_SERVICE_CONTACTUS_DATA_FAIL";
export const GET_SERVICE_SIGN_UP_DATA = "GET_SERVICE_SIGN_UP_DATA";
export const GET_SERVICE_SIGN_UP_DATA_SUCCESS =
  "GET_SERVICE_SIGN_UP_DATA_SUCCESS";
export const GET_SERVICE_SIGN_UP_DATA_FAIL = "GET_SERVICE_SIGN_UP_DATA_FAIL";
export const GET_SERVICE_DEMO_BANNER_DATA = "GET_SERVICE_DEMO_BANNER_DATA";
export const GET_SERVICE_DEMO_BANNER_DATA_SUCCESS =
  "GET_SERVICE_DEMO_BANNER_DATA_SUCCESS";
export const GET_SERVICE_DEMO_BANNER_DATA_FAIL =
  "GET_SERVICE_DEMO_BANNER_DATA_FAIL";
export const GET_SERVICE_RECOMMEND_DATA = "GET_SERVICE_RECOMMEND_DATA,";
export const GET_SERVICE_RECOMMEND_DATA_SUCCESS =
  "GET_SERVICE_RECOMMEND_DATA_SUCCESS";
export const GET_SERVICE_RECOMMEND_DATA_FAIL =
  "GET_SERVICE_RECOMMEND_DATA_FAIL";
