export const GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA =
  "GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA";
export const GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_SUCCESS =
  "GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_SUCCESS";
export const GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_FAIL =
  "GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_FAIL";
export const GET_COPYRIGHT_INFORMATION_PAGE_DATA =
  "GET_COPYRIGHT_INFORMATION_PAGE_DATA";
export const GET_COPYRIGHT_INFORMATION_PAGE_DATA_SUCCESS =
  "GET_COPYRIGHT_INFORMATION_PAGE_DATA_SUCCESS";
export const GET_COPYRIGHT_INFORMATION_PAGE_DATA_FAIL =
  "GET_COPYRIGHT_INFORMATION_PAGE_DATA_FAIL";
