import produce from "immer";

import {
  GET_TERM_OF_USE_CONTACTUS_DATA,
  GET_TERM_OF_USE_CONTACTUS_DATA_SUCCESS,
  GET_TERM_OF_USE_CONTACTUS_DATA_FAIL,
  GET_TERM_OF_USE_PAGE_DATA,
  GET_TERM_OF_USE_PAGE_DATA_SUCCESS,
  GET_TERM_OF_USE_PAGE_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  contactusData: [],
  termOfPageData: [],
};

const termOfUseReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TERM_OF_USE_CONTACTUS_DATA:
        draft.loading = true;
        break;
      case GET_TERM_OF_USE_CONTACTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.contactusData = action.data;
        break;
      case GET_TERM_OF_USE_CONTACTUS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_TERM_OF_USE_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_TERM_OF_USE_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.termOfPageData = action.data;
        break;
      case GET_TERM_OF_USE_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      default:
        return draft;
    }
  });

export default termOfUseReducer;
