import en from "./en-US";
import ge from "./de-DE";
import fr from "./fr-CA";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...en,
  ...ge,
  ...fr,
};
