import { takeEvery, put } from "redux-saga/effects";
import { GET_SERVICEBYCATEGORY_DATA } from "./constants";
import { get } from "../../utils/api";
import {
  getServiceByCategoryDataSuccess,
  getServiceByCategoryDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getServiceByCategoryData(pageName) {
  let data = yield get("/bwcms/page/" + pageName.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const ServiceByCategoryData = yield data.data;

    yield put(updateLanguageData(ServiceByCategoryData.languages));
    //temp code for service page footer links
    if (pageName.data === "public-relations") {
      yield put(
        updateFooterLanguageData({
          description: "",
          id: 414,
          active: true,
          children: [
            {
              active: true,
              children: [],
              description: "",
              id: 417,
              language: "",
              target: "_blank",
              title: "Deustch",
              url: "https://services.businesswire.com/de/public-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 418,
              language: "",
              target: "_blank",
              title: "Español",
              url: "https://services.businesswire.com/es/public-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 419,
              language: "",
              target: "_blank",
              title: "Français",
              url: "https://services.businesswire.com/fr/public-relations-services",
            },
            // {
            //   active: true,
            //   children: [],
            //   description: "",
            //   id: 420,
            //   language: "",
            //   target: "_blank",
            //   title: "Italiano",
            //   url: "https://services.businesswire.com/it/home",
            // },
            {
              active: true,
              children: [],
              description: "",
              id: 421,
              language: "",
              target: "_blank",
              title: "简体中文",
              url: "https://services.businesswire.com/zh-cn/public-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 422,
              language: "",
              target: "_blank",
              title: "繁體中文",
              url: "https://services.businesswire.com/zh-hk/public-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 423,
              language: "",
              target: "_blank",
              title: "日本語",
              url: "https://services.businesswire.com/ja/public-relations-services",
            },
          ],
          language: "",
          target: "_blank",
          title: "English",
          url: "https://services.businesswire.com/public-relations-services",
        })
      );
    } else if (pageName.data === "investor-relations") {
      yield put(
        updateFooterLanguageData({
          description: "",
          id: 414,
          active: true,
          children: [
            {
              active: true,
              children: [],
              description: "",
              id: 417,
              language: "",
              target: "_blank",
              title: "Deustch",
              url: "https://services.businesswire.com/de/investor-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 418,
              language: "",
              target: "_blank",
              title: "Español",
              url: "https://services.businesswire.com/es/investor-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 419,
              language: "",
              target: "_blank",
              title: "Français",
              url: "https://services.businesswire.com/fr/investor-relations-services",
            },
            // {
            //   active: true,
            //   children: [],
            //   description: "",
            //   id: 420,
            //   language: "",
            //   target: "_blank",
            //   title: "Italiano",
            //   url: "https://services.businesswire.com/it/home",
            // },
            {
              active: true,
              children: [],
              description: "",
              id: 421,
              language: "",
              target: "_blank",
              title: "简体中文",
              url: "https://services.businesswire.com/zh-cn/investor-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 422,
              language: "",
              target: "_blank",
              title: "繁體中文",
              url: "https://services.businesswire.com/zh-hk/investor-relations-services",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 423,
              language: "",
              target: "_blank",
              title: "日本語",
              url: "https://services.businesswire.com/ja/investor-relations-services",
            },
          ],
          language: "",
          target: "_blank",
          title: "English",
          url: "https://services.businesswire.com/investor-relations-services",
        })
      );
    } else if (pageName.data === "distribution-analytics") {
      yield put(
        updateFooterLanguageData({
          description: "",
          id: 414,
          active: true,
          children: [
            {
              active: true,
              children: [],
              description: "",
              id: 417,
              language: "",
              target: "_blank",
              title: "Deustch",
              url: "https://services.businesswire.com/de/distribution-analytics",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 418,
              language: "",
              target: "_blank",
              title: "Español",
              url: "https://services.businesswire.com/es/distribution-analytics",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 419,
              language: "",
              target: "_blank",
              title: "Français",
              url: "https://services.businesswire.com/fr/distribution-analytics",
            },
            // {
            //   active: true,
            //   children: [],
            //   description: "",
            //   id: 420,
            //   language: "",
            //   target: "_blank",
            //   title: "Italiano",
            //   url: "https://services.businesswire.com/it/home",
            // },
            {
              active: true,
              children: [],
              description: "",
              id: 421,
              language: "",
              target: "_blank",
              title: "简体中文",
              url: "https://services.businesswire.com/zh-cn/distribution-analytics",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 422,
              language: "",
              target: "_blank",
              title: "繁體中文",
              url: "https://services.businesswire.com/zh-hk/distribution-analytics",
            },
            {
              active: true,
              children: [],
              description: "",
              id: 423,
              language: "",
              target: "_blank",
              title: "日本語",
              url: "https://services.businesswire.com/ja/distribution-analytics",
            },
          ],
          language: "",
          target: "_blank",
          title: "English",
          url: "https://services.businesswire.com/distribution-analytics",
        })
      );
    } else if (pageName.data === "media-journalist-tools") {
      yield put(
        updateFooterLanguageData({
          description: "",
          id: 414,
          active: true,
          children: [],
          language: "",
          target: "_blank",
          title: "English",
          url: "https://services.businesswire.com/media-journalist-tools",
        })
      );
    }
    yield put(getServiceByCategoryDataSuccess(ServiceByCategoryData));
  } else {
    yield put(getServiceByCategoryDataFail(data));
  }
}

function* ServiceByCategorySaga() {
  yield takeEvery(GET_SERVICEBYCATEGORY_DATA, getServiceByCategoryData);
}

export default ServiceByCategorySaga;
