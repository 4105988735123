import {
  GET_SERVICEBYCATEGORY_DATA,
  GET_SERVICEBYCATEGORY_DATA_SUCCESS,
  GET_SERVICEBYCATEGORY_DATA_FAIL,
} from "./constants";

export const getServiceByCategoryData = (data) => ({
  type: GET_SERVICEBYCATEGORY_DATA,
  data,
});

export const getServiceByCategoryDataSuccess = (data) => ({
  type: GET_SERVICEBYCATEGORY_DATA_SUCCESS,
  data,
});

export const getServiceByCategoryDataFail = (data) => ({
  type: GET_SERVICEBYCATEGORY_DATA_FAIL,
  data,
});
