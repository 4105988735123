import { spawn } from "redux-saga/effects";
import educationSaga from "../containers/education/saga";
import homeSaga from "../containers/home/saga";
import newsDetailSaga from "../containers/newsdetail/saga";
import serviceSaga from "../containers/service/saga";
import loginSaga from "../containers/login/saga";
import appSaga from "../containers/app/saga";
import dashboardSaga from "../containers/dashboard/saga";
import allNewsSaga from "../containers/allnews/saga";
import officelocationSaga from "../containers/officelocation/saga";
import serviceByCategorySaga from "../containers/serviceByCategory/saga";
import contactPageSaga from "../containers/contactpage/saga";
import mediaPartnershipSaga from "../containers/mediapartnershipcontacts/saga";
import newsSubCatSaga from "../containers/newsbycategory/saga";
import termofuseSaga from "../containers/termofuse/saga";
import setailSaga from "../containers/setail/saga";
import aboutusSaga from "../containers/aboutus/saga";
import privacypolicySaga from "../containers/privacypolicy/saga";
import careersSaga from "../containers/careers/saga";
import copyrightinoformationSaga from "../containers/copyrightInformation/saga";

export default function* rootSaga() {
  yield spawn(educationSaga);
  yield spawn(homeSaga);
  yield spawn(newsDetailSaga);
  yield spawn(serviceSaga);
  yield spawn(loginSaga);
  yield spawn(appSaga);
  yield spawn(dashboardSaga);
  yield spawn(allNewsSaga);
  yield spawn(officelocationSaga);
  yield spawn(serviceByCategorySaga);
  yield spawn(contactPageSaga);
  yield spawn(mediaPartnershipSaga);
  yield spawn(newsSubCatSaga);
  yield spawn(termofuseSaga);
  yield spawn(setailSaga);
  yield spawn(aboutusSaga);
  yield spawn(privacypolicySaga);
  yield spawn(careersSaga);
  yield spawn(copyrightinoformationSaga);
}
