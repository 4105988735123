import React, { useState } from "react";
import { Tabs, Row, Col } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import AdvanceSearchComponent from "../advancesearch";
import TabSearchComponentWrapper from "./tabsearch";
import SimpleSearchComponent from "../simplesearch";
import {
  clearModalNewsSearchData,
  getModalNewsSearchData,
} from "../../containers/app/actions";
import { useDispatch } from "react-redux";
import ListView from "../listview";
import PaginationComponent from "../pagination";
import Loader from "../loader";
import "./tabserach.scss";

const TabSearchComponent = (props) => {
  // user to invoke redux actions
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState("");
  const [hasError, setHasError] = useState(false);
  const [selectedVal, setSelectedVal] = useState("enterrelease");
  const [releaseAllWords, setReleaseAllWords] = useState("");
  const [releaseLeaveOutWords, setReleaseLeaveOutWords] = useState("");
  const [releasePhrase, setReleasePhrase] = useState("");
  const [headlineAllWords, setHeadlineAllWords] = useState("");
  const [headlineLeaveOutWords, setHeadlineLeaveOutWords] = useState("");
  const [headlinePhrase, setHeadlinePhrase] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ticker, setTicker] = useState("");
  const [isin, setIsin] = useState("");
  const [hashtag, setHashtag] = useState("");
  const [cashtag, setCashtag] = useState("");
  const [pageNo, setPageNo] = useState(1);

  const onTabChange = (e) => {
    setSearchVal("");
    setSelectedVal("enterrelease");
    onUpdateSelectedOpt();
    setHasError(false);
    setPageNo(1);
    dispatch(clearModalNewsSearchData());
  };

  const onUpdateSelectedOpt = () => {
    setReleaseAllWords("");
    setReleaseLeaveOutWords("");
    setReleasePhrase("");
    setHeadlineAllWords("");
    setHeadlineLeaveOutWords("");
    setHeadlinePhrase("");
    setCompanyName("");
    setTicker("");
    setIsin("");
    setHashtag("");
    setCashtag("");
    setHasError("");
    setPageNo(1);
    dispatch(clearModalNewsSearchData());
  };

  const onResetPageNo = () => {
    setPageNo(1);
  };

  const onSetSimpCurrentPage = (e) => {
    setPageNo(e);
    dispatch(
      getModalNewsSearchData({
        entireRelease: searchVal,
        from: (e - 1) * 9,
        size: 9,
      })
    );
  };

  const onSetAdvCurrentPage = (e) => {
    setPageNo(e);

    if (selectedVal === "enterrelease") {
      if (releaseAllWords !== undefined) {
        dispatch(
          getModalNewsSearchData({
            entireRelease: releaseAllWords,
            exclude: releaseLeaveOutWords,
            exactPhrase: releasePhrase,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "headline") {
      if (headlineAllWords !== undefined) {
        dispatch(
          getModalNewsSearchData({
            headline: headlineAllWords,
            exclude: headlineLeaveOutWords,
            exactPhrase: headlinePhrase,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "companyname") {
      if (companyName) {
        dispatch(
          getModalNewsSearchData({
            companyName: companyName,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "ticker") {
      if (ticker) {
        dispatch(
          getModalNewsSearchData({
            ticker: ticker,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "isins") {
      if (isin) {
        dispatch(
          getModalNewsSearchData({
            isins: isin,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "hashtags") {
      if (hashtag) {
        dispatch(
          getModalNewsSearchData({
            hashtags: hashtag,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    } else if (selectedVal === "cashtags") {
      if (cashtag) {
        dispatch(
          getModalNewsSearchData({
            cashtags: cashtag,
            from: (e - 1) * 9,
            size: 9,
          })
        );
      } else {
        setHasError(true);
      }
    }
  };

  return (
    <>
      <TabSearchComponentWrapper>
        <Tabs
          defaultActiveKey={"1"}
          id="uncontrolled-tab-example"
          className="simple-search-text"
          tabBarStyle={{ border: "unset" }}
          onChange={(e) => onTabChange(e)}
        >
          <TabPane tab="Simple Search" key="1">
            <div className="tab-pane">
              <SimpleSearchComponent
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                hasError={hasError}
                setHasError={setHasError}
                pageNo={pageNo}
                resetPageNo={() => onResetPageNo()}
              />
              <Row justify="start" align="start" className="simple-search-div">
                <p className="result-count">
                  {props.modalSerachNewsData ? (
                    props.modalSerachNewsData.length === 0 ? (
                      <div className="no-res-found">No Results Found</div>
                    ) : (
                      `${props.modalNewsCount} Search Results`
                    )
                  ) : (
                    ""
                  )}
                </p>
                <div className="modalsearch-card">
                  {props.modalLoader ? (
                    <Loader></Loader>
                  ) : (
                    <>
                      {props.modalSerachNewsData ? (
                        props.modalSerachNewsData.map((data) => {
                          return (
                            <>
                              <Col
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <ListView
                                  className="modalsearch-listview-card"
                                  newsDate={data.date}
                                  metaTitle={data.headline}
                                  coverImage={data.cover}
                                  metaLogo={data.logo}
                                  metaDescription={data?.body?.description}
                                  id={data.id}
                                  language={data.language}
                                  closeModal={() => props.closeModal()}
                                ></ListView>
                              </Col>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <div>
                    {!props.modalLoader &&
                    props.modalSerachNewsData &&
                    props.modalSerachNewsData.length ? (
                      <Row>
                        <Col
                          sm={{ span: 22, offset: 1 }}
                          md={{ span: 22, offset: 1 }}
                          lg={{ span: 18, offset: 3 }}
                          xl={{ span: 16, offset: 4 }}
                        >
                          <div>
                            <PaginationComponent
                              current={pageNo}
                              total={props.modalNewsCount}
                              onSetCurrent={(e) => onSetSimpCurrentPage(e)}
                              defaultPageSize={9}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Advance News Search" key="2">
            <div className="tab-pane">
              <AdvanceSearchComponent
                selectedVal={selectedVal}
                setSelectedVal={setSelectedVal}
                releaseAllWords={releaseAllWords}
                setReleaseAllWords={setReleaseAllWords}
                releaseLeaveOutWords={releaseLeaveOutWords}
                setReleaseLeaveOutWords={setReleaseLeaveOutWords}
                releasePhrase={releasePhrase}
                setReleasePhrase={setReleasePhrase}
                headlineAllWords={headlineAllWords}
                setHeadlineAllWords={setHeadlineAllWords}
                headlineLeaveOutWords={headlineLeaveOutWords}
                setHeadlineLeaveOutWords={setHeadlineLeaveOutWords}
                headlinePhrase={headlinePhrase}
                setHeadlinePhrase={setHeadlinePhrase}
                companyName={companyName}
                setCompanyName={setCompanyName}
                ticker={ticker}
                setTicker={setTicker}
                isin={isin}
                setIsin={setIsin}
                hashtag={hashtag}
                setHashtag={setHashtag}
                cashtag={cashtag}
                setCashtag={setCashtag}
                updateSelectedOpt={() => onUpdateSelectedOpt()}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                hasError={hasError}
                setHasError={setHasError}
                pageNo={pageNo}
                resetPageNo={() => onResetPageNo()}
              />
              <Row justify="start" align="start" className="adv-search-div">
                <p className="result-count">
                  {props.modalSerachNewsData ? (
                    props.modalSerachNewsData.length === 0 ? (
                      <div className="no-res-found">No Results Found</div>
                    ) : (
                      `${props.modalNewsCount} Search Results`
                    )
                  ) : (
                    ""
                  )}
                </p>
                <div className="modalsearch-card">
                  {props.modalLoader ? (
                    <Loader></Loader>
                  ) : (
                    <>
                      {props.modalSerachNewsData ? (
                        props.modalSerachNewsData.map((data) => {
                          return (
                            <>
                              <Col
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                              >
                                <ListView
                                  className="modalsearch-listview-card"
                                  newsDate={data.date}
                                  metaTitle={data.headline}
                                  coverImage={data.cover}
                                  metaLogo={data.logo}
                                  metaDescription={data?.body?.description}
                                  id={data.id}
                                  language={data.language}
                                  closeModal={() => props.closeModal()}
                                ></ListView>
                              </Col>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <div>
                    {!props.modalLoader &&
                    props.modalSerachNewsData &&
                    props.modalSerachNewsData.length ? (
                      <Row>
                        <Col
                          sm={{ span: 22, offset: 1 }}
                          md={{ span: 22, offset: 1 }}
                          lg={{ span: 18, offset: 3 }}
                          xl={{ span: 16, offset: 4 }}
                        >
                          <div>
                            <PaginationComponent
                              current={pageNo}
                              total={props.modalNewsCount}
                              onSetCurrent={(e) => onSetAdvCurrentPage(e)}
                              defaultPageSize={9}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Row>
            </div>
          </TabPane>
        </Tabs>
      </TabSearchComponentWrapper>
    </>
  );
};

export default TabSearchComponent;
