import { takeEvery, put } from "redux-saga/effects";
import { GET_CONTACTPAGE_DATA } from "./constants";
import { get } from "../../utils/api";
import { getContactPageDataSuccess, getContactPageDataFail } from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getContactPageData(pageName) {
  let data = yield get("/bwcms/page/contact")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactPageData = yield data.data;
    yield put(updateLanguageData(contactPageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getContactPageDataSuccess(contactPageData));
  } else {
    yield put(getContactPageDataFail(data));
  }
}

function* ContactPageSaga() {
  yield takeEvery(GET_CONTACTPAGE_DATA, getContactPageData);
}

export default ContactPageSaga;
