import {
  GET_PRIVACY_POLICY_CONTACTUS,
  GET_PRIVACY_POLICY_CONTACTUS_SUCCESS,
  GET_PRIVACY_POLICY_CONTACTUS_FAIL,
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_PRIVACY_POLICY_FAIL,
} from "./constants";

export const getPrivacypolicyContactus = (data) => ({
  type: GET_PRIVACY_POLICY_CONTACTUS,
  data,
});

export const getPrivacypolicyContactusSuccess = (data) => ({
  type: GET_PRIVACY_POLICY_CONTACTUS_SUCCESS,
  data,
});

export const getPrivacypolicyContactusFail = (data) => ({
  type: GET_PRIVACY_POLICY_CONTACTUS_FAIL,
  data,
});

export const getPrivacypolicyPageData = (data) => ({
  type: GET_PRIVACY_POLICY,
  data,
});

export const getPrivacypolicyPageDataSuccess = (data) => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  data,
});

export const getPrivacypolicyPageDataFail = (data) => ({
  type: GET_PRIVACY_POLICY_FAIL,
  data,
});
