import produce from "immer";

import {
  GET_CAREERS_DATA,
  GET_CAREERS_DATA_SUCCESS,
  GET_CAREERS_DATA_FAIL,
  GET_CAREERS_CONTACTUS_DATA,
  GET_CAREERS_CONTACTUS_DATA_SUCCESS,
  GET_CAREERS_CONTACTUS_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  careersData: [],
  contactUsData: []
};

const careersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {

      case GET_CAREERS_DATA:
        draft.loading = true;
        break;
      case GET_CAREERS_DATA_SUCCESS:
        draft.loading = false;
        draft.careersData = action.data;
        break;
      case GET_CAREERS_DATA_FAIL:
        draft.loading = false;
        break;

      case GET_CAREERS_CONTACTUS_DATA:
        draft.loading = true;
        break;
      case GET_CAREERS_CONTACTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.contactUsData = action.data;
        break;
      case GET_CAREERS_CONTACTUS_DATA_FAIL:
        draft.loading = false;
        break;
      
      default:
        return draft;
    }
  });

export default careersReducer;
