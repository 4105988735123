import React from "react";
import { Row, Col, Button, Input, Radio } from "antd";
import AdvanceSearchComponentWrapper from "./advancesearch";
import Search1 from "../../images/header/search1.svg";
import Clear from "../../images/search/clear.svg";
import { useDispatch } from "react-redux";
import { getModalNewsSearchData } from "../../containers/app/actions";
import "./advancesearch.scss";
const RadioGroup = Radio.Group;

const AdvanceSearchComponent = (props) => {
  // user to invoke redux actions
  const dispatch = useDispatch();
  const options = [
    { label: "Entire Release", value: "enterrelease" },
    { label: "Headline", value: "headline" },
    { label: "Company Name", value: "companyname" },
    { label: "Ticker", value: "ticker" },
    { label: "ISIN", value: "isins" },
    { label: "Hashtag", value: "hashtags" },
    { label: "Cashtag", value: "cashtags" },
  ];

  const onChangeVal = (e) => {
    if (e?.target?.value) {
      props.setSelectedVal(e.target.value);
      props.updateSelectedOpt();
    }
  };

  const handleSearch = () => {
    props.resetPageNo();
    if (props.selectedVal === "enterrelease") {
      if (
        props.releaseAllWords ||
        props.releaseLeaveOutWords ||
        props.releasePhrase
      ) {
        dispatch(
          getModalNewsSearchData({
            entireRelease: encodeURIComponent(props.releaseAllWords),
            exclude: encodeURIComponent(props.releaseLeaveOutWords),
            exactPhrase: encodeURIComponent(props.releasePhrase),
            from: 0,
            size: 9,
          })
        );
      } else {
        alert("Please enter search data");
      }
    } else if (props.selectedVal === "headline") {
      if (
        props.headlineAllWords ||
        props.headlineLeaveOutWords ||
        props.headlinePhrase
      ) {
        dispatch(
          getModalNewsSearchData({
            headline: encodeURIComponent(props.headlineAllWords),
            exclude: encodeURIComponent(props.headlineLeaveOutWords),
            exactPhrase: encodeURIComponent(props.headlinePhrase),
            from: 0,
            size: 9,
          })
        );
      } else {
        alert("Please enter search data");
      }
    } else if (props.selectedVal === "companyname") {
      if (props.companyName) {
        dispatch(
          getModalNewsSearchData({
            companyName: encodeURIComponent(props.companyName),
            from: 0,
            size: 9,
          })
        );
      } else {
        props.setHasError(true);
      }
    } else if (props.selectedVal === "ticker") {
      if (props.ticker) {
        dispatch(
          getModalNewsSearchData({
            ticker: encodeURIComponent(props.ticker),
            from: 0,
            size: 9,
          })
        );
      } else {
        props.setHasError(true);
      }
    } else if (props.selectedVal === "isins") {
      if (props.isin) {
        dispatch(
          getModalNewsSearchData({
            isins: encodeURIComponent(props.isin),
            from: 0,
            size: 9,
          })
        );
      } else {
        props.setHasError(true);
      }
    } else if (props.selectedVal === "hashtags") {
      if (props.hashtag) {
        let hashtags = props.hashtag.split(" ");
        let updatedHashtags = hashtags.map((tag) => {
          if (tag.indexOf("#") !== -1) {
            return tag;
          } else {
            return "#" + tag;
          }
        });
        dispatch(
          getModalNewsSearchData({
            hashtags: encodeURIComponent(updatedHashtags.join(" ")),
            from: 0,
            size: 9,
          })
        );
      } else {
        props.setHasError(true);
      }
    } else if (props.selectedVal === "cashtags") {
      if (props.cashtag) {
        let cashtags = props.cashtag.split(" ");
        let updatedCashtags = cashtags.map((tag) => {
          if (tag.indexOf("$") !== -1) {
            return tag;
          } else {
            return "$" + tag;
          }
        });
        dispatch(
          getModalNewsSearchData({
            cashtags: encodeURIComponent(updatedCashtags.join(" ")),
            from: 0,
            size: 9,
          })
        );
      } else {
        props.setHasError(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const renderSelectedSearchUI = () => {
    if (props.selectedVal === "enterrelease") {
      return (
        <>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            className="div-mt"
          >
            <Row>
              <Col span={24} tabIndex="0">
                <label className="search-title">Find All These Words</label>
              </Col>
              <Col span={24}>
                <Input
                  tabIndex="0"
                  placeholder="Enter Search Words"
                  aria-label="Search"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  value={props.releaseAllWords}
                  onChange={(e) => {
                    props.setReleaseAllWords(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      tabIndex="0"
                      src={Clear}
                      height={24}
                      width={24}
                      alt=""
                      onClick={() => {
                        props.setReleaseAllWords("");
                      }}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Release</div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            className="div-mt"
          >
            <Row>
              <Col span={24} tabIndex="0">
                <label className="search-title">Find This Exact Phrase</label>
              </Col>
              <Col span={24} tabIndex="0">
                <Input
                  placeholder="Enter Phase Term"
                  aria-label="Search"
                  className="adv-search-input"
                  onKeyDown={handleKeyDown}
                  value={props.releasePhrase}
                  onChange={(e) => props.setReleasePhrase(e.target.value)}
                  suffix={
                    <img
                      src={Clear}
                      height={24}
                      width={24}
                      alt=""
                      onClick={() => props.setReleasePhrase("")}
                    />
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            className="div-mt"
          >
            <Row>
              <Col span={24} tabIndex="0">
                <label className="search-title">Leave Out These Words</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Input
                  tabIndex="0"
                  placeholder="Enter Words to Leave Out"
                  className="adv-search-input"
                  aria-label="Search"
                  onKeyDown={handleKeyDown}
                  value={props.releaseLeaveOutWords}
                  onChange={(e) =>
                    props.setReleaseLeaveOutWords(e.target.value)
                  }
                  suffix={
                    <img
                      src={Clear}
                      height={24}
                      width={24}
                      alt=""
                      onClick={() => props.setReleaseLeaveOutWords("")}
                    />
                  }
                />
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                  tabIndex="0"
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "headline") {
      return (
        <>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            className="div-mt"
          >
            <Row>
              <Col span={24} tabIndex="0">
                <label className="search-title">Find All These Words</label>
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Enter Search Words"
                  onKeyDown={handleKeyDown}
                  aria-label="Search"
                  className="adv-search-input"
                  value={props.headlineAllWords}
                  onChange={(e) => {
                    props.setHeadlineAllWords(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setHeadlineAllWords("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Headline</div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            className="div-mt"
          >
            <Row>
              <Col span={24}>
                <label className="search-title">Find This Exact Phrase</label>
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Enter Phase Term"
                  onKeyDown={handleKeyDown}
                  aria-label="Search"
                  className="adv-search-input"
                  value={props.headlinePhrase}
                  onChange={(e) => props.setHeadlinePhrase(e.target.value)}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setHeadlinePhrase("")}
                    />
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            className="div-mt"
          >
            <Row>
              <Col span={24}>
                <label className="search-title">Leave Out These Words</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Input
                  placeholder="Enter Words to Leave Out"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.headlineLeaveOutWords}
                  onChange={(e) =>
                    props.setHeadlineLeaveOutWords(e.target.value)
                  }
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setHeadlineLeaveOutWords("")}
                    />
                  }
                />
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "companyname") {
      return (
        <>
          <Col span={24} className="div-mt">
            <Row>
              <Col span={24}>
                <label className="search-title"> Search by Company Name</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
              >
                <Input
                  placeholder="Enter Company Name"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.companyName}
                  onChange={(e) => {
                    props.setCompanyName(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setCompanyName("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Company Name</div>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "ticker") {
      return (
        <>
          <Col span={24} className="div-mt">
            <Row>
              <Col span={24}>
                <label className="search-title">Search by Ticker</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
              >
                <Input
                  placeholder="Enter Ticker"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.ticker}
                  onChange={(e) => {
                    props.setTicker(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setTicker("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Ticker</div>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "isins") {
      return (
        <>
          <Col span={24} className="div-mt">
            <Row>
              <Col span={24}>
                <label className="search-title">Search by ISIN</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
              >
                <Input
                  placeholder="Enter ISIN"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.isin}
                  onChange={(e) => {
                    props.setIsin(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setIsin("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter ISIN</div>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "hashtags") {
      return (
        <>
          <Col span={24} className="div-mt">
            <Row>
              <Col span={24}>
                <label className="search-title"> Search by Hashtag(s)</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
              >
                <Input
                  placeholder="Enter Hashtag(s): (Delimited by Space)"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.hashtag}
                  onChange={(e) => {
                    props.setHashtag(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setHashtag("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Hashtag(s)</div>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    height={26}
                    width={21}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    } else if (props.selectedVal === "cashtags") {
      return (
        <>
          <Col span={24} className="div-mt">
            <Row>
              <Col span={24}>
                <label className="search-title">Search by Cashtag(s)</label>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 18 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
              >
                <Input
                  placeholder="Enter Cashtag(s): (Delimited by Space)"
                  onKeyDown={handleKeyDown}
                  className="adv-search-input"
                  aria-label="Search"
                  value={props.cashtag}
                  onChange={(e) => {
                    props.setCashtag(e.target.value);
                    props.setHasError(false);
                  }}
                  status={props.hasError ? "error" : ""}
                  suffix={
                    <img
                      src={Clear}
                      height={20}
                      width={20}
                      alt=""
                      onClick={() => props.setCashtag("")}
                    />
                  }
                />
                {props.hasError ? (
                  <div className="error-text">Please Enter Cashtag(s)</div>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Button
                  type="primary"
                  className="adv-search-btn"
                  onClick={() => handleSearch()}
                >
                  <span className="search-text">Search</span>
                  <img
                    src={Search1}
                    alt={"search icon"}
                    className="modal-search-icon"
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      );
    }
  };

  return (
    <>
      <AdvanceSearchComponentWrapper>
        <Row className="advance-search">
          <Col
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
          >
            <div className="radio-group">
              <RadioGroup
                options={options}
                onChange={(e) => onChangeVal(e)}
                name="radiogroup"
                value={props.selectedVal}
                buttonStyle="solid"
              ></RadioGroup>
            </div>
          </Col>
          {renderSelectedSearchUI()}
        </Row>
      </AdvanceSearchComponentWrapper>
    </>
  );
};

export default AdvanceSearchComponent;
