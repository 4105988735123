import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const ShareIconWrapper = styled.div`
  .sortfilterCont {
    .sortText {
      color: ${colors["@text-body"]};
    }
  }
`;

export default ShareIconWrapper;
