import React from "react";
import { Pagination } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PaginationComponentWrapper from "./pagination";
import "./pagination.scss";

const PaginationComponent = (props) => {

  const onChange = (page) => {
    props.onSetCurrent(page);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href="#nogo" className="previousLink">
          {" "}
          <LeftOutlined className="left-arrow" />{" "}
          <span className="hide-pagi-text">Previous</span>
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href="#nogo" className="nextLink">
          <span className="hide-pagi-text">Next</span>{" "}
          <RightOutlined className="right-arrow" />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <>
      <PaginationComponentWrapper>
        <Pagination
          simple 
          current={props.current}
          onChange={onChange}
          total={props.total}
          prevText="Previous"
          nextText="Next"
          showPrevNextJumpers={true}
          showSizeChanger={false}
          itemRender={itemRender}
          className="paginationSec custom-pagination"
          defaultPageSize={props.defaultPageSize ? props.defaultPageSize : 10}
          responsive={true}
          showLessItems={true}
        />
      </PaginationComponentWrapper>
    </>
  );
};
export default PaginationComponent;
