import { combineReducers } from "redux";
import educationReducer from "../containers/education/reducer";
import homeReducer from "../containers/home/reducer";
import newsDetailReducer from "../containers/newsdetail/reducer";
import serviceReducer from "../containers/service/reducer";
import loginReducer from "../containers/login/reducer";
import appReducer from "../containers/app/reducer";
import dashboardReducer from "../containers/dashboard/reducer";
import aboutusReducer from "../containers/aboutus/reducer";
import allNewsReducer from "../containers/allnews/reducer";
import officelocationReducer from "../containers/officelocation/reducer";
import serviceByCategoryReducer from "../containers/serviceByCategory/reducer";
import contactPageReducer from "../containers/contactpage/reducer";
import mediaPartnershipReducer from "../containers/mediapartnershipcontacts/reducer";
import newsSubCatReducer from "../containers/newsbycategory/reducer";
import privacyPolicyReducer from "../containers/privacypolicy/reducer";
import termOfUseReducer from "../containers/termofuse/reducer";
import copyrightInoformationReducer from "../containers/copyrightInformation/reducer";
import setailReducer from "../containers/setail/reducer";
import careersReducer from "../containers/careers/reducer";

export default combineReducers({
  educationReducer,
  homeReducer,
  newsDetailReducer,
  serviceReducer,
  loginReducer,
  appReducer,
  dashboardReducer,
  allNewsReducer,
  officelocationReducer,
  serviceByCategoryReducer,
  contactPageReducer,
  mediaPartnershipReducer,
  newsSubCatReducer,
  termOfUseReducer,
  setailReducer,
  aboutusReducer,
  privacyPolicyReducer,
  careersReducer,
  copyrightInoformationReducer,
});
