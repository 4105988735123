import React from "react";
import { Row, Col, Button, Input } from "antd";
import "./simplesearch.scss";
import Search1 from "../../images/header/search1.svg";
import Clear from "../../images/search/clear.svg";
import SimpleSearchComponentWrapper from "./simplesearch";
import { getModalNewsSearchData } from "../../containers/app/actions";
import { useDispatch } from "react-redux";

const SimpleSearchComponent = (props) => {
  // user to invoke redux actions
  const dispatch = useDispatch();

  const handleSearch = () => {
    props.resetPageNo();
    if (props.searchVal) {
      dispatch(
        getModalNewsSearchData({
          entireRelease: encodeURIComponent(props.searchVal),
          from: 0,
          size: 9,
        })
      );
    } else {
      props.setHasError(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <SimpleSearchComponentWrapper>
        <Row className="simple-search">
          <Col span={24}>
            <label className="search-title" tabIndex="0">
              Search Keyword
            </label>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 18 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
          >
            <Input
              placeholder="Enter Search Keyword"
              className="simple-search-input"
              aria-label="Search"
              onKeyDown={handleKeyDown}
              value={props.searchVal}
              onChange={(e) => {
                props.setSearchVal(e.target.value);
                props.setHasError(false);
              }}
              status={props.hasError ? "error" : ""}
              tabIndex="0"
              suffix={
                <img
                  src={Clear}
                  height={24}
                  width={24}
                  alt=""
                  onClick={() => props.setSearchVal("")}
                />
              }
            />
            {props.hasError ? (
              <div className="error-text">Please Enter Search Keyword</div>
            ) : (
              <></>
            )}
          </Col>
          <Col
            sm={{ span: 6 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            tabIndex="0"
          >
            <Button
              type="primary"
              className="simple-search-btn"
              onClick={() => handleSearch()}
            >
              <span className="search-text" tabIndex="0">
                Search
              </span>
              <img
                tabIndex="0"
                src={Search1}
                alt={"search icon"}
                className="modal-search-icon"
              />
            </Button>
          </Col>
        </Row>
      </SimpleSearchComponentWrapper>
    </>
  );
};

export default SimpleSearchComponent;
