import {
  GET_TERM_OF_USE_CONTACTUS_DATA,
  GET_TERM_OF_USE_CONTACTUS_DATA_SUCCESS,
  GET_TERM_OF_USE_CONTACTUS_DATA_FAIL,
  GET_TERM_OF_USE_PAGE_DATA,
  GET_TERM_OF_USE_PAGE_DATA_SUCCESS,
  GET_TERM_OF_USE_PAGE_DATA_FAIL,
} from "./constants";

export const getTermOfUseContactus = (data) => ({
  type: GET_TERM_OF_USE_CONTACTUS_DATA,
  data,
});

export const getTermOfUseContactusSuccess = (data) => ({
  type: GET_TERM_OF_USE_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getTermOfUseContactusFail = (data) => ({
  type: GET_TERM_OF_USE_CONTACTUS_DATA_FAIL,
  data,
});

export const getTermOfUsePageData = (data) => ({
  type: GET_TERM_OF_USE_PAGE_DATA,
  data,
});

export const getTermOfUsePageDataSuccess = (data) => ({
  type: GET_TERM_OF_USE_PAGE_DATA_SUCCESS,
  data,
});

export const getTermOfUsePageDataFail = (data) => ({
  type: GET_TERM_OF_USE_PAGE_DATA_FAIL,
  data,
});
