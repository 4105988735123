import {
  GET_NEWS_DETAILS_DATA,
  GET_NEWS_DETAILS_DATA_SUCCESS,
  GET_NEWS_DETAILS_DATA_FAIL,
  GET_RELEASE_VERSION,
  GET_RELEASE_VERSION_SUCCESS,
  GET_RELEASE_VERSION_FAIL,
  GET_ENEVELOPE_DATA,
  GET_ENEVELOPE_DATA_SUCCESS,
  GET_ENEVELOPE_DATA_FAIL,
  GET_MORE_NEWS_DATA,
  GET_MORE_NEWS_DATA_SUCCESS,
  GET_MORE_NEWS_DATA_FAIL,
  GET_NEWS_NEWSLETTER_BANNER_DATA,
  GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS,
  GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL,
  GET_MEDIA_BANNER,
  GET_MEDIA_BANNER_SUCCESS,
  GET_MEDIA_BANNER_FAIL,
  GET_NEWS_DETAILS_RECOMMENDED,
  GET_NEWS_DETAILS_RECOMMENDED_SUCCESS,
  GET_NEWS_DETAILS_RECOMMENDED_FAIL,
  GET_NEWS_DETAILS_PAGE_DATA,
  GET_NEWS_DETAILS_PAGE_DATA_SUCCESS,
  GET_NEWS_DETAILS_PAGE_DATA_FAIL,
} from "./constants";

export const getNewsDetails = (data) => ({
  type: GET_NEWS_DETAILS_DATA,
  data,
});

export const getNewsDetailsSuccess = (data) => ({
  type: GET_NEWS_DETAILS_DATA_SUCCESS,
  data,
});

export const getNewsDetailsFail = (data) => ({
  type: GET_NEWS_DETAILS_DATA_FAIL,
  data,
});

export const getReleaseVersion = (data) => ({
  type: GET_RELEASE_VERSION,
  data,
});

export const getReleaseVersionSuccess = (data) => ({
  type: GET_RELEASE_VERSION_SUCCESS,
  data,
});

export const getReleaseVersionFail = (data) => ({
  type: GET_RELEASE_VERSION_FAIL,
  data,
});

export const getEnevelopData = (data) => ({
  type: GET_ENEVELOPE_DATA,
  data,
});

export const getEnevelopDataSuccess = (data) => ({
  type: GET_ENEVELOPE_DATA_SUCCESS,
  data,
});

export const getEnevelopDataFail = (data) => ({
  type: GET_ENEVELOPE_DATA_FAIL,
  data,
});

export const getMoreNewsData = (data) => ({
  type: GET_MORE_NEWS_DATA,
  data,
});

export const getMoreNewsDataSuccess = (data) => ({
  type: GET_MORE_NEWS_DATA_SUCCESS,
  data,
});

export const getMoreNewsDataFail = (data) => ({
  type: GET_MORE_NEWS_DATA_FAIL,
  data,
});

export const getNewsNewsLetterBannerData = (data) => ({
  type: GET_NEWS_NEWSLETTER_BANNER_DATA,
  data,
});

export const getNewsNewsLetterBannerDataSuccess = (data) => ({
  type: GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS,
  data,
});

export const getNewsNewsLetterBannerDataFail = (data) => ({
  type: GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL,
  data,
});

export const getMediaBanner = (data) => ({
  type: GET_MEDIA_BANNER,
  data,
});

export const getMediaBannerSuccess = (data) => ({
  type: GET_MEDIA_BANNER_SUCCESS,
  data,
});

export const getMediaBannerFail = (data) => ({
  type: GET_MEDIA_BANNER_FAIL,
  data,
});

export const getNewsDetailsRecommended = (data) => ({
  type: GET_NEWS_DETAILS_RECOMMENDED,
  data,
});

export const getNewsDetailsRecommendedSuccess = (data) => ({
  type: GET_NEWS_DETAILS_RECOMMENDED_SUCCESS,
  data,
});

export const getNewsDetailsRecommendedFail = (data) => ({
  type: GET_NEWS_DETAILS_RECOMMENDED_FAIL,
  data,
});

export const getNewsDetailsPageData = (data) => ({
  type: GET_NEWS_DETAILS_PAGE_DATA,
  data,
});

export const getNewsDetailsPageDataSuccess = (data) => ({
  type: GET_NEWS_DETAILS_PAGE_DATA_SUCCESS,
  data,
});

export const getNewsDetailsPageDataFail = (data) => ({
  type: GET_NEWS_DETAILS_PAGE_DATA_FAIL,
  data,
});
