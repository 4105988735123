import React from "react";
const DashboardPage = React.lazy(() => import("../containers/dashboard"));
const HomePage = React.lazy(() => import("../containers/home"));
const ServicePage = React.lazy(() => import("../containers/service"));
const AboutUsPage = React.lazy(() => import("../containers/aboutus"));
const Login = React.lazy(() => import("../containers/login/login"));
const NewsByCatPage = React.lazy(() => import("../containers/newsbycategory"));
const NewsDetailPage = React.lazy(() => import("../containers/newsdetail"));
const EducationPage = React.lazy(() => import("../containers/education"));
const Officelocation = React.lazy(() => import("../containers/officelocation"));
const Contactpage = React.lazy(() => import("../containers/contactpage"));
const AllNewsPage = React.lazy(() => import("../containers/allnews"));
const Privacypolicy = React.lazy(() => import("../containers/privacypolicy"));
const Setail = React.lazy(() => import("../containers/setail"));
const CareersPage = React.lazy(() => import("../containers/careers"));
const NotFound = React.lazy(() => import("../containers/NotFound"));

const MediaPartnershipPage = React.lazy(() =>
  import("../containers/mediapartnershipcontacts")
);
const ServiceByCategory = React.lazy(() =>
  import("../containers/serviceByCategory")
);
const TermOfUsePage = React.lazy(() => import("../containers/termofuse"));
const CopyrightInoformationPage = React.lazy(() =>
  import("../containers/copyrightInformation")
);
const SitemapPage = React.lazy(() => import("../containers/sitemap"));
export const routes = [
  {
    path: "/",
    component: <DashboardPage />,
  },

  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/home",
    component: <HomePage />,
  },
  {
    path: "/education",
    component: <EducationPage />,
  },
  {
    path: "/news/:newsCat",
    component: <NewsByCatPage />,
  },
  {
    path: "/:langcode/news/:newsCat",
    component: <NewsByCatPage />,
  },
  {
    path: "/news/:newsCat*",
    component: <NewsByCatPage />,
  },
  {
    path: "/newsdetail/:newsId",
    component: <NewsDetailPage />,
  },
  {
    path: "/:langcode/newsdetail/:newsId*",
    component: <NewsDetailPage />,
  },
  // {
  //   path: "/newsdetail/:newsId/:lang*",
  //   component: <NewsDetailPage />,
  // },
  {
    path: "/services",
    component: <ServicePage />,
  },
  {
    path: "/:langcode/services",
    component: <ServicePage />,
  },
  {
    path: "/aboutus",
    component: <AboutUsPage />,
  },
  {
    path: "/contact/office-locations",
    component: <Officelocation />,
  },
  {
    path: "/contact-us",
    component: <Contactpage />,
  },
  {
    path: "/contact/media-and-partnership-contacts",
    component: <MediaPartnershipPage />,
  },
  {
    path: "/services/:servicePageId",
    component: <ServiceByCategory />,
  },
  {
    path: "/:langcode/services/:servicePageId",
    component: <ServiceByCategory />,
  },
  {
    path: "/news",
    component: <AllNewsPage />,
  },
  {
    path: "/privacy",
    component: <Privacypolicy />,
  },
  {
    path: "/terms-of-use",
    component: <TermOfUsePage />,
  },
  {
    path: "/services/public-relations/press-release-distribution",
    component: <Setail />,
  },
  {
    path: "/:langcode/services/public-relations/press-release-distribution",
    component: <Setail />,
  },
  {
    path: "/careers",
    component: <CareersPage />,
  },
  {
    path: "/copyright",
    component: <CopyrightInoformationPage />,
  },
  {
    path: "/sitemap.xml",
    component: <SitemapPage />,
  },
  {
    path: "/notfound",
    component: <NotFound />,
  },
];
