export const GET_MENU_DATA = "GET_MENU_DATA";
export const GET_MENU_DATA_SUCCESS = "GET_MENU_DATA_SUCCESS";
export const GET_MENU_DATA_FAIL = "GET_MENU_DATA_FAIL";
export const GET_LFOOTER_DATA = "GET_LFOOTER_DATA";
export const GET_LFOOTER_DATA_SUCCESS = "GET_LFOOTER_DATA_SUCCESS";
export const GET_LFOOTER_DATA_FAIL = "GET_LFOOTER_DATA_FAIL";
export const GET_MFOOTER_DATA = "GET_MFOOTER_DATA";
export const GET_MFOOTER_DATA_SUCCESS = "GET_MFOOTER_DATA_SUCCESS";
export const GET_MFOOTER_DATA_FAIL = "GET_MFOOTER_DATA_FAIL";
export const GET_BLOG_DATA = "GET_BLOG_DATA";
export const GET_BLOG_DATA_SUCCESS = "GET_BLOG_DATA_SUCCESS";
export const GET_BLOG_DATA_FAIL = "GET_BLOG_DATA_FAIL";
export const GET_LANGUAGE_DATA = "GET_LANGUAGE_DATA";
export const GET_LANGUAGE_DATA_SUCCESS = "GET_LANGUAGE_DATA_SUCCESS";
export const GET_LANGUAGE_DATA_FAIL = "GET_LANGUAGE_DATA_FAIL";
export const GET_BNAVIGATION_DATA = "GET_BNAVIGATION_DATA";
export const GET_BNAVIGATION_DATA_SUCCESS = "GET_BNAVIGATION_DATA_SUCCESS";
export const GET_BNAVIGATION_DATA_FAIL = "GET_BNAVIGATION_DATA_FAIL";
export const GET_GLOBAL_SETTINGS = "GET_GLOBAL_SETTINGS";
export const GET_GLOBAL_SETTINGS_SUCCESS = "GET_GLOBAL_SETTINGS_SUCCESS";
export const GET_GLOBAL_SETTINGS_FAIL = "GET_GLOBAL_SETTINGS_FAIL";
export const UPDATE_LANGUAGE_DATA = "UPDATE_LANGUAGE_DATA";
export const GET_MODAL_NEWS_SEARCH_DATA = "GET_MODAL_NEWS_SEARCH_DATA";
export const GET_MODAL_NEWS_SEARCH_DATA_SUCCESS =
  "GET_MODAL_NEWS_SEARCH_DATA_SUCCESS";
export const GET_MODAL_NEWS_SEARCH_DATA_FAIL =
  "GET_MODAL_NEWS_SEARCH_DATA_FAIL";
export const CLEAR_MODAL_NEWS_SEARCH_DATA = "CLEAR_MODAL_NEWS_SEARCH_DATA";
export const GET_LOGIN_BTN_DATA = "GET_LOGIN_BTN_DATA";
export const GET_LOGIN_BTN_DATA_SUCCESS = "GET_LOGIN_BTN_DATA_SUCCESS";
export const GET_LOGIN_BTN_DATA_FAIL = "GET_LOGIN_BTN_DATA_FAIL";
export const UPDATE_MODAL_SEARCH_NEWS_CNT = "UPDATE_MODAL_SEARCH_NEWS_CNT";
//temp code for service page footer links
export const UPDATE_FOOTER_LANGUAGE_DATA = "UPDATE_FOOTER_LANGUAGE_DATA";
