import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";
const TabSearchComponentWrapper = styled.div`
  // .simple-search-text .ant-tabs-tab .ant-tabs-tab-btn {
  //   color: ${colors["@text-body-light"]};
  // }

  .simple-search-text > .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .simple-search-text .ant-tabs-tab .ant-tabs-tab-btn{
    color: ${colors["@text-body-light"]};
  }
  .simple-search-text .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors["@text-highlight"]};
  }
`;
export default TabSearchComponentWrapper;
