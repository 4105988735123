import { takeEvery, put } from "redux-saga/effects";
import {
  GET_NEWS_CAT_DATA,
  GET_POPULAR_NEWS_SUBCAT_DATA,
  GET_NEWS_SUBCAT_DATA,
  GET_NEWS_CAT_MEDIA_BANNER,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER,
  GET_NEWS_CAT_PAGE_DATA,
  GET_NEWS_MM_LANG_DATA,
  GET_NEWS_CAT_PRESSPASSDATA,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS,
} from "./constants";
import { getBW, get, getBWPublic } from "../../utils/api";
import {
  getNewsSubCatDataSuccess,
  getNewsSubCatDataFail,
  getPopularNewsDataSuccess,
  getPopularNewsDataFail,
  getNewsCatDataSuccess,
  getNewsCatDataFail,
  getNewsCatMediaBannerSuccess,
  getNewsCatMediaBannerFail,
  getNewsCatSignUpNewsLetterSuccess,
  getNewsCatSignUpNewsLetterFail,
  getNewsCatPageDataSuccess,
  getNewsCatPageDataFail,
  getNewsMMLangDataSuccess,
  getNewsMMLangDataFail,
  getNewsCatPresspassDataSuccess,
  getNewsCatPresspassDataFail,
  getFollowSocialFail,
  getFollowSocialSuccess,
  updateSubCatPageNo,
  updateSubCatCount,
  getPopularNewsDetailsFail,
  getPopularNewsDetailsSuccess,
  getNewsCatPresspassData,
} from "./actions";
import { formatNewsData, formatSearchNewsData } from "../../utils/helper";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";
import { URL_REGEX, URL_REGEX1 } from "../../utils/constants";

const checkInd = (ind, val) => {
  return ind.startsWith(val);
};

const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (
      nestedValue &&
      nestedValue[keyToFind]?.toLowerCase() == valToFind?.toLowerCase()
    ) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

function* getNewsCat(params) {
  let data = yield getBWPublic(
    "/api/v1/client/newsInterestsCategoriesSetup/public"
  )
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    let categoryData = yield data.data.data;
    let keys = Object.keys(categoryData);
    let ind = keys.findIndex((data) => checkInd(data, params.data));
    if (ind !== -1) {
      let updatedUrlData = categoryData[keys[ind]].map((data) => {
        data.url = data?.name
          ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase()
          : data?.code
          ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase()
          : "";
        if (data.childList) {
          data.childList.map((data1) => {
            data1.url = data1?.name
              ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                "/" +
                data1?.name.replaceAll(URL_REGEX, "-").toLowerCase()
              : data1?.code
              ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                "/" +
                data1?.code.replaceAll(URL_REGEX, "-").toLowerCase()
              : "";
            data1.url = data1.url.replaceAll(URL_REGEX1, "");
            if (data1.childList) {
              data1.childList.map((data2) => {
                data2.url = data2?.name
                  ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                    "/" +
                    data1?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                    "/" +
                    data2?.name.replaceAll(URL_REGEX, "-").toLowerCase()
                  : data2?.code
                  ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                    "/" +
                    data1?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                    "/" +
                    data2?.code.replaceAll(URL_REGEX, "-").toLowerCase()
                  : "";
                data2.url = data2.url.replaceAll(URL_REGEX1, "");
                return data2;
              });
            }
            return data1;
          });
        }

        data.url = data.url.replaceAll(URL_REGEX1, "");
        return data;
      });

      yield put(getNewsCatDataSuccess(updatedUrlData));
    }
  } else {
    yield put(getNewsCatDataFail(data));
  }
}

function* getPopularNews() {
  let data = yield getBW("/news/popular")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const popularNews = yield typeof data.data.data === "string"
      ? JSON.parse(data.data.data)
      : data.data.data;
    let updatedData = popularNews.map((data) => {
      return formatNewsData(data);
    });
    yield put(getPopularNewsDataSuccess(updatedData));
  } else {
    yield put(getPopularNewsDataFail(data));
  }
}

function* getNewsSubCat(params) {
  if (params?.data?.newsCat === "language") {
    // if (params?.data?.startdate) {
    //   yield put(updateSubCatPageNo(1));
    // }
    //Need one more API call for subcategory data
    let data = yield getBWPublic(
      "/api/v1/client/newsInterestsCategoriesSetup/public"
    )
      .then((result) => {
        return result;
      })
      .catch(() => console.error());
    if (data && data.status === 200) {
      const newsSubCatData = yield data.data.data;
      let keys = Object.keys(newsSubCatData);
      let ind = keys.findIndex((data) => checkInd(data, params.data.newsCat));
      if (ind !== -1) {
        let updatedUrlData = newsSubCatData[keys[ind]].map((data) => {
          data.url = data?.name
            ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase()
            : data?.code
            ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase()
            : "";
          if (data.childList) {
            data.childList.map((data1) => {
              data1.url = data1?.name
                ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                  "/" +
                  data1?.name.replaceAll(URL_REGEX, "-").toLowerCase()
                : data1?.code
                ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                  "/" +
                  data1?.code.replaceAll(URL_REGEX, "-").toLowerCase()
                : "";
              data1.url = data1.url.replaceAll(URL_REGEX1, "");
              if (data1.childList) {
                data1.childList.map((data2) => {
                  data2.url = data2?.name
                    ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data1?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data2?.name.replaceAll(URL_REGEX, "-").toLowerCase()
                    : data2?.code
                    ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data1?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data2?.code.replaceAll(URL_REGEX, "-").toLowerCase()
                    : "";
                  data2.url = data2.url.replaceAll(URL_REGEX1, "");
                  return data2;
                });
              }
              return data1;
            });
          }

          data.url = data.url.replaceAll(URL_REGEX1, "");
          return data;
        });

        yield put(getNewsCatDataSuccess(updatedUrlData));
        let rec = findNestedObj(
          newsSubCatData[keys[ind]],
          "code",
          params.data.vnsId
        );

        if (rec) {
          yield put(getNewsSubCatDataSuccess(rec));
          yield put(getNewsCatPresspassData(rec.code));
          let url = params?.data?.startdate
            ? "/news?lang=" +
              params.data.vnsId +
              "&startdate=" +
              params.data.startdate +
              "&enddate=" +
              params.data.enddate +
              "&page=" +
              params?.data?.subCatPageNo +
              "&perpage=" +
              params.data.subCatPageSize
            : "/news?lang=" +
              params.data.vnsId +
              "&page=" +
              params?.data?.subCatPageNo +
              "&perpage=" +
              params.data.subCatPageSize;
          let data = yield getBW(url)
            .then((result) => {
              return result;
            })
            .catch(() => console.error());
          if (data && data.status === 200) {
            let newsData = yield data.data.data;
            let updatedData = newsData.map((data) => {
              return formatNewsData(data);
            });
            yield put(getNewsMMLangDataSuccess(updatedData));
            yield put(updateSubCatCount(data.data.count));
          } else {
            yield put(getNewsMMLangDataFail(data));
          }
        }
      }
    } else {
      yield put(getNewsSubCatDataFail(data));
    }
  } else {
    //Need one more API call for subcategory data
    let data = yield getBWPublic(
      "/api/v1/client/newsInterestsCategoriesSetup/public"
    )
      .then((result) => {
        return result;
      })
      .catch(() => console.error());
    if (data && data.status === 200) {
      const newsSubCatData = yield data.data.data;
      let keys = Object.keys(newsSubCatData);
      let ind = keys.findIndex((data) => checkInd(data, params.data.newsCat));
      if (ind !== -1) {
        let updatedUrlData = newsSubCatData[keys[ind]].map((data) => {
          data.url = data?.name
            ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase()
            : data?.code
            ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase()
            : "";
          if (data.childList) {
            data.childList.map((data1) => {
              data1.url = data1?.name
                ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                  "/" +
                  data1?.name.replaceAll(URL_REGEX, "-").toLowerCase()
                : data1?.code
                ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                  "/" +
                  data1?.code.replaceAll(URL_REGEX, "-").toLowerCase()
                : "";

              data1.url = data1.url.replaceAll(URL_REGEX1, "");
              if (data1.childList) {
                data1.childList.map((data2) => {
                  data2.url = data2?.name
                    ? data?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data1?.name.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data2?.name.replaceAll(URL_REGEX, "-").toLowerCase()
                    : data2?.code
                    ? data?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data1?.code.replaceAll(URL_REGEX, "-").toLowerCase() +
                      "/" +
                      data2?.code.replaceAll(URL_REGEX, "-").toLowerCase()
                    : "";
                  data2.url = data2.url.replaceAll(URL_REGEX1, "");
                  return data2;
                });
              }
              return data1;
            });
          }
          data.url = data.url.replaceAll(URL_REGEX1, "");
          return data;
        });

        yield put(getNewsCatDataSuccess(updatedUrlData));

        let rec = findNestedObj(
          newsSubCatData[keys[ind]],
          "url",
          params.data.vnsId
        );
        if (rec) {
          yield put(getNewsSubCatDataSuccess(rec));
          yield put(getNewsCatPresspassData(rec.vocabId));
          // API call for subcategory news

          let keyword =
            params.data.newsCat !== "industry"
              ? "categorykeywords"
              : "industrykeywords";
          if (params?.data?.search) {
            updateSubCatPageNo(1);
          }
          let url = params?.data?.search
            ? "/news/search?" +
              keyword +
              "=" +
              encodeURIComponent(rec.name) +
              "&keywords=" +
              params.data.search +
              "&from=" +
              (params.data.subCatPageNo - 1) * params.data.subCatPageSize +
              "&size=" +
              params.data.subCatPageSize
            : "/news/search?" +
              keyword +
              "=" +
              encodeURIComponent(rec.name) +
              "&from=" +
              (params.data.subCatPageNo - 1) * params.data.subCatPageSize +
              "&size=" +
              params.data.subCatPageSize;
          let data1 = yield getBW(url)
            .then((result) => {
              return result;
            })
            .catch(() => console.error());
          if (data1 && data1.status === 200) {
            let searchNews = yield data1?.data?.data?.hits;
            let updatedData = searchNews.map((data) => {
              return formatSearchNewsData(data._source);
            });
            yield put(getNewsMMLangDataSuccess(updatedData));
            yield put(updateSubCatCount(data1?.data?.data?.total?.value));
          } else {
            yield put(getNewsMMLangDataFail(data1));
          }
        }
      }
    } else {
      yield put(getNewsSubCatDataFail(data));
    }
  }
}

function* getNewsCatMediaBannerData() {
  let data = yield get("/bwcms/globalcomponent/media-banner")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const mediaBannerData = yield data.data;
    yield put(getNewsCatMediaBannerSuccess(mediaBannerData));
  } else {
    yield put(getNewsCatMediaBannerFail(data));
  }
}

function* getNewsCatSignUpNewsLetterData() {
  let data = yield get("/bwcms/globalcomponent/newsletter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsLetterSignUpData = yield data.data;
    yield put(getNewsCatSignUpNewsLetterSuccess(newsLetterSignUpData));
  } else {
    yield put(getNewsCatSignUpNewsLetterFail(data));
  }
}

function* getNewsCatPageData(params) {
  let data = yield get("/bwcms/page?name=" + params.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getNewsCatPageDataSuccess(pageData));
  } else {
    yield put(getNewsCatPageDataFail(data));
  }
}

function* getNewsMMLangdata(params) {
  let url = params?.data?.startdate
    ? "/news?mm=true&startdate=" +
      params.data.startdate +
      "&enddate=" +
      params.data.enddate +
      "&page=1&perpage=" +
      params?.data?.subCatPageSize
    : "/news?mm=true&page=" +
      params?.data?.subCatPageNo +
      "&perpage=" +
      params?.data?.subCatPageSize;
  let data = yield getBW(url)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    let newsData = yield data.data.data;
    let updatedData = newsData.map((data) => {
      return formatNewsData(data);
    });
    yield put(getNewsMMLangDataSuccess(updatedData));
    yield put(updateSubCatCount(data.data.count));
  } else {
    yield put(getNewsMMLangDataFail(data));
  }
}
function* getNewCatPresspassData(params) {
  if (params.data) {
    let data = yield get("/bwcms/category?id=" + params.data)
      .then((result) => {
        return result;
      })
      .catch(() => console.error());
    if (data && data.status === 200) {
      const presspassData = yield data?.data[0];
      let updatedData = {
        description: presspassData.rssLinkText,
        icon: presspassData.rssIcon,
        icon_alt_text: "",
        link: presspassData.rssLink,
        id: presspassData.id,
        image: presspassData.image,
        subtitle: presspassData.subtitle,
        title: presspassData.title,
        ogTitle: presspassData.seoDetails.ogTitle,
        ogDescription: presspassData.seoDetails.ogDescription,
        ogImage: presspassData.seoDetails.ogImage,
      };
      yield put(getNewsCatPresspassDataSuccess(updatedData));
    } else {
      yield put(getNewsCatPresspassDataFail(data));
    }
  } else {
    let data = yield get("/bwcms/globalcomponent/right")
      .then((result) => {
        return result;
      })
      .catch(() => console.error());
    if (data && data.status === 200) {
      const presspassData = yield data.data;
      yield put(getNewsCatPresspassDataSuccess(presspassData));
    } else {
      yield put(getNewsCatPresspassDataFail(data));
    }
  }
}

function* getFollowSocialData() {
  let data = yield get("/bwcms/globalcomponent/twitter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const followsocialData = yield data.data;
    yield put(getFollowSocialSuccess(followsocialData));
  } else {
    yield put(getFollowSocialFail(data));
  }
}

function* getPopularNewsDetails() {
  let data = yield get("/bwcms/globalcomponent/popular_news")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const popularNewsDetails = yield data.data;
    yield put(getPopularNewsDetailsSuccess(popularNewsDetails));
  } else {
    yield put(getPopularNewsDetailsFail(data));
  }
}

function* newsSubCatSaga() {
  yield takeEvery(GET_NEWS_CAT_DATA, getNewsCat);
  yield takeEvery(GET_POPULAR_NEWS_SUBCAT_DATA, getPopularNews);
  yield takeEvery(GET_NEWS_SUBCAT_DATA, getNewsSubCat);
  yield takeEvery(GET_NEWS_CAT_MEDIA_BANNER, getNewsCatMediaBannerData);
  yield takeEvery(
    GET_NEWS_CAT_SIGN_UP_NEWS_LETTER,
    getNewsCatSignUpNewsLetterData
  );
  yield takeEvery(GET_NEWS_CAT_PAGE_DATA, getNewsCatPageData);
  yield takeEvery(GET_NEWS_MM_LANG_DATA, getNewsMMLangdata);
  yield takeEvery(GET_NEWS_CAT_PRESSPASSDATA, getNewCatPresspassData);

  yield takeEvery(GET_NEWS_CAT_FOLLOWSOCIAL_DATA, getFollowSocialData);
  yield takeEvery(GET_NEWS_CAT_POPULAR_NEWS_DETAILS, getPopularNewsDetails);
}

export default newsSubCatSaga;
