import { takeEvery, put } from "redux-saga/effects";
import { GET_MEDIA_PARTNERSHIP, GET_MEDIA_CONTACT_US } from "./constants";
import { get } from "../../utils/api";
import {
  getMediaPartnershipSuccess,
  getMediaPartnershipFail,
  getMediaContactUsSuccess,
  getMediaContactUsFail,
} from "./actions";

import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getMediaPartnership(params) {
  let data = yield get("/bwcms/page/" + params.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const mediaPageData = yield data.data;

    yield put(updateLanguageData(mediaPageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getMediaPartnershipSuccess(mediaPageData));
  } else {
    yield put(getMediaPartnershipFail(data));
  }
}

function* getMediaContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getMediaContactUsSuccess(contactData));
  } else {
    yield put(getMediaContactUsFail(data));
  }
}

function* mediaPartnershipSaga() {
  yield takeEvery(GET_MEDIA_PARTNERSHIP, getMediaPartnership);
  yield takeEvery(GET_MEDIA_CONTACT_US, getMediaContactUs);
}

export default mediaPartnershipSaga;
