import produce from "immer";

import {
  GET_ABOUTUS_DATA,
  GET_ABOUTUS_DATA_SUCCESS,
  GET_ABOUTUS_DATA_FAIL,
  GET_ABOUTUS_CONTACTUS_DATA,
  GET_ABOUTUS_CONTACTUS_DATA_SUCCESS,
  GET_ABOUTUS_CONTACTUS_DATA_FAIL,
  GET_ABOUTUS_TESTIMONIALS_DATA,
  GET_ABOUTUS_TESTIMONIALS_DATA_SUCCESS,
  GET_ABOUTUS_TESTIMONIALS_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  aboutUsData: [],
  contactUsData: [],
  testimonialsData: [],
};

const aboutusReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ABOUTUS_DATA:
        draft.loading = true;
        break;
      case GET_ABOUTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.aboutUsData = action.data;
        break;
      case GET_ABOUTUS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_ABOUTUS_CONTACTUS_DATA:
        draft.loading = true;
        break;
      case GET_ABOUTUS_CONTACTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.contactUsData = action.data;
        break;
      case GET_ABOUTUS_CONTACTUS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_ABOUTUS_TESTIMONIALS_DATA:
        draft.loading = true;
        break;
      case GET_ABOUTUS_TESTIMONIALS_DATA_SUCCESS:
        draft.loading = false;
        draft.testimonialsData = action.data;
        break;
      case GET_ABOUTUS_TESTIMONIALS_DATA_FAIL:
        draft.loading = false;
        break;
      default:
        return draft;
    }
  });

export default aboutusReducer;
