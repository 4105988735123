import { generateLink } from "../../utils/helper";
import { Menu, Input } from "antd";
import DownArrow from "../../images/header/down_arrow.svg";
import Search1 from "../../images/header/search1.svg";

const { SubMenu } = Menu;

export const getMenuTitle = (title, url, target, active) => {
  return (
    <span>
      {generateLink(title, url, target, false, active)}
      <img
        src={DownArrow}
        width={10}
        height={10}
        className="hide-arrow-element"
        alt={"DownArrow"}
      />
    </span>
  );
};

export const renderMobileMenuOpt = (menuData) => {
  return menuData.map((menu) => {
    return (
      <SubMenu
        key={menu.id}
        title={getMenuTitle(menu.title, menu.url, menu.target, menu.active)}
        label={menu.title}
      >
        {menu.children.map((submenu) => {
          return (
            <>
              {(submenu.description || submenu?.children?.length === 0) ? (
                <Menu.Item
                  key={submenu.id}
                  label={submenu.title}
                  title={submenu.title}
                >
                  <div className="menu-desc">
                    {generateLink(
                      submenu.title,
                      submenu.url,
                      submenu.target,
                      false,
                      submenu.active
                    )}
                  </div>
                </Menu.Item> 
              ) : (
                <SubMenu
                  key={submenu.id}
                  title={getMenuTitle(
                    submenu.title,
                    submenu.url,
                    submenu.target,
                    submenu.active
                  )}
                  label={submenu.title}
                >
                  {submenu.children.map((childmenu) => {
                    return (
                      <Menu.Item
                        key={childmenu.id}
                        label={childmenu.title}
                        title={childmenu.title}
                      >
                        {generateLink(
                          childmenu.title,
                          childmenu.url,
                          childmenu.target,
                          false,
                          childmenu.active
                        )}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              )}
            </>
          );
        })}
      </SubMenu>
    );
  });
};

export const renderMobileMenu = (
  menuData,
  loginBtnData,
  mobileSearchBox,
  languages,
  selectedlang,
  handleChange
) => {
  return (
    <>
      {renderMobileMenuOpt(menuData)}
      {/* temporary hide */}
      {/* <SubMenu
        key={"Language(EN)"}
        title={"Language(EN)"}
        label={"Language(EN)"}
      >
        <Menu.Item key={"All"} label={"All"} title={"All"}>
          {"All"}
        </Menu.Item>
        <Menu.Item key={"English"} label={"English"} title={"English"}>
          {"English"}
        </Menu.Item>
        <Menu.Item key={"Deutsch"} label={"Deutsch"} title={"Deutsch"}>
          {"Deutsch"}
        </Menu.Item>
        <Menu.Item key={"Español"} label={"Español"} title={"Español"}>
          {"Español"}
        </Menu.Item>
        <Menu.Item key={"Français"} label={"Français"} title={"Français"}>
          {"Français"}
        </Menu.Item>
        <Menu.Item key={"Italiano"} label={"Italiano"} title={"Italiano"}>
          {"Italiano"}
        </Menu.Item>
        <Menu.Item key={"简体中文"} label={"简体中文"} title={"简体中文"}>
          {"简体中文"}
        </Menu.Item>
        <Menu.Item key={"繁體中文"} label={"繁體中文"} title={"繁體中文"}>
          {"繁體中文"}
        </Menu.Item>
        <Menu.Item key={"日本語"} label={"日本語"} title={"日本語"}>
          {"日本語"}
        </Menu.Item>
      </SubMenu> */}
      {languages?.length ? (
        <>
          <SubMenu
            key={"Language"}
            title={`Language (${selectedlang})`}
            label={"Language"}
          >
            {languages.map((lang) => {
              return (
                <Menu.Item
                  key={lang.value}
                  label={lang.label}
                  title={lang.name}
                  onClick={() => handleChange(lang.value)}
                >
                  {lang.name}
                </Menu.Item>
              );
            })}
          </SubMenu>
        </>
      ) : (
        <></>
      )}
      {loginBtnData?.length ? (
        <>
          {loginBtnData[0]?.title ? (
            <>
              <Menu.Item key={loginBtnData[0].title}>
                <a
                  href={loginBtnData[0].url}
                  target={loginBtnData[0].target}
                  rel="noreferrer"
                >
                  {loginBtnData[0].title}
                </a>
              </Menu.Item>
            </>
          ) : (
            <></>
          )}
          {loginBtnData[1]?.title ? (
            <>
              <Menu.Item key={loginBtnData[1].title}>
                <a
                  href={loginBtnData[1].url}
                  target={loginBtnData[1].target}
                  rel="noreferrer"
                >
                  {loginBtnData[1].title}
                </a>
              </Menu.Item>
            </>
          ) : (
            <></>
          )}
        </>
      ) : null}
      <Menu.Item key={"serach"} className="searchSec">
        <Input
          className="header-search header-search-mob"
          placeholder="Search"
          aria-label="search"
          prefix={
            <img src={Search1} height={16} width={16} alt="serach icon" />
          }
          onClick={() => mobileSearchBox()}
        />
      </Menu.Item>
    </>
  );
};
