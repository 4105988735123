import { takeEvery, put } from "redux-saga/effects";
import {
  GET_TRENDING_NEWS,
  GET_HOME_PAGE_DATA,
  GET_VIDEO_DATA,
  GET_HOME_CONTACTUS_DATA,
  GET_TESTIMONIALS_DATA,
  GET_NEWSLETTER_SIGNUP_DATA,
  GET_WHOWEARE_DATA,
  GET_LATEST_NEWS_DATA,
  GET_MULT_NEWS_DATA,
} from "./constants";
import { getBW, get } from "../../utils/api";
import {
  getTrendingNewsSuccess,
  getTrendingNewsFail,
  getHomePageDataSuccess,
  getHomePageDataFail,
  getWhoweareDataSuccess,
  getWhoweareDataFail,
  getVideoDataSuccess,
  getVideoDataFail,
  getHomeContactUsDataSuccess,
  getHomeContactUsDataFail,
  getTestimonialsDataSuccess,
  getTestimonialsDataFail,
  getNewsLetterSignUpDataSuccess,
  getNewsLetterSignUpDataFail,
  getLatestNewsSuccess,
  getLatestNewsFail,
  getMultNewsSuccess,
  getMultNewsFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";
import { formatNewsData } from "../../utils/helper";
import {
  REACT_APP_TRENDING_CNT,
  REACT_APP_LATEST_CNT,
  REACT_APP_NMM_CNT,
} from "../../utils/constants";

function* getTrendingNews() {
  let res = yield getBW("/news/trending?perpage=" + REACT_APP_TRENDING_CNT)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (res && res.status === 200) {
    const newsData = yield typeof res.data.data === "string"
      ? JSON.parse(res.data.data)
      : res.data.data;
    let updatedData = newsData.map((data) => {
      return formatNewsData(data);
    });
    yield put(getTrendingNewsSuccess(updatedData));
  } else {
    yield put(getTrendingNewsFail(res));
  }
}

function* getHomePageData(pageName) {
  let res = yield get("/bwcms/page?name=" + pageName.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (res && res.status === 200) {
    const homeData = yield res.data;
    yield put(updateLanguageData(homeData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getHomePageDataSuccess(homeData));
  } else {
    yield put(getHomePageDataFail(res));
  }
}

function* getVideoData() {
  let data = yield get("/bwcms/globalcomponent/video_component")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const videoData = yield data.data;
    yield put(getVideoDataSuccess(videoData));
  } else {
    yield put(getVideoDataFail(data));
  }
}

function* getWhoweareData() {
  let data = yield get("/bwcms/globalcomponent/who_we_are")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const WhoweareData = yield data.data;
    yield put(getWhoweareDataSuccess(WhoweareData));
  } else {
    yield put(getWhoweareDataFail(data));
  }
}

function* getHomeContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getHomeContactUsDataSuccess(contactData));
  } else {
    yield put(getHomeContactUsDataFail(data));
  }
}

function* getTestimonialsData() {
  let data = yield get("/bwcms/testimonials")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const testimonialData = yield data.data;
    yield put(getTestimonialsDataSuccess(testimonialData[0]));
  } else {
    yield put(getTestimonialsDataFail(data));
  }
}

function* getNewsLetterSignUpData(pageName) {
  let data = yield get("/bwcms/globalcomponent/newsletter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsLetterSignUpData = yield data.data;
    yield put(getNewsLetterSignUpDataSuccess(newsLetterSignUpData));
  } else {
    yield put(getNewsLetterSignUpDataFail(data));
  }
}

function* getLatestNews() {
  let res = yield getBW("/news?perpage=" + REACT_APP_LATEST_CNT)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (res && res.status === 200) {
    let latestNews = yield res.data.data;
    let updatedData = latestNews.map((data) => {
      return formatNewsData(data);
    });
    yield put(getLatestNewsSuccess(updatedData));
  } else {
    yield put(getLatestNewsFail(res));
  }
}

function* getMultNews() {
  let res = yield getBW("/news?mm=true&perpage=" + REACT_APP_NMM_CNT)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (res && res.status === 200) {
    let multiNewsData = yield res.data.data;
    let updatedData = multiNewsData.map((data) => {
      return formatNewsData(data);
    });
    yield put(getMultNewsSuccess(updatedData));
  } else {
    yield put(getMultNewsFail(res));
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_TRENDING_NEWS, getTrendingNews);
  yield takeEvery(GET_HOME_PAGE_DATA, getHomePageData);
  yield takeEvery(GET_VIDEO_DATA, getVideoData);
  yield takeEvery(GET_HOME_CONTACTUS_DATA, getHomeContactUs);
  yield takeEvery(GET_TESTIMONIALS_DATA, getTestimonialsData);
  yield takeEvery(GET_NEWSLETTER_SIGNUP_DATA, getNewsLetterSignUpData);
  yield takeEvery(GET_WHOWEARE_DATA, getWhoweareData);
  yield takeEvery(GET_LATEST_NEWS_DATA, getLatestNews);
  yield takeEvery(GET_MULT_NEWS_DATA, getMultNews);
}

export default dashboardSaga;
