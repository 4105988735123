import { takeEvery, put } from "redux-saga/effects";
import {
  GET_ALL_NEWS_DATA,
  GET_POPULAR_NEWS_DATA,
  GET_ALL_NEWS_MEDIA_BANNER,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER,
  GET_ALL_NEWS_PAGE_DATA,
  GET_ALL_NEWS_PRESSPASSDATA,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS,
} from "./constants";
import { getBW, get } from "../../utils/api";
import {
  getAllNewsDataSuccess,
  getAllNewsDataFail,
  getPopularNewsDataSuccess,
  getPopularNewsDataFail,
  getAllNewsMediaBannerSuccess,
  getAllNewsMediaBannerFail,
  getAllNewsSignUpNewsLetterSuccess,
  getAllNewsSignUpNewsLetterFail,
  getAllNewsPageDataSuccess,
  getAllNewsPageDataFail,
  getAllNewsPresspassDataSuccess,
  getAllNewsPresspassDataFail,
  updatePageNumber,
  updateTotalNewsCnt,
  getFollowSocialFail,
  getFollowSocialSuccess,
  getPopularAllNewsDetailsFail,
  getPopularAllNewsDetailsSuccess,
} from "./actions";
import { formatNewsData } from "../../utils/helper";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";
import { subCatPageSize } from "../../utils/constants";

function* getAllNews(params) {
  let url = params?.data?.startdate
    ? "/news?startdate=" +
      params.data.startdate +
      "&enddate=" +
      params.data.enddate +
      "&page=" +
      params?.data?.pageNo +
      "&perpage=" +
      subCatPageSize
    : "/news?page=" + params?.data?.pageNo + "&perpage=" + subCatPageSize;
  let data = yield getBW(url)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const news = yield data.data.data;
    let updatedData = news.map((data) => {
      return formatNewsData(data);
    });

    yield put(getAllNewsDataSuccess(updatedData));
    yield put(updateTotalNewsCnt(data.data.count));
  } else {
    yield put(getAllNewsDataFail(data));
  }
}

function* getPopularNews(newsId) {
  let data = yield getBW(`/news/popular`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const popularNews = yield typeof data.data.data === "string"
      ? JSON.parse(data.data.data)
      : data.data.data;
    let updatedData = popularNews.map((data) => {
      return formatNewsData(data);
    });
    yield put(getPopularNewsDataSuccess(updatedData));
  } else {
    yield put(getPopularNewsDataFail(data));
  }
}

function* getMediaBannerData() {
  let data = yield get("/bwcms/globalcomponent/media-banner")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const mediaBannerData = yield data.data;
    yield put(getAllNewsMediaBannerSuccess(mediaBannerData));
  } else {
    yield put(getAllNewsMediaBannerFail(data));
  }
}

function* getAllNewsSignUpNewsLetterData() {
  let data = yield get("/bwcms/globalcomponent/newsletter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsLetterSignUpData = yield data.data;
    yield put(getAllNewsSignUpNewsLetterSuccess(newsLetterSignUpData));
  } else {
    yield put(getAllNewsSignUpNewsLetterFail(data));
  }
}

function* getAllNewsPageData(params) {
  let data = yield get("/bwcms/page?name=" + params.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(getAllNewsPageDataSuccess(pageData));
    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
  } else {
    yield put(getAllNewsPageDataFail(data));
  }
}
function* getPresspassData() {
  let data = yield get("/bwcms/globalcomponent/right")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const presspassData = yield data.data;
    yield put(getAllNewsPresspassDataSuccess(presspassData));
  } else {
    yield put(getAllNewsPresspassDataFail(data));
  }
}

function* getFollowSocialData() {
  let data = yield get("/bwcms/globalcomponent/twitter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const followsocialData = yield data.data;
    yield put(getFollowSocialSuccess(followsocialData));
  } else {
    yield put(getFollowSocialFail(data));
  }
}

function* getPopularAllNewsDetails() {
  let data = yield get("/bwcms/globalcomponent/popular_news")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const popularNewsDetails = yield data.data;
    yield put(getPopularAllNewsDetailsSuccess(popularNewsDetails));
  } else {
    yield put(getPopularAllNewsDetailsFail(data));
  }
}

function* allNewsSaga() {
  yield takeEvery(GET_ALL_NEWS_DATA, getAllNews);
  yield takeEvery(GET_POPULAR_NEWS_DATA, getPopularNews);
  yield takeEvery(GET_ALL_NEWS_MEDIA_BANNER, getMediaBannerData);
  yield takeEvery(
    GET_ALL_NEWS_SIGN_UP_NEWS_LETTER,
    getAllNewsSignUpNewsLetterData
  );
  yield takeEvery(GET_ALL_NEWS_PAGE_DATA, getAllNewsPageData);
  yield takeEvery(GET_ALL_NEWS_PRESSPASSDATA, getPresspassData);
  yield takeEvery(GET_ALL_NEWS_FOLLOWSOCIAL_DATA, getFollowSocialData);
  yield takeEvery(GET_ALL_NEWS_POPULAR_NEWS_DETAILS, getPopularAllNewsDetails);
}

export default allNewsSaga;
