import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const PaginationComponentWrapper = styled.div`
  .paginationSec .previousLink {
    border-right: 1px solid ${colors["@separator"]};
  }
  .paginationSec .nextLink {
    border-left: 1px solid ${colors["@separator"]};
  }
  .ant-pagination-item-active a {
    color: ${colors["@btn-pill-dark-text"]};
  }
  .ant-pagination-item-active a:hover {
    color: ${colors["@btn-pill-dark-text"]};
  }

  .paginationSec a.nextLink,
  .paginationSec a.previousLink {
    color: ${colors["@pagination-link"]};
  }
  .paginationSec a[disabled] {
    color: ${colors["@text-body"]};
  }
  .custom-pagination .ant-pagination-disabled {
    color: ${colors["@text-body"]};
  }
  .ant-pagination-item {
    border-color: ${colors["@pagination"]};
  }
  .ant-pagination-item-active a {
    background: ${colors["@pagination-link"]};
  }
  .custom-pagination .ant-pagination-simple-pager input {
    border: 1px solid ${colors["@field-border"]};
  }
  .custom-pagination .ant-pagination-simple-pager input:focus {
    border-color: ${colors["@field-border-active-accent"]};
  }
  .custom-pagination
    .ant-pagination-simple-pager
    .ant-pagination-slash::before {
    background-color: ${colors["@pagination-bg"]};
  }
  @media only screen and (min-width: 360px) and (max-width: 768.98px) {
    .paginationSec .previousLink {
      border-right: unset;
    }
    .paginationSec .nextLink {
      border-left: unset;
    }
  }
`;
export default PaginationComponentWrapper;
