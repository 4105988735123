export const GET_ALL_NEWS_DATA = "GET_ALL_NEWS_DATA";
export const GET_ALL_NEWS_DATA_SUCCESS = "GET_ALL_NEWS_DATA_SUCCESS";
export const GET_ALL_NEWS_DATA_FAIL = "GET_ALL_NEWS_DATA_FAIL";
export const GET_POPULAR_NEWS_DATA = "GET_POPULAR_NEWS_DATA";
export const GET_POPULAR_NEWS_DATA_SUCCESS = "GET_POPULAR_NEWS_DATA_SUCCESS";
export const GET_POPULAR_NEWS_DATA_FAIL = "GET_POPULAR_NEWS_DATA_FAIL";
export const GET_SUBCATEGORY_DATA = "GET_SUBCATEGORY_DATA";
export const GET_SUBCATEGORY_DATA_SUCCESS = "GET_SUBCATEGORY_DATA_SUCCESS";
export const GET_SUBCATEGORY_DATA_FAIL = "GET_SUBCATEGORY_DATA_FAIL";
export const GET_ALL_NEWS_MEDIA_BANNER = "GET_ALL_NEWS_MEDIA_BANNER";
export const GET_ALL_NEWS_MEDIA_BANNER_SUCCESS =
  "GET_ALL_NEWS_MEDIA_BANNER_SUCCESS";
export const GET_ALL_NEWS_MEDIA_BANNER_FAIL = "GET_ALL_NEWS_MEDIA_BANNER_FAIL";
export const GET_ALL_NEWS_SIGN_UP_NEWS_LETTER =
  "GET_ALL_NEWS_SIGN_UP_NEWS_LETTER";
export const GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS =
  "GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS";
export const GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL =
  "GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL";
export const GET_ALL_NEWS_FOLLOWSOCIAL_DATA = "GET_ALL_NEWS_FOLLOWSOCIAL_DATA";
export const GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS =
  "GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS";
export const GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL =
  "GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL";
export const GET_ALL_NEWS_POPULAR_NEWS_DETAILS =
  "GET_ALL_NEWS_POPULAR_NEWS_DETAILS";
export const GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS =
  "GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS";
export const GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL =
  "GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL";
export const GET_ALL_NEWS_PAGE_DATA = "GET_ALL_NEWS_PAGE_DATA";
export const GET_ALL_NEWS_PAGE_DATA_SUCCESS = "GET_ALL_NEWS_PAGE_DATA_SUCCESS";
export const GET_ALL_NEWS_PAGE_DATA_FAIL = "GET_ALL_NEWS_PAGE_DATA_FAIL";
export const GET_ALL_NEWS_PRESSPASSDATA = "GET_ALL_NEWS_PRESSPASSDATA";
export const GET_ALL_NEWS_PRESSPASSDATA_SUCCESS =
  "GET_ALL_NEWS_PRESSPASSDATA_SUCCESS";
export const GET_ALL_NEWS_PRESSPASSDATA_FAIL =
  "GET_ALL_NEWS_PRESSPASSDATA_FAIL";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const UPDATE_TOTAL_NEWS_CNT = "UPDATE_TOTAL_NEWS_CNT";

export const UPDATE_FILTER_DATE = "UPDATE_FILTER_DATE";
