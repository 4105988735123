import { takeEvery, put } from "redux-saga/effects";
import {
  GET_SERVICE_PAGE_DATA,
  GET_SERVICE_CONTACTUS_DATA,
  GET_SERVICE_SIGN_UP_DATA,
  GET_SERVICE_DEMO_BANNER_DATA,
  GET_SERVICE_RECOMMEND_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getServicePageDataSuccess,
  getServicePageDataFail,
  getServiceContactUsDataSuccess,
  getServiceContactUsDataFail,
  getServiceSignUpDataSuccess,
  getServiceSignUpDataFail,
  getServiceDemoBannerDataSuccess,
  getServiceDemoBannerDataFail,
  getServiceRecommendDataSuccess,
  getServiceRecommendDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getServicePageData(pageName) {
  let data = yield get("/bwcms/page?name=" + pageName.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const serviceData = yield data.data;

    yield put(updateLanguageData(serviceData.languages));
    //temp code for service page footer links
    yield put(
      updateFooterLanguageData({
        description: "",
        id: 414,
        active: true,
        children: [
          {
            active: true,
            children: [],
            description: "",
            id: 417,
            language: "",
            target: "_blank",
            title: "Deustch",
            url: "https://services.businesswire.com/de/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 418,
            language: "",
            target: "_blank",
            title: "Español",
            url: "https://services.businesswire.com/es/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 419,
            language: "",
            target: "_blank",
            title: "Français",
            url: "https://services.businesswire.com/fr/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 420,
            language: "",
            target: "_blank",
            title: "Italiano",
            url: "https://services.businesswire.com/it/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 420,
            language: "",
            target: "_blank",
            title: "עברית",
            url: "https://services.businesswire.com/he/home",
          },

          {
            active: true,
            children: [],
            description: "",
            id: 421,
            language: "",
            target: "_blank",
            title: "简体中文",
            url: "https://services.businesswire.com/zh-cn/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 422,
            language: "",
            target: "_blank",
            title: "繁體中文",
            url: "https://services.businesswire.com/zh-hk/home",
          },
          {
            active: true,
            children: [],
            description: "",
            id: 423,
            language: "",
            target: "_blank",
            title: "日本語",
            url: "https://services.businesswire.com/ja/home",
          },
        ],
        language: "",
        target: "_blank",
        title: "English",
        url: "https://services.businesswire.com/",
      })
    );
    yield put(getServicePageDataSuccess(serviceData));
  } else {
    yield put(getServicePageDataFail(data));
  }
}

function* getContactUs() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getServiceContactUsDataSuccess(contactData));
  } else {
    yield put(getServiceContactUsDataFail(data));
  }
}

function* getServiceSignUpData() {
  let data = yield get("/bwcms/globalcomponent/sign_up")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const signUpData = yield data.data;
    yield put(getServiceSignUpDataSuccess(signUpData));
  } else {
    yield put(getServiceSignUpDataFail(data));
  }
}

function* getDemoBannerData() {
  let data = yield get("/bwcms/globalcomponent/demo")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const signUpData = yield data.data;
    yield put(getServiceDemoBannerDataSuccess(signUpData));
  } else {
    yield put(getServiceDemoBannerDataFail(data));
  }
}
function* getServiceRecommendData() {
  let data = yield get("/bwcms/page?name=service")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const recommendData = yield data.data;
    yield put(getServiceRecommendDataSuccess(recommendData));
  } else {
    yield put(getServiceRecommendDataFail(data));
  }
}

function* serviceSaga() {
  yield takeEvery(GET_SERVICE_PAGE_DATA, getServicePageData);
  yield takeEvery(GET_SERVICE_CONTACTUS_DATA, getContactUs);
  yield takeEvery(GET_SERVICE_SIGN_UP_DATA, getServiceSignUpData);
  yield takeEvery(GET_SERVICE_DEMO_BANNER_DATA, getDemoBannerData);
  yield takeEvery(GET_SERVICE_RECOMMEND_DATA, getServiceRecommendData);
}

export default serviceSaga;
