import axios from "axios";
import { REACT_APP_API_URL, NEWS_API_URL, NEWS_PUBLIC_API_URL } from "./urls";

//For CMS API
let CMSApi = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Set the AUTH token for every request
CMSApi.interceptors.request.use(function (config) {
  // const token = localStorage.getItem("token");
  // config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.params = {
    ...config.params,
    wpml_language: localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en",
  };

  return config;
});

//For BW news API
let BWApi = axios.create({
  baseURL: NEWS_API_URL,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

let BWPublic = axios.create({
  baseURL: NEWS_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

const get = (url) => {
  return CMSApi.get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const post = (url, data) => {
  return CMSApi.post(url, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getBW = (url) => {
  return BWApi.get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getBWWithNF = (url) => {
    return BWApi.get(url)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            if (error.code === "ERR_BAD_REQUEST") {
                console.log("Loading page not found");
                window.open("/notfound", "_self");
            }
        });
};

const postBW = (url, data) => {
  return BWApi.post(url, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getBWPublic = (url) => {
  return BWPublic.get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

export { get, post, getBW, postBW, getBWPublic, getBWWithNF };
