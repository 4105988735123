import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const ListWrapper = styled.div`
  .share-icon {
    color: ${colors["@required-text"]};
  }
  .listview .ant-card-meta-title,
  .listview .ant-card-meta-title a {
    color: ${colors["@text-body-dark"]};
  }
  .listview .date-sect {
    color: ${colors["@date"]};
  }
  .listview .ant-card-meta-description {
    color: ${colors["@text-body"]};
  }
  .listview .card-footer {
    border-top: 0.5px solid ${colors["@separator"]};
  }
`;
export default ListWrapper;
