import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const AppWrapper = styled.div`
  .app-page {
    background: ${colors["@bg"]};
  }
  .error-text {
    color: ${colors["@error-text"]};
  }
`;
export default AppWrapper;
