import produce from "immer";

import {
  GET_OFFICE_LOCATIONS,
  GET_OFFICE_LOCATIONS_SUCCESS,
  GET_OFFICE_LOCATIONS_FAIL,
  GET_CONTACTUSOFF_DATA,
  GET_CONTACTUSOFF_DATA_SUCCESS,
  GET_CONTACTUSOFF_DATA_FAIL,
  GET_OFFICE_PAGE_DATA,
  GET_OFFICE_PAGE_DATA_SUCCESS,
  GET_OFFICE_PAGE_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  officeLocations: [],
  contactOfficeLoc: [],
  officePageData: [],
};

const officelocationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_OFFICE_LOCATIONS:
        draft.loading = true;
        break;
      case GET_OFFICE_LOCATIONS_SUCCESS:
        draft.loading = false;
        draft.officeLocations = action.data;
        break;
      case GET_OFFICE_LOCATIONS_FAIL:
        draft.loading = false;
        break;
      case GET_CONTACTUSOFF_DATA:
        draft.loading = true;
        break;
      case GET_CONTACTUSOFF_DATA_SUCCESS:
        draft.loading = false;
        draft.contactOfficeLoc = action.data;
        break;
      case GET_CONTACTUSOFF_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_OFFICE_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_OFFICE_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.officePageData = action.data;
        break;
      case GET_OFFICE_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      default:
        return draft;
    }
  });

export default officelocationReducer;
