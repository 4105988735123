import React from "react";
import { Col, Row } from "antd";
import "./pagefooter.scss";
import PagefooterWrapper from "./pagefooter";

const PageFooter = (props) => {
  const onLogoClick = () => {
    props.handleActiveLinks();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onItemClick = (e, item) => {
    if (item.language) {
      localStorage.setItem("lang", item.language);
    }
  };

  return (
    <PagefooterWrapper>
      <div className="footer">
        <Row gutter={8} justify={"space-between"}>
          {props.mfooterData.map &&
            props.mfooterData.map((footer, i) => (
              <Col
                xs={4}
                sm={i === 4 ? 24 : 12}
                md={i === 4 ? 24 : 4}
                lg={i === 4 ? 4 : 5}
                xl={i === 4 ? 4 : 5}
                key={footer.id}
                className="footer-gutter-space"
              >
                <Col xl={24} lg={24} md={24} sm={24} className="pl-0">
                  <Row
                    className={i === 4 ? "footer-alt-title " : "footer-title"}
                  >
                    <Col>
                      <div
                        onClick={(e) => onItemClick(e, footer)}
                        className={i === 4 ? "all-lang" : "footer-title"}
                      >
                        <a
                          href={footer.url}
                          target={footer.target}
                          className={
                            footer.active
                              ? "footer-alt-title"
                              : "footer-alt-title isDisabled"
                          }
                        >
                          {footer.title}
                        </a>
                      </div>
                      {footer.children.map &&
                        footer.children.map((item, ikey) => (
                          <div
                            className={
                              i === 3
                                ? "footer-link"
                                : i === 4
                                ? "footer-alt-link lang-links"
                                : "footer-items"
                            }
                            key={item.id}
                            onClick={(e) => onItemClick(e, item)}
                          >
                            <a
                              href={item.url}
                              target={item.target}
                              className={
                                i === 3
                                  ? item.active
                                    ? "footer-link"
                                    : "footer-link isDisabled"
                                  : i === 4
                                  ? item.active
                                    ? "footer-alt-links"
                                    : "footer-alt-links isDisabled"
                                  : item.active
                                  ? "footer-items"
                                  : "footer-items isDisabled"
                              }
                            >
                              {item.title}
                            </a>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Col>
            ))}
        </Row>
      </div>

      <div className="next-footer-container">
        <hr className="footer-hr" />
        <Row gutter={30} className="next-footer-container_content">
          <Col xl={7} lg={7} md={8} sm={24} className="footer-logo-col">
            <div className="logo-footer">
              <a
                href={props.lfooterData.footerlogoUrl}
                target=""
                rel="noreferrer"
              >
                <img
                  src={props.lfooterData.footerLogo}
                  alt={props.lfooterData.footerLogoAltText}
                  onClick={onLogoClick}
                />
              </a>
              <div className="logo-text" tabIndex="0">
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.lfooterData.websiteDescription,
                  }}
                ></div>
              </div>
            </div>
          </Col>
          <Col
            xl={17}
            lg={17}
            md={16}
            sm={24}
            className={"footer-link-cointainer"}
          >
            <Row justify="space-between" className="footer-nav-list">
              {props.bnavigationData &&
                props.bnavigationData.map((nd, index) => {
                  return (
                    <Col
                      xl={4}
                      lg={8}
                      md={8}
                      sm={"auto"}
                      key={nd.id}
                      className="footer-nav-item"
                    >
                      <a
                        href={nd.url}
                        target={nd.target}
                        className={
                          index === 3
                            ? nd.active
                              ? " footer-nav ot-sdk-show-settings"
                              : " footer-nav ot-sdk-show-settings isDisabled"
                            : nd.active
                            ? "footer-nav"
                            : "footer-nav isDisabled"
                        }
                        id={index === 3 ? "ot-sdk-btn" : ""}
                      >
                        {nd.title}
                      </a>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="copyright">
        <div className="copyright-content">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12}>
              <a
                href={props.lfooterData.copyrightUrl}
                target=""
                rel="noreferrer"
              >
                {props.lfooterData.copyrightText}
              </a>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12}>
              <Row className="social ant-row-end">
                {props.lfooterData.socialMedia &&
                  props.lfooterData.socialMedia.map((sm) => {
                    return (
                      <Col className="linked">
                        <a href={sm.hyperlink} target="_blank" rel="noreferrer">
                          <img
                            src={sm.icon}
                            alt={sm.label}
                            height={18}
                            width={18}
                          />
                        </a>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </PagefooterWrapper>
  );
};
export default PageFooter;
