import {
  GET_POPULAR_NEWS_SUBCAT_DATA,
  GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS,
  GET_POPULAR_NEWS_SUBCAT_DATA_FAIL,
  GET_NEWS_SUBCAT_DATA,
  GET_NEWS_SUBCAT_DATA_SUCCESS,
  GET_NEWS_SUBCAT_DATA_FAIL,
  GET_NEWS_CAT_DATA,
  GET_NEWS_CAT_DATA_SUCCESS,
  GET_NEWS_CAT_DATA_FAIL,
  GET_NEWS_CAT_MEDIA_BANNER,
  GET_NEWS_CAT_MEDIA_BANNER_SUCCESS,
  GET_NEWS_CAT_MEDIA_BANNER_FAIL,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS,
  GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL,
  GET_NEWS_CAT_PAGE_DATA,
  GET_NEWS_CAT_PAGE_DATA_SUCCESS,
  GET_NEWS_CAT_PAGE_DATA_FAIL,
  GET_NEWS_MM_LANG_DATA,
  GET_NEWS_MM_LANG_DATA_SUCCESS,
  GET_NEWS_MM_LANG_DATA_FAIL,
  UPDATE_CAT_PAGE_NO,
  UPDATE_SUBCAT_PAGE_NO,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS,
  GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL,
  GET_NEWS_CAT_PRESSPASSDATA,
  GET_NEWS_CAT_PRESSPASSDATA_SUCCESS,
  GET_NEWS_CAT_PRESSPASSDATA_FAIL,
  UPDATE_SUBCAT_COUNT,
  UPDATE_LANG_PAGE_TITLE,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS,
  GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL,
  UPDATE_DATE_TEXT_FILTER,
} from "./constants";

export const getFollowSocialData = (data) => ({
  type: GET_NEWS_CAT_FOLLOWSOCIAL_DATA,
  data,
});

export const getFollowSocialSuccess = (data) => ({
  type: GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS,
  data,
});

export const getFollowSocialFail = (data) => ({
  type: GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL,
  data,
});
export const getPopularNewsDetails = (data) => ({
  type: GET_NEWS_CAT_POPULAR_NEWS_DETAILS,
  data,
});
export const getPopularNewsDetailsSuccess = (data) => ({
  type: GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS,
  data,
});

export const getPopularNewsDetailsFail = (data) => ({
  type: GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL,
  data,
});
export const getNewsCatPresspassData = (data) => ({
  type: GET_NEWS_CAT_PRESSPASSDATA,
  data,
});

export const getNewsCatPresspassDataSuccess = (data) => ({
  type: GET_NEWS_CAT_PRESSPASSDATA_SUCCESS,
  data,
});

export const getNewsCatPresspassDataFail = (data) => ({
  type: GET_NEWS_CAT_PRESSPASSDATA_FAIL,
  data,
});
export const getPopularNewsData = (data) => ({
  type: GET_POPULAR_NEWS_SUBCAT_DATA,
  data,
});

export const getPopularNewsDataSuccess = (data) => ({
  type: GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS,
  data,
});

export const getPopularNewsDataFail = (data) => ({
  type: GET_POPULAR_NEWS_SUBCAT_DATA_FAIL,
  data,
});

export const getNewsSubCatData = (data) => ({
  type: GET_NEWS_SUBCAT_DATA,
  data,
});

export const getNewsSubCatDataSuccess = (data) => ({
  type: GET_NEWS_SUBCAT_DATA_SUCCESS,
  data,
});

export const getNewsSubCatDataFail = (data) => ({
  type: GET_NEWS_SUBCAT_DATA_FAIL,
  data,
});

export const getNewsCatData = (data) => ({
  type: GET_NEWS_CAT_DATA,
  data,
});

export const getNewsCatDataSuccess = (data) => ({
  type: GET_NEWS_CAT_DATA_SUCCESS,
  data,
});

export const getNewsCatDataFail = (data) => ({
  type: GET_NEWS_CAT_DATA_FAIL,
  data,
});

export const getNewsCatMediaBanner = (data) => ({
  type: GET_NEWS_CAT_MEDIA_BANNER,
  data,
});

export const getNewsCatMediaBannerSuccess = (data) => ({
  type: GET_NEWS_CAT_MEDIA_BANNER_SUCCESS,
  data,
});

export const getNewsCatMediaBannerFail = (data) => ({
  type: GET_NEWS_CAT_MEDIA_BANNER_FAIL,
  data,
});

export const getNewsCatSignUpNewsLetter = (data) => ({
  type: GET_NEWS_CAT_SIGN_UP_NEWS_LETTER,
  data,
});

export const getNewsCatSignUpNewsLetterSuccess = (data) => ({
  type: GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS,
  data,
});

export const getNewsCatSignUpNewsLetterFail = (data) => ({
  type: GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL,
  data,
});

export const getNewsCatPageData = (data) => ({
  type: GET_NEWS_CAT_PAGE_DATA,
  data,
});

export const getNewsCatPageDataSuccess = (data) => ({
  type: GET_NEWS_CAT_PAGE_DATA_SUCCESS,
  data,
});

export const getNewsCatPageDataFail = (data) => ({
  type: GET_NEWS_CAT_PAGE_DATA_FAIL,
  data,
});

export const getNewsMMLangData = (data) => ({
  type: GET_NEWS_MM_LANG_DATA,
  data,
});

export const getNewsMMLangDataSuccess = (data) => ({
  type: GET_NEWS_MM_LANG_DATA_SUCCESS,
  data,
});

export const getNewsMMLangDataFail = (data) => ({
  type: GET_NEWS_MM_LANG_DATA_FAIL,
  data,
});

export const updateCatPageNo = (data) => ({
  type: UPDATE_CAT_PAGE_NO,
  data,
});

export const updateSubCatPageNo = (data) => ({
  type: UPDATE_SUBCAT_PAGE_NO,
  data,
});

export const updateSubCatCount = (data) => ({
  type: UPDATE_SUBCAT_COUNT,
  data,
});

export const updateLangPageTitle = (data) => ({
  type: UPDATE_LANG_PAGE_TITLE,
  data,
});

export const updateDateTextFilter = (data) => ({
  type: UPDATE_DATE_TEXT_FILTER,
  data,
});
