/**
 * @author Pramod Turakane
 * @company Sai Systems
 * Header Page
 *
 * This is the header page consist of menu's for navigating to different pages
 *
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Input, Col, Row, Button, Select, Modal } from "antd";
import BWLogo from "../../images/BW_Logo.svg";
import Hamburger from "../../images/header/hamburger_menu.svg";
import Bar from "../../images/header/bar.svg";
import BarClose from "../../images/header/bar_close.svg";
import Search from "../../images/header/search.svg";
import Search1 from "../../images/header/search1.svg";
// import { useScrollDirection } from "../../utils/scroll";
//import Header from "../header";
import "./pageheader.scss";
import { generateLink } from "../../utils/helper";
import PageHeaderComponentWrapper from "./pageheader";
import { getMenuTitle, renderMobileMenu } from "./helper";
import ModalSearchComponent from "../modalsearch";
import { useSelector } from "react-redux";

const { SubMenu } = Menu;

const PageHeaderComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  //const scrollDirection = useScrollDirection();
  const languages = useSelector((state) => state.appReducer.languages);

  useEffect(() => {
    if (props.menuData.length) {
      const extractedKeys = props.menuData.map((item) => item.id.toString());
      extractedKeys.push("Language");
      setRootSubmenuKeys(extractedKeys);
    }
  }, [props.menuData]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    const selectedMenu = [keys[0], keys[keys.length - 1]];

    if (!rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(selectedMenu);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = (e) => {
    props.handleActiveLinks(e);
  };

  const handleChange = (evt) => {
    props.handleChange(evt);
  };

  const onLogoClick = () => {
    props.handleActiveLinks();
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mobileSearchBox = () => {
    setIsSearchModalOpen(true);
  };

  const renderMenu = (menuData) => {
    return menuData.map((menu) => {
      return (
        <SubMenu
          key={menu.id}
          title={getMenuTitle(menu.title, menu.url, menu.target, menu.active)}
          onTitleClick={onClick}
        >
          <Row className="submenu-row">
            {menu.children.map((submenu) => {
              return (
                <Col flex={"20%"} key={submenu.id}>
                  {generateLink(
                    submenu.title,
                    submenu.url,
                    submenu.target,
                    true,
                    submenu.active
                  )}
                  {submenu.description ? (
                    <Menu.Item
                      key={submenu.id}
                      label={submenu.description}
                      title={submenu.description}
                    >
                      <div className="menu-desc">
                        {generateLink(
                          submenu.description,
                          submenu.url,
                          submenu.target,
                          false,
                          submenu.active
                        )}
                      </div>
                    </Menu.Item>
                  ) : (
                    submenu.children.map((childmenu) => {
                      return (
                        <Menu.Item
                          key={childmenu.id}
                          label={childmenu.title}
                          title={childmenu.title}
                        >
                          {generateLink(
                            childmenu.title,
                            childmenu.url,
                            childmenu.target,
                            false,
                            childmenu.active
                          )}
                        </Menu.Item>
                      );
                    })
                  )}
                </Col>
              );
            })}
          </Row>
        </SubMenu>
      );
    });
  };

  const onShowModal = () => {
    setShowModal(!showModal);
    setOpenKeys([]);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onMobileClick = (e) => {
    props.handleActiveLinks(e);
    setShowModal(false);
  };

  const onAfterModalClose = (e) => {
    props.handleActiveLinks("");
  };

  const handleModalOpen = () => {
    setIsSearchModalOpen(true);
  };

  return (
    <PageHeaderComponentWrapper>
      <div className="header-menu bw-header" tabIndex="0">
        <Row justify="center" className="header-row">
          {/* {scrollDirection === "up" ||
          scrollDirection === null ||
          !props?.HeaderData?._header_status ? ( */}
          <>
            <Col
              className="left-align pointer"
              sm={{ span: 20, order: 2 }}
              md={{ span: 10, order: 2 }}
              lg={{ span: 6, order: 2 }}
              xl={{ span: 5, order: 2 }}
              tabIndex="0"
            >
              <img
                onClick={onLogoClick}
                className="bw-logo"
                src={
                  props.globalSettings && props.globalSettings.headerLogo
                    ? props.globalSettings.headerLogo
                    : BWLogo
                }
                alt={props.globalSettings.headerLogoAltText}
              />
            </Col>
            <Col
              sm={{ span: 2, order: 8 }}
              md={{ span: 14, order: 3 }}
              lg={{ span: 18, order: 4 }}
              xl={{ span: 19, order: 4 }}
              className="right-sect"
            >
              <Row justify={"end"}>
                <Col className="menu-sect">
                  <div tabIndex="0">
                    <Row>
                      <Col span={24}>
                        <Menu
                          onClick={onClick}
                          className="menu-list header-arrow"
                          selectedKeys={[props.current]}
                          mode="horizontal"
                          selectable={true}
                          triggerSubMenuAction={"hover"}
                          overflowedIndicator={
                            <img src={Hamburger} alt={"hamburger"} />
                          }
                          breakpoint={"lg"}
                        >
                          {props.menuData ? renderMenu(props.menuData) : <></>}
                        </Menu>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={
                      showModal ? "hamburger-icon bar-close" : "hamburger-icon"
                    }
                    onClick={onShowModal}
                  >
                    {showModal ? (
                      <img src={BarClose} alt="" />
                    ) : (
                      <img src={Bar} alt="" />
                    )}
                  </div>
                </Col>
                <Col className="hide-element search-box">
                  {!showModal ? (
                    <>
                      <span onClick={() => handleModalOpen()}>
                        <Input
                          tabIndex="0"
                          className="header-search"
                          placeholder="Search"
                          aria-label="search"
                          prefix={
                            <img
                              src={Search1}
                              height={16}
                              width={16}
                              alt={"serach icon"}
                              className="header-search-icon"
                            />
                          }
                        />
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>

                {languages && languages.length ? (
                  <Col className="hide-element hide-lang-element">
                    <Select
                      className="header-select"
                      value={props.lang}
                      onChange={(e) => handleChange(e)}
                      options={languages}
                      aria-label="languages"
                      popupClassName="header-select-popup"
                      bordered={false}
                      tabIndex="0"
                    />
                  </Col>
                ) : (
                  <></>
                )}

                <Col className="hide-element btn-sect">
                  {!showModal ? (
                    <>
                      {props?.loginBtnData && props?.loginBtnData.length ? (
                        <>
                          {props?.loginBtnData[1] &&
                          props?.loginBtnData[1]?.title ? (
                            <>
                              {" "}
                              <a
                                style={{ height: "100%" }}
                                href={props.loginBtnData[1].url}
                                target={props.loginBtnData[1].target}
                                aria-label={props.loginBtnData[1].title}
                                rel="noreferrer"
                              >
                                <Button
                                  className="header-btn1"
                                  type="text"
                                  aria-hidden="true"
                                >
                                  {props.loginBtnData[1].title}
                                </Button>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                          <hr className="hr-line" />
                          {props?.loginBtnData[0] &&
                          props?.loginBtnData[0]?.title ? (
                            <>
                              <a
                                style={{ height: "100%" }}
                                href={props.loginBtnData[0].url}
                                target={props.loginBtnData[0].target}
                                rel="noreferrer"
                                aria-label={props.loginBtnData[0].title}
                              >
                                <Button
                                  className="header-btn"
                                  type="text"
                                  aria-hidden="true"
                                >
                                  {props.loginBtnData[0].title}
                                </Button>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col className="show-search-div" sm={{ span: 2, order: 7 }}>
              {!showModal ? (
                <div
                  className="show-search"
                  aria-label="search"
                  onClick={() => handleModalOpen()}
                >
                  {" "}
                  <img src={Search} alt="search icon" />
                </div>
              ) : (
                <></>
              )}
            </Col>
          </>
          {/* ) : ( */}
          {/*<>
              <Col span={24} tabIndex="0">
                {props.HeaderData.header_cta_label ? (
                  <Header
                    btnName={props.HeaderData.header_cta_label}
                    btnUrl={props.HeaderData.header_cta_url}
                  />
                ) : (
                  <></>
                )}
              </Col>
                </> */}
          {/* )} */}
          {showModal && (
            <Modal
              closable={false}
              open={showModal}
              onCancel={handleCancel}
              footer={null}
              width="100vw"
              className="mega-menu-modal"
              style={{ top: 84, right: 0 }}
              tabIndex="0"
            >
              <PageHeaderComponentWrapper>
                <Menu
                  onClick={(e) => onMobileClick(e)}
                  className="menu-list"
                  mode="inline"
                  triggerSubMenuAction={"hover"}
                  tabIndex="0"
                  afterClose={() => onAfterModalClose()}
                  destroyOnClose={true}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                >
                  {props.menuData ? (
                    renderMobileMenu(
                      props.menuData,
                      props.loginBtnData,
                      mobileSearchBox,
                      languages,
                      props.lang,
                      handleChange
                    )
                  ) : (
                    <></>
                  )}
                </Menu>
              </PageHeaderComponentWrapper>
            </Modal>
          )}
        </Row>
        <>
          {isSearchModalOpen && (
            <ModalSearchComponent
              isSearchModalOpen={isSearchModalOpen}
              setIsSearchModalOpen={setIsSearchModalOpen}
            />
          )}
        </>
      </div>
    </PageHeaderComponentWrapper>
  );
};

export default PageHeaderComponent;
