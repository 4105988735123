import {
  GET_CAREERS_DATA,
  GET_CAREERS_DATA_SUCCESS,
  GET_CAREERS_DATA_FAIL,
  GET_CAREERS_CONTACTUS_DATA,
  GET_CAREERS_CONTACTUS_DATA_SUCCESS,
  GET_CAREERS_CONTACTUS_DATA_FAIL,
} from "./constants";

export const getCareersData = () => ({
  type: GET_CAREERS_DATA,
});

export const getCareersDataSuccess = (data) => ({
  type: GET_CAREERS_DATA_SUCCESS,
  data,
});

export const getCareersDataFail = (data) => ({
  type: GET_CAREERS_DATA_FAIL,
  data,
});

export const getCareersContactUsData = () => ({
  type: GET_CAREERS_CONTACTUS_DATA,
});

export const getCareersContactUsDataSuccess = (data) => ({
  type: GET_CAREERS_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getCareersContactUsDataFail = (data) => ({
  type: GET_CAREERS_CONTACTUS_DATA_FAIL,
  data,
});
