import { takeEvery, put } from "redux-saga/effects";
import {
  GET_OFFICE_LOCATIONS,
  GET_CONTACTUSOFF_DATA,
  GET_OFFICE_PAGE_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getOfficeLocationsSuccess,
  getOfficeLocationsFail,
  getContactusOffSuccess,
  getContactusOffFail,
  getOfficePageDataSuccess,
  getOfficePageDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getOfficeLocations() {
  let data = yield get("/bwcms/page?name=office-locations")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const officelocationData = yield data.data;
    yield put(getOfficeLocationsSuccess(officelocationData));
  } else {
    yield put(getOfficeLocationsFail(data));
  }
}

function* getContactUsOff() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactDataoff = yield data.data;
    yield put(getContactusOffSuccess(contactDataoff));
  } else {
    yield put(getContactusOffFail(data));
  }
}

function* getOfficePageData(pageName) {
  let data = yield get("/bwcms/page/" + pageName.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getOfficePageDataSuccess(pageData));
  } else {
    yield put(getOfficePageDataFail(data));
  }
}

function* officelocationSaga() {
  yield takeEvery(GET_OFFICE_LOCATIONS, getOfficeLocations);
  yield takeEvery(GET_CONTACTUSOFF_DATA, getContactUsOff);
  yield takeEvery(GET_OFFICE_PAGE_DATA, getOfficePageData);
}

export default officelocationSaga;
