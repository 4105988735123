import { takeEvery, put } from "redux-saga/effects";
import {
  GET_MENU_DATA,
  GET_GLOBAL_SETTINGS,
  GET_LFOOTER_DATA,
  GET_MFOOTER_DATA,
  GET_BNAVIGATION_DATA,
  GET_LANGUAGE_DATA,
  GET_BLOG_DATA,
  GET_MODAL_NEWS_SEARCH_DATA,
  GET_LOGIN_BTN_DATA,
} from "./constants";
import { get, getBW } from "../../utils/api";
import { formatSearchNewsData } from "../../utils/helper";
import {
  getMenuDataSuccess,
  getMenuDataFail,
  getlfooterDataSuccess,
  getlfooterDataFail,
  getMfooterDataSuccess,
  getMfooterDataFail,
  getBnavigationDataSuccess,
  getBnavigationDataFail,
  getLanguageDataSuccess,
  getLanguageDataFail,
  getGlobalSettingsSuccess,
  getGlobalSettingsFail,
  getBlogDataFail,
  getBlogDataSuccess,
  getLoginBtnDataSuccess,
  getLoginBtnDataFail,
  getModalNewsSearchDataSuccess,
  getModalNewsSearchDataFail,
  updateModalSearchNewsCnt,
} from "./actions";

function* getMenus() {
  let data = yield get("/bwcms/menu?name=main-header-navigation")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsData = yield data.data;
    yield put(getMenuDataSuccess(newsData));
  } else {
    yield put(getMenuDataFail(data));
  }
}

function* getGlobalSettings() {
  let data = yield get("/bwcms/globalsettings")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const globalSettings = yield data.data;
    yield put(getGlobalSettingsSuccess(globalSettings));
  } else {
    yield put(getGlobalSettingsFail(data));
  }
}

function* getLfooterData() {
  let data = yield get("/bwcms/globalsettings")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const lfooterData = yield data.data;
    yield put(getlfooterDataSuccess(lfooterData));
  } else {
    yield put(getlfooterDataFail(data));
  }
}

function* getMfooterData() {
  let data = yield get("/bwcms/menu?name=footer-navigation")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const mfooterData = yield data.data;
    yield put(getMfooterDataSuccess(mfooterData));
  } else {
    yield put(getMfooterDataFail(data));
  }
}
function* getBnavigationData() {
  let data = yield get("/bwcms/menu?name=footer-bottom-navigation")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const BnavigationData = yield data.data;
    yield put(getBnavigationDataSuccess(BnavigationData));
  } else {
    yield put(getBnavigationDataFail(data));
  }
}
function* getLanguageData() {
  let data = yield get("bwcms/menu?name=footer-language-navigation")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const languageData = yield data.data;
    yield put(getLanguageDataSuccess(languageData));
  } else {
    yield put(getLanguageDataFail(data));
  }
}
function* getblogData() {
  let data = yield get("/bwcms/menu?name=footer-blog-menu")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const blogData = yield data.data;
    yield put(getBlogDataSuccess(blogData));
  } else {
    yield put(getBlogDataFail(data));
  }
}
function* getLoginBtnData() {
  let data = yield get("/bwcms/menu?name=login-menu")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const loginbtnData = yield data.data;
    yield put(getLoginBtnDataSuccess(loginbtnData));
  } else {
    yield put(getLoginBtnDataFail(data));
  }
}

function* getModalnewsSearch(params) {
  // by default path
  let url = "/news/search?tickers=AAPL";
  if (params?.data?.keywords) {
    url = "/news/search?keywords=" + params.data.keywords;
  } else if (params?.data?.entireRelease !== undefined) {
    if (
      params.data.exclude &&
      params.data.exactPhrase &&
      params.data.entireRelease
    ) {
      url =
        "/news/search?entireRelease=" +
        params.data.entireRelease +
        "&exclude=" +
        params.data.exclude +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exclude && params.data.entireRelease) {
      url =
        "/news/search?entireRelease=" +
        params.data.entireRelease +
        "&exclude=" +
        params.data.exclude +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exactPhrase && params.data.entireRelease) {
      url =
        "/news/search?entireRelease=" +
        params.data.entireRelease +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exactPhrase && params.data.exclude) {
      url =
        "/news/search?exclude=" +
        params.data.exclude +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exactPhrase) {
      url =
        "/news/search?exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exclude) {
      url =
        "/news/search?exclude=" +
        params.data.exclude +
        "&from=" +
        params.data.from +
        "&size=9";
    } else {
      url =
        "/news/search?entireRelease=" +
        params.data.entireRelease +
        "&from=" +
        params.data.from +
        "&size=9";
    }
  } else if (params?.data?.headline !== undefined) {
    if (
      params.data.exclude &&
      params.data.exactPhrase &&
      params.data.headline
    ) {
      url =
        "/news/search?headline=" +
        params.data.headline +
        "&exclude=" +
        params.data.exclude +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exclude && params.data.headline) {
      url =
        "/news/search?headline=" +
        params.data.headline +
        "&exclude=" +
        params.data.exclude +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exactPhrase && params.data.headline) {
      url =
        "/news/search?headline=" +
        params.data.headline +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exclude && params.data.exactPhrase) {
      url =
        "/news/search?exclude=" +
        params.data.exclude +
        "&exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exclude) {
      url =
        "/news/search?exclude=" +
        params.data.exclude +
        "&from=" +
        params.data.from +
        "&size=9";
    } else if (params.data.exactPhrase) {
      url =
        "/news/search?exactPhrase=" +
        params.data.exactPhrase +
        "&from=" +
        params.data.from +
        "&size=9";
    } else {
      url =
        "/news/search?headline=" +
        params.data.headline +
        "&from=" +
        params.data.from +
        "&size=9";
    }
  } else if (params?.data?.companyName) {
    url =
      "/news/search?companyName=" +
      params.data.companyName +
      "&from=" +
      params.data.from +
      "&size=9";
  } else if (params?.data?.ticker) {
    url =
      "/news/search?tickers=" +
      params.data.ticker +
      "&from=" +
      params.data.from +
      "&size=9";
  } else if (params?.data?.isins) {
    url =
      "/news/search?isins=" +
      params.data.isins +
      "&from=" +
      params.data.from +
      "&size=9";
  } else if (params?.data?.hashtags) {
    url =
      "/news/search?hashtags=" +
      params.data.hashtags +
      "&from=" +
      params.data.from +
      "&size=9";
  } else if (params?.data?.cashtags) {
    url =
      "/news/search?cashtags=" +
      params.data.cashtags +
      "&from=" +
      params.data.from +
      "&size=9";
  }

  let data = yield getBW(url)
    .then((result) => {
      return result;
    })

    .catch(() => console.error());
  if (data && data.status === 200) {
    let searchNews = yield data?.data?.data?.hits;
    let updatedData = searchNews.map((data) => {
      return formatSearchNewsData(data._source);
    });

    yield put(getModalNewsSearchDataSuccess(updatedData));
    yield put(updateModalSearchNewsCnt(data?.data?.data?.total?.value));
  } else {
    yield put(getModalNewsSearchDataFail(data));
  }
}

function* appSaga() {
  yield takeEvery(GET_MENU_DATA, getMenus);
  yield takeEvery(GET_GLOBAL_SETTINGS, getGlobalSettings);
  yield takeEvery(GET_LFOOTER_DATA, getLfooterData);
  yield takeEvery(GET_MFOOTER_DATA, getMfooterData);
  yield takeEvery(GET_BNAVIGATION_DATA, getBnavigationData);
  yield takeEvery(GET_LANGUAGE_DATA, getLanguageData);
  yield takeEvery(GET_BLOG_DATA, getblogData);
  yield takeEvery(GET_MODAL_NEWS_SEARCH_DATA, getModalnewsSearch);
  yield takeEvery(GET_LOGIN_BTN_DATA, getLoginBtnData);
}
export default appSaga;
