export const GET_NEWS_DETAILS_DATA = "GET_NEWS_DETAILS_DATA";
export const GET_NEWS_DETAILS_DATA_SUCCESS = "GET_NEWS_DETAILS_DATA_SUCCESS";
export const GET_NEWS_DETAILS_DATA_FAIL = "GET_NEWS_DETAILS_DATA_FAIL";
export const GET_RELEASE_VERSION = "GET_RELEASE_VERSION";
export const GET_RELEASE_VERSION_SUCCESS = "GET_RELEASE_VERSION_SUCCESS";
export const GET_RELEASE_VERSION_FAIL = "GET_RELEASE_VERSION_FAIL";
export const GET_ENEVELOPE_DATA = "GET_ENEVELOPE_DATA";
export const GET_ENEVELOPE_DATA_SUCCESS = "GET_ENEVELOPE_DATA_SUCCESS";
export const GET_ENEVELOPE_DATA_FAIL = "GET_ENEVELOPE_DATA_FAIL";
export const GET_MORE_NEWS_DATA = "GET_MORE_NEWS_DATA";
export const GET_MORE_NEWS_DATA_SUCCESS = "GET_MORE_NEWS_DATA_SUCCESS";
export const GET_MORE_NEWS_DATA_FAIL = "GET_MORE_NEWS_DATA_FAIL";
export const GET_NEWS_NEWSLETTER_BANNER_DATA =
  "GET_NEWS_NEWSLETTER_BANNER_DATA";
export const GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS =
  "GET_NEWS_NEWSLETTER_BANNER_DATA_SUCCESS";
export const GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL =
  "GET_NEWS_NEWSLETTER_BANNER_DATA_FAIL";
export const GET_MEDIA_BANNER = "GET_MEDIA_BANNER";
export const GET_MEDIA_BANNER_SUCCESS = "GET_MEDIA_BANNER_SUCCESS";
export const GET_MEDIA_BANNER_FAIL = "GET_MEDIA_BANNER_FAIL";
export const GET_NEWS_DETAILS_RECOMMENDED = "GET_NEWS_DETAILS_RECOMMENDED";
export const GET_NEWS_DETAILS_RECOMMENDED_SUCCESS =
  "GET_NEWS_DETAILS_RECOMMENDED_SUCCESS";
export const GET_NEWS_DETAILS_RECOMMENDED_FAIL =
  "GET_NEWS_DETAILS_RECOMMENDED_FAIL";
export const GET_NEWS_DETAILS_PAGE_DATA = "GET_NEWS_DETAILS_PAGE_DATA";
export const GET_NEWS_DETAILS_PAGE_DATA_SUCCESS =
  "GET_NEWS_DETAILS_PAGE_DATA_SUCCESS";
export const GET_NEWS_DETAILS_PAGE_DATA_FAIL =
  "GET_NEWS_DETAILS_PAGE_DATA_FAIL";
