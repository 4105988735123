import { takeEvery, put } from "redux-saga/effects";
import {
  GET_TERM_OF_USE_CONTACTUS_DATA,
  GET_TERM_OF_USE_PAGE_DATA,
} from "./constants";
import { get } from "../../utils/api";
import {
  getTermOfUseContactusSuccess,
  getTermOfUseContactusFail,
  getTermOfUsePageDataSuccess,
  getTermOfUsePageDataFail,
} from "./actions";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getTermOfUseContactus() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const contactData = yield data.data;
    yield put(getTermOfUseContactusSuccess(contactData));
  } else {
    yield put(getTermOfUseContactusFail(data));
  }
}

function* getTermOfUsePageData(pageName) {
  let data = yield get("bwcms/page/terms-of-use")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;

    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
    yield put(getTermOfUsePageDataSuccess(pageData));
  } else {
    yield put(getTermOfUsePageDataFail(data));
  }
}

function* termofuseSaga() {
  yield takeEvery(GET_TERM_OF_USE_CONTACTUS_DATA, getTermOfUseContactus);
  yield takeEvery(GET_TERM_OF_USE_PAGE_DATA, getTermOfUsePageData);
}

export default termofuseSaga;
