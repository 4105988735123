import produce from "immer";

import {
  GET_MENU_DATA,
  GET_MENU_DATA_SUCCESS,
  GET_MENU_DATA_FAIL,
  GET_LFOOTER_DATA,
  GET_LFOOTER_DATA_SUCCESS,
  GET_LFOOTER_DATA_FAIL,
  GET_MFOOTER_DATA,
  GET_MFOOTER_DATA_SUCCESS,
  GET_MFOOTER_DATA_FAIL,
  GET_BNAVIGATION_DATA,
  GET_BNAVIGATION_DATA_SUCCESS,
  GET_BNAVIGATION_DATA_FAIL,
  GET_LANGUAGE_DATA,
  GET_LANGUAGE_DATA_SUCCESS,
  GET_LANGUAGE_DATA_FAIL,
  GET_GLOBAL_SETTINGS,
  GET_GLOBAL_SETTINGS_SUCCESS,
  GET_GLOBAL_SETTINGS_FAIL,
  GET_BLOG_DATA,
  GET_BLOG_DATA_SUCCESS,
  GET_BLOG_DATA_FAIL,
  UPDATE_LANGUAGE_DATA,
  GET_MODAL_NEWS_SEARCH_DATA,
  GET_MODAL_NEWS_SEARCH_DATA_SUCCESS,
  GET_MODAL_NEWS_SEARCH_DATA_FAIL,
  CLEAR_MODAL_NEWS_SEARCH_DATA,
  GET_LOGIN_BTN_DATA,
  GET_LOGIN_BTN_DATA_SUCCESS,
  GET_LOGIN_BTN_DATA_FAIL,
  UPDATE_FOOTER_LANGUAGE_DATA,
  UPDATE_MODAL_SEARCH_NEWS_CNT,
} from "./constants";

const initialState = {
  loading: false,
  menuData: [],
  lfooterData: [],
  mfooterData: [],
  bnavigationData: [],
  languageData: [],
  globalSettings: [],
  blogData: [],
  languages: [],
  modalSerachNewsData: "",
  modalNewsCount: 0,
  modalLoader: false,
  loginBtnData: [],
  //temp code for service page footer links
  tempFooterLangData: [],
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_MENU_DATA:
        draft.loading = true;
        break;
      case GET_MENU_DATA_SUCCESS:
        draft.loading = false;
        draft.menuData = action.data;
        break;
      case GET_MENU_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_LFOOTER_DATA:
        draft.loading = true;
        break;
      case GET_LFOOTER_DATA_SUCCESS:
        draft.loading = false;
        draft.lfooterData = action.data;
        break;
      case GET_LFOOTER_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_MFOOTER_DATA:
        draft.loading = true;
        break;
      case GET_MFOOTER_DATA_SUCCESS:
        draft.loading = false;
        draft.mfooterData = action.data;
        //temp code for service page footer links
        if (action?.data[4]) {
          draft.tempFooterLangData = action.data[4];
        }
        break;
      case GET_MFOOTER_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_BNAVIGATION_DATA:
        draft.loading = true;
        break;
      case GET_BNAVIGATION_DATA_SUCCESS:
        draft.loading = false;
        draft.bnavigationData = action.data;
        break;
      case GET_BNAVIGATION_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_LANGUAGE_DATA:
        draft.loading = true;
        break;
      case GET_LANGUAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.languageData = action.data;
        break;
      case GET_LANGUAGE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_BLOG_DATA:
        draft.loading = true;
        break;
      case GET_BLOG_DATA_SUCCESS:
        draft.loading = false;
        break;
      case GET_BLOG_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_GLOBAL_SETTINGS:
        draft.loading = true;
        break;
      case GET_GLOBAL_SETTINGS_SUCCESS:
        draft.loading = false;
        draft.globalSettings = action.data;
        break;
      case GET_GLOBAL_SETTINGS_FAIL:
        draft.loading = false;
        break;
      case UPDATE_LANGUAGE_DATA:
        if (localStorage.getItem("lang")) {
          let index = action.data.findIndex(
            (data) => data.value === localStorage.getItem("lang")
          );
          if (index !== -1) {
            //do nothing
          } else {
            if (
              localStorage.getItem("lang") === "en" &&
              action.data.length === 0
            ) {
              //do nothing
            } else {
              localStorage.setItem("lang", "en");
              window.location.reload();
            }
          }
          draft.languages = action.data;
        } else {
          draft.languages = action.data;
        }
        break;
      case GET_MODAL_NEWS_SEARCH_DATA:
        draft.modalLoader = true;
        break;
      case GET_MODAL_NEWS_SEARCH_DATA_SUCCESS:
        draft.modalLoader = false;
        draft.modalSerachNewsData = action.data;
        break;
      case GET_MODAL_NEWS_SEARCH_DATA_FAIL:
        draft.modalLoader = false;
        break;
      case CLEAR_MODAL_NEWS_SEARCH_DATA:
        draft.modalSerachNewsData = "";
        draft.modalNewsCount = 0;
        draft.modalLoader = false;
        break;
      case GET_LOGIN_BTN_DATA:
        draft.loading = true;
        break;
      case GET_LOGIN_BTN_DATA_SUCCESS:
        draft.loading = false;
        draft.loginBtnData = action.data;
        break;
      case GET_LOGIN_BTN_DATA_FAIL:
        draft.loading = false;
        break;
      case UPDATE_FOOTER_LANGUAGE_DATA:
        if (draft.mfooterData) {
          let updatedFooterdata = draft.mfooterData.map((data, index) => {
            return index === 4
              ? action.data
                ? action.data
                : draft.tempFooterLangData
              : data;
          });

          draft.mfooterData = updatedFooterdata;
        }
        break;

      case UPDATE_MODAL_SEARCH_NEWS_CNT:
        draft.modalNewsCount = action.data;
        break;
      default:
        return draft;
    }
  });

export default appReducer;
