import produce from "immer";

import {
  GET_SETAIL_PAGE_DATA,
  GET_SETAIL_PAGE_DATA_SUCCESS,
  GET_SETAIL_PAGE_DATA_FAIL,
  GET_SETAIL_CONTACTUS_DATA,
  GET_SETAIL_CONTACTUS_DATA_SUCCESS,
  GET_SETAIL_CONTACTUS_DATA_FAIL,
  GET_SETAIL_SIGN_UP_DATA,
  GET_SETAIL_SIGN_UP_DATA_SUCCESS,
  GET_SETAIL_SIGN_UP_DATA_FAIL,
  GET_SETAIL_DEMO_BANNER_DATA,
  GET_SETAIL_DEMO_BANNER_DATA_SUCCESS,
  GET_SETAIL_DEMO_BANNER_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  setailPageData: [],
  contactUsData: [],
  demoBannerData: [],
  signUpData: [],
};

const setailReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SETAIL_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_SETAIL_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.setailPageData = action.data;
        if (
          action?.data?.recommendedBanner &&
          action?.data?.recommendedBanner?.columns
        ) {
          const recommendedBanners = action.data.recommendedBanner;
          const columnsData = recommendedBanners.columns;

          // Check if the columnsData array has data
          if (columnsData && columnsData.length > 0) {
            // Filter the recommended banners to keep only the ones with both title and description
            const validBanners = columnsData.filter(
              (banner) => banner.title && banner.description
            );
            // Check if the number of valid recommended banners is more than 2 (3 or more)

            if (validBanners.length > 2) {
              // If there are 3 or more valid banners, keep only the first 3 in the state
              draft.setailPageData.recommendedBanner.columns =
                validBanners.slice(0, 3);
            } else {
              // If there are less than 3 valid banners or the array is empty, set recommendData to an empty array
              draft.setailPageData.recommendedBanner.columns = [];
            }
          }
        }
        break;
      case GET_SETAIL_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_SETAIL_CONTACTUS_DATA:
        draft.loading = true;
        break;
      case GET_SETAIL_CONTACTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.contactUsData = action.data;
        break;
      case GET_SETAIL_CONTACTUS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_SETAIL_SIGN_UP_DATA:
        draft.loading = true;
        break;
      case GET_SETAIL_SIGN_UP_DATA_SUCCESS:
        draft.loading = false;
        draft.signUpData = action.data;
        break;
      case GET_SETAIL_SIGN_UP_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_SETAIL_DEMO_BANNER_DATA:
        draft.loading = true;
        break;
      case GET_SETAIL_DEMO_BANNER_DATA_SUCCESS:
        draft.loading = false;
        draft.demoBannerData = action.data;
        break;
      case GET_SETAIL_DEMO_BANNER_DATA_FAIL:
        draft.loading = false;
        break;

      default:
        return draft;
    }
  });

export default setailReducer;
