export const GET_VIDEO_DATA = "GET_VIDEO_DATA";
export const GET_VIDEO_DATA_SUCCESS = "GET_VIDEO_DATA_SUCCESS";
export const GET_VIDEO_DATA_FAIL = "GETVIDEO_DATA_FAIL";
export const GET_WHOWEARE_DATA = "GET_WHOWEARE_DATA";
export const GET_WHOWEARE_DATA_SUCCESS = "GET_WHOWEARE_DATA_SUCCESS";
export const GET_WHOWEARE_DATA_FAIL = "GET_WHOWEARE_DATA_FAIL";
export const GET_TRENDING_NEWS = "GET_TRENDING_NEWS";
export const GET_TRENDING_NEWS_SUCCESS = "GET_TRENDING_NEWS_SUCCESS";
export const GET_TRENDING_NEWS_FAIL = "GET_TRENDING_NEWS_FAIL";
export const GET_HOME_PAGE_DATA = "GET_HOME_PAGE_DATA";
export const GET_HOME_PAGE_DATA_SUCCESS = "GET_HOME_PAGE_DATA_SUCCESS";
export const GET_HOME_PAGE_DATA_FAIL = "GET_HOME_PAGE_DATA_FAIL";
export const GET_HOME_CONTACTUS_DATA = "GET_HOME_CONTACTUS_DATA";
export const GET_HOME_CONTACTUS_DATA_SUCCESS = "GET_HOME_CONTACTUS_DATA_SUCCESS";
export const GET_HOME_CONTACTUS_DATA_FAIL = "GET_HOME_CONTACTUS_DATA_FAIL";
export const GET_TESTIMONIALS_DATA = "GET_TESTIMONIALS_DATA";
export const GET_TESTIMONIALS_DATA_SUCCESS = "GET_TESTIMONIALS_DATA_SUCCESS";
export const GET_TESTIMONIALS_DATA_FAIL = "GET_TESTIMONIALS_DATA_FAIL";
export const GET_NEWSLETTER_SIGNUP_DATA = "GET_NEWSLETTER_SIGNUP_DATA";
export const GET_NEWSLETTER_SIGNUP_DATA_SUCCESS =
  "GET_NEWSLETTER_SIGNUP_DATA_SUCCESS";
export const GET_NEWSLETTER_SIGNUP_DATA_FAIL =
  "GET_NEWSLETTER_SIGNUP_DATA_FAIL";
export const GET_LATEST_NEWS_DATA = "GET_LATEST_NEWS_DATA";
export const GET_LATEST_NEWS_DATA_SUCCESS = "GET_LATEST_NEWS_DATA_SUCCESS";
export const GET_LATEST_NEWS_DATA_FAIL = "GET_LATEST_NEWS_DATA_FAIL";
export const GET_MULT_NEWS_DATA = "GET_MULT_NEWS_DATA";
export const GET_MULT_NEWS_DATA_SUCCESS = "GET_MULT_NEWS_DATA_SUCCESS";
export const GET_MULT_NEWS_DATA_FAIL = "GET_MULT_NEWS_DATA_FAIL";
