import produce from "immer";

import {
  GET_ALL_NEWS_DATA,
  GET_ALL_NEWS_DATA_SUCCESS,
  GET_ALL_NEWS_DATA_FAIL,
  GET_POPULAR_NEWS_DATA,
  GET_POPULAR_NEWS_DATA_SUCCESS,
  GET_POPULAR_NEWS_DATA_FAIL,
  GET_ALL_NEWS_MEDIA_BANNER,
  GET_ALL_NEWS_MEDIA_BANNER_SUCCESS,
  GET_ALL_NEWS_MEDIA_BANNER_FAIL,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS,
  GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL,
  GET_ALL_NEWS_PAGE_DATA,
  GET_ALL_NEWS_PAGE_DATA_SUCCESS,
  GET_ALL_NEWS_PAGE_DATA_FAIL,
  GET_ALL_NEWS_PRESSPASSDATA,
  GET_ALL_NEWS_PRESSPASSDATA_SUCCESS,
  GET_ALL_NEWS_PRESSPASSDATA_FAIL,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS,
  GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS,
  GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL,
  UPDATE_PAGE_NUMBER,
  UPDATE_TOTAL_NEWS_CNT,
  UPDATE_FILTER_DATE,
} from "./constants";

const initialState = {
  loading: false,
  allNews: [],
  popularNews: [],
  mediaBanner: [],
  signUpNewsLetter: [],
  pageData: [],
  allNewsLoad: false,
  popularNewsLoad: false,
  presspassData: [],
  pageNo: 1,
  newsCount: 0,
  popularNewsDetails: [],
  pillData: [],
};

const allNewsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ALL_NEWS_DATA:
        draft.allNewsLoad = true;
        break;
      case GET_ALL_NEWS_DATA_SUCCESS:
        draft.allNewsLoad = false;
        draft.allNews = action.data;
        break;
      case GET_ALL_NEWS_DATA_FAIL:
        draft.allNewsLoad = false;
        break;
      case GET_POPULAR_NEWS_DATA:
        draft.popularNewsLoad = true;
        break;
      case GET_POPULAR_NEWS_DATA_SUCCESS:
        draft.popularNewsLoad = false;
        draft.popularNews = action.data;
        break;
      case GET_POPULAR_NEWS_DATA_FAIL:
        draft.popularNewsLoad = false;
        break;
      case GET_ALL_NEWS_MEDIA_BANNER:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_MEDIA_BANNER_SUCCESS:
        draft.loading = false;
        draft.mediaBanner = action.data;
        break;
      case GET_ALL_NEWS_MEDIA_BANNER_FAIL:
        draft.loading = false;
        break;
      case GET_ALL_NEWS_SIGN_UP_NEWS_LETTER:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_SUCCESS:
        draft.loading = false;
        draft.signUpNewsLetter = action.data;
        break;
      case GET_ALL_NEWS_SIGN_UP_NEWS_LETTER_FAIL:
        draft.loading = false;
        break;
      case GET_ALL_NEWS_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.pageData = action.data;
        break;
      case GET_ALL_NEWS_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_ALL_NEWS_PRESSPASSDATA:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_PRESSPASSDATA_SUCCESS:
        draft.loading = false;
        draft.presspassData = action.data;
        break;
      case GET_ALL_NEWS_PRESSPASSDATA_FAIL:
        draft.loading = false;
        break;
      case GET_ALL_NEWS_FOLLOWSOCIAL_DATA:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_FOLLOWSOCIAL_DATA_SUCCESS:
        draft.loading = false;
        draft.followSocialData = action.data;
        break;
      case GET_ALL_NEWS_FOLLOWSOCIAL_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_ALL_NEWS_POPULAR_NEWS_DETAILS:
        draft.loading = true;
        break;
      case GET_ALL_NEWS_POPULAR_NEWS_DETAILS_SUCCESS:
        draft.loading = false;
        draft.popularNewsDetails = action.data;
        break;
      case GET_ALL_NEWS_POPULAR_NEWS_DETAILS_FAIL:
        draft.loading = false;
        break;
      case UPDATE_PAGE_NUMBER:
        draft.pageNo = action.data;
        break;
      case UPDATE_TOTAL_NEWS_CNT:
        draft.newsCount = action.data;
        break;
      case UPDATE_FILTER_DATE:
        draft.pillData = action.data;
        break;
      default:
        return draft;
    }
  });
export default allNewsReducer;
