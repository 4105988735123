import {
  GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA,
  GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_SUCCESS,
  GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_FAIL,
  GET_COPYRIGHT_INFORMATION_PAGE_DATA,
  GET_COPYRIGHT_INFORMATION_PAGE_DATA_SUCCESS,
  GET_COPYRIGHT_INFORMATION_PAGE_DATA_FAIL,
} from "./constants";

export const getCopyrightInoformationContactus = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA,
  data,
});

export const getCopyrightInoformationContactusSuccess = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getCopyrightInoformationContactusFail = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_CONTACTUS_DATA_FAIL,
  data,
});

export const getCopyrightInoformationPageData = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_PAGE_DATA,
  data,
});

export const getCopyrightInoformationPageDataSuccess = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_PAGE_DATA_SUCCESS,
  data,
});

export const getCopyrightInoformationPageDataFail = (data) => ({
  type: GET_COPYRIGHT_INFORMATION_PAGE_DATA_FAIL,
  data,
});
