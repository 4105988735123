import {
  GET_MEDIA_PARTNERSHIP,
  GET_MEDIA_PARTNERSHIP_SUCCESS,
  GET_MEDIA_PARTNERSHIP_FAIL,
  GET_MEDIA_CONTACT_US,
  GET_MEDIA_CONTACT_US_SUCCESS,
  GET_MEDIA_CONTACT_US_FAIL,
} from "./constants";

export const getMediaPartnership = (data) => ({
  type: GET_MEDIA_PARTNERSHIP,
  data,
});

export const getMediaPartnershipSuccess = (data) => ({
  type: GET_MEDIA_PARTNERSHIP_SUCCESS,
  data,
});

export const getMediaPartnershipFail = (data) => ({
  type: GET_MEDIA_PARTNERSHIP_FAIL,
  data,
});

export const getMediaContactUs = (data) => ({
  type: GET_MEDIA_CONTACT_US,
  data,
});

export const getMediaContactUsSuccess = (data) => ({
  type: GET_MEDIA_CONTACT_US_SUCCESS,
  data,
});

export const getMediaContactUsFail = (data) => ({
  type: GET_MEDIA_CONTACT_US_FAIL,
  data,
});
