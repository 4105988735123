import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const LoaderWrapper = styled.div`
  margin: auto;
  padding: 48px 0px;
  .loader-cont .loading-text {
    color: ${colors["@text-body"]};
  }
  .loader-cont .loader{
    border: 6px solid ${colors["@section-bg-lighter"]};
    border-top: 6px solid ${colors["@section-bg-medium"]};
  }
`;

export default LoaderWrapper;
