import {
  GET_SETAIL_PAGE_DATA,
  GET_SETAIL_PAGE_DATA_SUCCESS,
  GET_SETAIL_PAGE_DATA_FAIL,
  GET_SETAIL_CONTACTUS_DATA,
  GET_SETAIL_CONTACTUS_DATA_SUCCESS,
  GET_SETAIL_CONTACTUS_DATA_FAIL,
  GET_SETAIL_SIGN_UP_DATA,
  GET_SETAIL_SIGN_UP_DATA_SUCCESS,
  GET_SETAIL_SIGN_UP_DATA_FAIL,
  GET_SETAIL_DEMO_BANNER_DATA,
  GET_SETAIL_DEMO_BANNER_DATA_SUCCESS,
  GET_SETAIL_DEMO_BANNER_DATA_FAIL,
} from "./constants";

export const getSetailPageData = (data) => ({
  type: GET_SETAIL_PAGE_DATA,
  data,
});

export const getSetailPageDataSuccess = (data) => ({
  type: GET_SETAIL_PAGE_DATA_SUCCESS,
  data,
});

export const getSetailPageDataFail = (data) => ({
  type: GET_SETAIL_PAGE_DATA_FAIL,
  data,
});

export const getSetailContactUsData = () => ({
  type: GET_SETAIL_CONTACTUS_DATA,
});

export const getSetailContactUsDataSuccess = (data) => ({
  type: GET_SETAIL_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getSetailContactUsDataFail = (data) => ({
  type: GET_SETAIL_CONTACTUS_DATA_FAIL,
  data,
});

export const getSetailSignUpData = () => ({
  type: GET_SETAIL_SIGN_UP_DATA,
});

export const getSetailSignUpDataSuccess = (data) => ({
  type: GET_SETAIL_SIGN_UP_DATA_SUCCESS,
  data,
});

export const getSetailSignUpDataFail = (data) => ({
  type: GET_SETAIL_SIGN_UP_DATA_FAIL,
  data,
});

export const getSetailDemoBannerData = () => ({
  type: GET_SETAIL_DEMO_BANNER_DATA,
});

export const getSetailDemoBannerDataSuccess = (data) => ({
  type: GET_SETAIL_DEMO_BANNER_DATA_SUCCESS,
  data,
});

export const getSetailDemoBannerDataFail = (data) => ({
  type: GET_SETAIL_DEMO_BANNER_DATA_FAIL,
  data,
});
