export const GET_SETAIL_PAGE_DATA = "GET_SETAIL_PAGE_DATA";
export const GET_SETAIL_PAGE_DATA_SUCCESS = "GET_SETAIL_PAGE_DATA_SUCCESS";
export const GET_SETAIL_PAGE_DATA_FAIL = "GET_SETAIL_PAGE_DATA_FAIL";
export const GET_SETAIL_CONTACTUS_DATA = "GET_SETAIL_CONTACTUS_DATA";
export const GET_SETAIL_CONTACTUS_DATA_SUCCESS =
  "GET_SETAIL_CONTACTUS_DATA_SUCCESS";
export const GET_SETAIL_CONTACTUS_DATA_FAIL = "GET_SETAIL_CONTACTUS_DATA_FAIL";
export const GET_SETAIL_SIGN_UP_DATA = "GET_SETAIL_SIGN_UP_DATA";
export const GET_SETAIL_SIGN_UP_DATA_SUCCESS =
  "GET_SETAIL_SIGN_UP_DATA_SUCCESS";
export const GET_SETAIL_SIGN_UP_DATA_FAIL = "GET_SETAIL_SIGN_UP_DATA_FAIL";
export const GET_SETAIL_DEMO_BANNER_DATA = "GET_SETAIL_DEMO_BANNER_DATA";
export const GET_SETAIL_DEMO_BANNER_DATA_SUCCESS =
  "GET_SETAIL_DEMO_BANNER_DATA_SUCCESS";
export const GET_SETAIL_DEMO_BANNER_DATA_FAIL =
  "GET_SETAIL_DEMO_BANNER_DATA_FAIL";
