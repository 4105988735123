import produce from "immer";
import {
  GET_MEDIA_PARTNERSHIP,
  GET_MEDIA_PARTNERSHIP_SUCCESS,
  GET_MEDIA_PARTNERSHIP_FAIL,
  GET_MEDIA_CONTACT_US,
  GET_MEDIA_CONTACT_US_SUCCESS,
  GET_MEDIA_CONTACT_US_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  mediaPartnership: [],
  mediaContactUs: [],
};

const mediaPartnershipReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_MEDIA_PARTNERSHIP:
        draft.loading = true;
        break;
      case GET_MEDIA_PARTNERSHIP_SUCCESS:
        draft.loading = false;
        draft.mediaPartnership = action.data;
        break;
      case GET_MEDIA_PARTNERSHIP_FAIL:
        draft.loading = false;
        break;
      case GET_MEDIA_CONTACT_US:
        draft.loading = true;
        break;
      case GET_MEDIA_CONTACT_US_SUCCESS:
        draft.loading = false;
        draft.mediaContactUs = action.data;
        break;
      case GET_MEDIA_CONTACT_US_FAIL:
        draft.loading = false;
        break;
      default:
        return draft;
    }
  });

export default mediaPartnershipReducer;
