import {
  GET_OFFICE_LOCATIONS,
  GET_OFFICE_LOCATIONS_SUCCESS,
  GET_OFFICE_LOCATIONS_FAIL,
  GET_CONTACTUSOFF_DATA,
  GET_CONTACTUSOFF_DATA_SUCCESS,
  GET_CONTACTUSOFF_DATA_FAIL,
  GET_OFFICE_PAGE_DATA,
  GET_OFFICE_PAGE_DATA_SUCCESS,
  GET_OFFICE_PAGE_DATA_FAIL,
} from "./constants";

export const getOfficeLocations = () => ({
  type: GET_OFFICE_LOCATIONS,
});

export const getOfficeLocationsSuccess = (data) => ({
  type: GET_OFFICE_LOCATIONS_SUCCESS,
  data,
});

export const getOfficeLocationsFail = (data) => ({
  type: GET_OFFICE_LOCATIONS_FAIL,
  data,
});

export const getContactusOff = (data) => ({
  type: GET_CONTACTUSOFF_DATA,
  data,
});

export const getContactusOffSuccess = (data) => ({
  type: GET_CONTACTUSOFF_DATA_SUCCESS,
  data,
});

export const getContactusOffFail = (data) => ({
  type: GET_CONTACTUSOFF_DATA_FAIL,
  data,
});

export const getOfficePageData = (data) => ({
  type: GET_OFFICE_PAGE_DATA,
  data,
});

export const getOfficePageDataSuccess = (data) => ({
  type: GET_OFFICE_PAGE_DATA_SUCCESS,
  data,
});

export const getOfficePageDataFail = (data) => ({
  type: GET_OFFICE_PAGE_DATA_FAIL,
  data,
});
