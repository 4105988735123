import React from "react";
import { Card, Row, Col } from "antd";
import { updateTextLenght } from "../../utils/helper";
import { FormattedDate, FormattedTime } from "react-intl";
import "./listview.scss";
import ListWrapper from "./listview";
import ShareIconComponent from "../shareicon";
import { Link } from "react-router-dom";
import { CURR_TZ } from "../../utils/constants";

const ListView = (data) => {
  const imgUrl = `url(${data.coverImage})`;
  return (
    <ListWrapper>
      <Card
        className="listview"
        bordered={false}
        cover={
          data.coverImage ? (
            <Link to={`/${data.language}/newsdetail/${data.id}`}>
              <div
                onClick={() => data.closeModal()}
                className="cover-image-div"
                style={{ backgroundImage: imgUrl }}
              ></div>
            </Link>
          ) : null // provide a default value when coverImage is falsy
        }
      >
        <div className="align-text-left">
          {data.description ? updateTextLenght(data.description, 200) : <></>}
          {data.metaTitle ? (
            <Card.Meta
              title={
                <div>
                  {data.metaLogo ? (
                    <div>
                      <Link
                        className="news-title-logo"
                        to={`/${data.language}/newsdetail/${data.id}`}
                      >
                        <img
                          onClick={() => data.closeModal()}
                          width={"100%"}
                          src={data.metaLogo}
                          alt={""}
                        ></img>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    onClick={() => data.closeModal()}
                    className="news-title-text"
                  >
                    {" "}
                    <Link
                      to={`/${data.language}/newsdetail/${data.id}`}
                      className="newsListTitleLink"
                    >
                      {data.metaTitle}
                    </Link>
                  </div>
                </div>
              }
              description={
                <Row gutter={0}>
                  {data.metaDescription && !data.coverImage && (
                    <Col span={24} className="news-description">
                      {data.metaDescription}
                    </Col>
                  )}

                  {data.newsDate && (
                    <Col span={24} className="card-footer">
                      <Row className="left-text-align">
                        <Col
                          offset={1}
                          sm={18}
                          md={20}
                          lg={18}
                          xl={20}
                          className="date-sect"
                        >
                          {" "}
                          {data.newsDate ? (
                            <>
                              <FormattedDate
                                value={new Date(data.newsDate)}
                                year="numeric"
                                month="short"
                                day="2-digit"
                                timeZone={CURR_TZ}
                              ></FormattedDate>{" "}
                              |{" "}
                              <FormattedTime
                                value={new Date(data.newsDate)}
                                timeZone={CURR_TZ}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>

                        <Col
                          sm={6}
                          md={4}
                          lg={6}
                          xl={4}
                          className="align-text-end"
                        >
                          <ShareIconComponent
                            id={data.id}
                            language={data.language}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              }
            />
          ) : (
            <></>
          )}
        </div>
      </Card>
    </ListWrapper>
  );
};
export default ListView;
