import { takeEvery, put } from "redux-saga/effects";
import { GET_PRIVACY_POLICY, GET_PRIVACY_POLICY_CONTACTUS } from "./constants";
import { get } from "../../utils/api";
import {
  getPrivacypolicyContactusSuccess,
  getPrivacypolicyContactusFail,
  getPrivacypolicyPageDataSuccess,
  getPrivacypolicyPageDataFail,
} from "./actions";

function* getPrivacypolicyContactus() {
  let data = yield get("/bwcms/globalcomponent/contact_us")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const privacypolicyData = yield data.data;
    yield put(getPrivacypolicyContactusSuccess(privacypolicyData));
  } else {
    yield put(getPrivacypolicyContactusFail(data));
  }
}

function* getPrivacypolicyPageData(pageName) {
  let data = yield get("/bwcms/page/privacy-policy")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(getPrivacypolicyPageDataSuccess(pageData));
  } else {
    yield put(getPrivacypolicyPageDataFail(data));
  }
}

function* privacypolicySaga() {
  yield takeEvery(GET_PRIVACY_POLICY_CONTACTUS, getPrivacypolicyContactus);
  yield takeEvery(GET_PRIVACY_POLICY, getPrivacypolicyPageData);
}

export default privacypolicySaga;
