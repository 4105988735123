import {
  GET_TRENDING_NEWS,
  GET_TRENDING_NEWS_SUCCESS,
  GET_TRENDING_NEWS_FAIL,
  GET_HOME_PAGE_DATA,
  GET_HOME_PAGE_DATA_SUCCESS,
  GET_HOME_PAGE_DATA_FAIL,
  GET_VIDEO_DATA,
  GET_VIDEO_DATA_SUCCESS,
  GET_VIDEO_DATA_FAIL,
  GET_WHOWEARE_DATA_FAIL,
  GET_WHOWEARE_DATA,
  GET_WHOWEARE_DATA_SUCCESS,
  GET_HOME_CONTACTUS_DATA,
  GET_HOME_CONTACTUS_DATA_SUCCESS,
  GET_HOME_CONTACTUS_DATA_FAIL,
  GET_TESTIMONIALS_DATA,
  GET_TESTIMONIALS_DATA_SUCCESS,
  GET_TESTIMONIALS_DATA_FAIL,
  GET_NEWSLETTER_SIGNUP_DATA,
  GET_NEWSLETTER_SIGNUP_DATA_SUCCESS,
  GET_NEWSLETTER_SIGNUP_DATA_FAIL,
  GET_LATEST_NEWS_DATA,
  GET_LATEST_NEWS_DATA_SUCCESS,
  GET_LATEST_NEWS_DATA_FAIL,
  GET_MULT_NEWS_DATA,
  GET_MULT_NEWS_DATA_SUCCESS,
  GET_MULT_NEWS_DATA_FAIL,
} from "./constants";

export const getTrendingNews = () => ({
  type: GET_TRENDING_NEWS,
});

export const getTrendingNewsSuccess = (data) => ({
  type: GET_TRENDING_NEWS_SUCCESS,
  data,
});

export const getTrendingNewsFail = (data) => ({
  type: GET_TRENDING_NEWS_FAIL,
  data,
});

export const getHomePageData = (data) => ({
  type: GET_HOME_PAGE_DATA,
  data,
});

export const getHomePageDataSuccess = (data) => ({
  type: GET_HOME_PAGE_DATA_SUCCESS,
  data,
});

export const getHomePageDataFail = (data) => ({
  type: GET_HOME_PAGE_DATA_FAIL,
  data,
});

export const getVideoData = () => ({
  type: GET_VIDEO_DATA,
});

export const getVideoDataSuccess = (data) => ({
  type: GET_VIDEO_DATA_SUCCESS,
  data,
});

export const getVideoDataFail = (data) => ({
  type: GET_VIDEO_DATA_FAIL,
  data,
});

export const getWhoweareData = () => ({
  type: GET_WHOWEARE_DATA,
});

export const getWhoweareDataSuccess = (data) => ({
  type: GET_WHOWEARE_DATA_SUCCESS,
  data,
});

export const getWhoweareDataFail = (data) => ({
  type: GET_WHOWEARE_DATA_FAIL,
  data,
});

export const getHomeContactUsData = () => ({
  type: GET_HOME_CONTACTUS_DATA,
});

export const getHomeContactUsDataSuccess = (data) => ({
  type: GET_HOME_CONTACTUS_DATA_SUCCESS,
  data,
});

export const getHomeContactUsDataFail = (data) => ({
  type: GET_HOME_CONTACTUS_DATA_FAIL,
  data,
});

export const getTestimonialsData = () => ({
  type: GET_TESTIMONIALS_DATA,
});

export const getTestimonialsDataSuccess = (data) => ({
  type: GET_TESTIMONIALS_DATA_SUCCESS,
  data,
});

export const getTestimonialsDataFail = (data) => ({
  type: GET_TESTIMONIALS_DATA_FAIL,
  data,
});

export const getNewsLetterSignUpData = () => ({
  type: GET_NEWSLETTER_SIGNUP_DATA,
});

export const getNewsLetterSignUpDataSuccess = (data) => ({
  type: GET_NEWSLETTER_SIGNUP_DATA_SUCCESS,
  data,
});

export const getNewsLetterSignUpDataFail = (data) => ({
  type: GET_NEWSLETTER_SIGNUP_DATA_FAIL,
  data,
});

export const getLatestNews = (data) => ({
  type: GET_LATEST_NEWS_DATA,
  data,
});

export const getLatestNewsSuccess = (data) => ({
  type: GET_LATEST_NEWS_DATA_SUCCESS,
  data,
});

export const getLatestNewsFail = (data) => ({
  type: GET_LATEST_NEWS_DATA_FAIL,
  data,
});

export const getMultNews = (data) => ({
  type: GET_MULT_NEWS_DATA,
  data,
});

export const getMultNewsSuccess = (data) => ({
  type: GET_MULT_NEWS_DATA_SUCCESS,
  data,
});

export const getMultNewsFail = (data) => ({
  type: GET_MULT_NEWS_DATA_FAIL,
  data,
});
