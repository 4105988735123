import produce from "immer";

import {
  GET_TRENDING_NEWS,
  GET_TRENDING_NEWS_SUCCESS,
  GET_TRENDING_NEWS_FAIL,
  GET_HOME_PAGE_DATA,
  GET_HOME_PAGE_DATA_SUCCESS,
  GET_HOME_PAGE_DATA_FAIL,
  GET_VIDEO_DATA,
  GET_VIDEO_DATA_FAIL,
  GET_VIDEO_DATA_SUCCESS,
  GET_WHOWEARE_DATA_FAIL,
  GET_WHOWEARE_DATA,
  GET_WHOWEARE_DATA_SUCCESS,
  GET_HOME_CONTACTUS_DATA,
  GET_HOME_CONTACTUS_DATA_SUCCESS,
  GET_HOME_CONTACTUS_DATA_FAIL,
  GET_TESTIMONIALS_DATA,
  GET_TESTIMONIALS_DATA_SUCCESS,
  GET_TESTIMONIALS_DATA_FAIL,
  GET_NEWSLETTER_SIGNUP_DATA,
  GET_NEWSLETTER_SIGNUP_DATA_SUCCESS,
  GET_NEWSLETTER_SIGNUP_DATA_FAIL,
  GET_LATEST_NEWS_DATA,
  GET_LATEST_NEWS_DATA_SUCCESS,
  GET_LATEST_NEWS_DATA_FAIL,
  GET_MULT_NEWS_DATA,
  GET_MULT_NEWS_DATA_SUCCESS,
  GET_MULT_NEWS_DATA_FAIL,
} from "./constants";

const initialState = {
  loading: false,
  trendingNews: [],
  homePageData: [],
  videoBaseData: [],
  contactUsData: [],
  testimonialsData: [],
  newsLetterSignUpData: [],
  whoweareData: [],
  latestNews: [],
  multimediaNews: [],
  loadLatestNews: false,
  loadMMNews: false,
  loadTrendNews: false,
};

const dashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TRENDING_NEWS:
        draft.loadTrendNews = true;
        break;
      case GET_TRENDING_NEWS_SUCCESS:
        draft.loadTrendNews = false;
        draft.trendingNews = action.data;
        break;
      case GET_TRENDING_NEWS_FAIL:
        draft.loadTrendNews = false;
        break;
      case GET_HOME_PAGE_DATA:
        draft.loading = true;
        break;
      case GET_HOME_PAGE_DATA_SUCCESS:
        draft.loading = false;
        draft.homePageData = action.data;
        break;
      case GET_HOME_PAGE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_VIDEO_DATA:
        draft.loading = true;
        break;
      case GET_VIDEO_DATA_SUCCESS:
        draft.loading = false;
        draft.videoBaseData = action.data;
        break;
      case GET_VIDEO_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_WHOWEARE_DATA:
        draft.loading = true;
        break;
      case GET_WHOWEARE_DATA_SUCCESS:
        draft.loading = false;
        draft.whoweareData = action.data;
        break;
      case GET_WHOWEARE_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_HOME_CONTACTUS_DATA:
        draft.loading = true;
        break;
      case GET_HOME_CONTACTUS_DATA_SUCCESS:
        draft.loading = false;
        draft.contactUsData = action.data;
        break;
      case GET_HOME_CONTACTUS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_TESTIMONIALS_DATA:
        draft.loading = true;
        break;
      case GET_TESTIMONIALS_DATA_SUCCESS:
        draft.loading = false;
        draft.testimonialsData = action.data;
        break;
      case GET_TESTIMONIALS_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_NEWSLETTER_SIGNUP_DATA:
        draft.loading = true;
        break;
      case GET_NEWSLETTER_SIGNUP_DATA_SUCCESS:
        draft.loading = false;
        draft.newsLetterSignUpData = action.data;
        break;
      case GET_NEWSLETTER_SIGNUP_DATA_FAIL:
        draft.loading = false;
        break;
      case GET_LATEST_NEWS_DATA:
        draft.loadLatestNews = true;
        break;
      case GET_LATEST_NEWS_DATA_SUCCESS:
        draft.loadLatestNews = false;
        draft.latestNews = action.data;
        break;
      case GET_LATEST_NEWS_DATA_FAIL:
        draft.loadLatestNews = false;
        break;
      case GET_MULT_NEWS_DATA:
        draft.loadMMNews = true;
        break;
      case GET_MULT_NEWS_DATA_SUCCESS:
        draft.loadMMNews = false;
        draft.multimediaNews = action.data;
        break;
      case GET_MULT_NEWS_DATA_FAIL:
        draft.loadMMNews = false;
        break;
      default:
        return draft;
    }
  });

export default dashboardReducer;
