export const GET_POPULAR_NEWS_SUBCAT_DATA = "GET_POPULAR_NEWS_SUBCAT_DATA";
export const GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS =
  "GET_POPULAR_NEWS_SUBCAT_DATA_SUCCESS";
export const GET_POPULAR_NEWS_SUBCAT_DATA_FAIL =
  "GET_POPULAR_NEWS_SUBCAT_DATA_FAIL";
export const GET_NEWS_SUBCAT_DATA = "GET_NEWS_SUBCAT_DATA";
export const GET_NEWS_SUBCAT_DATA_SUCCESS = "GET_NEWS_SUBCAT_DATA_SUCCESS";
export const GET_NEWS_SUBCAT_DATA_FAIL = "GET_NEWS_SUBCAT_DATA_FAIL";

export const GET_NEWS_CAT_DATA = "GET_NEWS_CAT_DATA";
export const GET_NEWS_CAT_DATA_SUCCESS = "GET_NEWS_CAT_DATA_SUCCESS";
export const GET_NEWS_CAT_DATA_FAIL = "GET_NEWS_CAT_DATA_FAIL";

export const UPDATE_CAT_PAGE_NO = "UPDATE_CAT_PAGE_NO";
export const UPDATE_SUBCAT_PAGE_NO = "UPDATE_SUBCAT_PAGE_NO";

export const GET_NEWS_CAT_MEDIA_BANNER = "GET_NEWS_CAT_MEDIA_BANNER";
export const GET_NEWS_CAT_MEDIA_BANNER_SUCCESS =
  "GET_NEWS_CAT_MEDIA_BANNER_SUCCESS";
export const GET_NEWS_CAT_MEDIA_BANNER_FAIL = "GET_NEWS_CAT_MEDIA_BANNER_FAIL";
export const GET_NEWS_CAT_SIGN_UP_NEWS_LETTER =
  "GET_NEWS_CAT_SIGN_UP_NEWS_LETTER";
export const GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS =
  "GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_SUCCESS";
export const GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL =
  "GET_NEWS_CAT_SIGN_UP_NEWS_LETTER_FAIL";
export const GET_NEWS_CAT_PAGE_DATA = "GET_NEWS_CAT_PAGE_DATA";
export const GET_NEWS_CAT_PAGE_DATA_SUCCESS = "GET_NEWS_CAT_PAGE_DATA_SUCCESS";
export const GET_NEWS_CAT_PAGE_DATA_FAIL = "GET_NEWS_CAT_PAGE_DATA_FAIL";
export const GET_NEWS_MM_LANG_DATA = "GET_NEWS_MM_LANG_DATA";
export const GET_NEWS_MM_LANG_DATA_SUCCESS = "GET_NEWS_MM_LANG_DATA_SUCCESS";
export const GET_NEWS_MM_LANG_DATA_FAIL = "GET_NEWS_MM_LANG_DATA_FAIL";
export const GET_NEWS_CAT_FOLLOWSOCIAL_DATA = "GET_NEWS_CAT_FOLLOWSOCIAL_DATA";
export const GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS =
  "GET_NEWS_CAT_FOLLOWSOCIAL_DATA_SUCCESS";
export const GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL =
  "GET_NEWS_CAT_FOLLOWSOCIAL_DATA_FAIL";
export const GET_NEWS_CAT_POPULAR_NEWS_DETAILS =
  "GET_NEWS_CAT_POPULAR_NEWS_DETAILS";
export const GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS =
  "GET_NEWS_CAT_POPULAR_NEWS_DETAILS_SUCCESS";
export const GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL =
  "GET_NEWS_CAT_POPULAR_NEWS_DETAILS_FAIL";

export const GET_NEWS_CAT_PRESSPASSDATA = "GET_NEWS_CAT_PRESSPASSDATA";
export const GET_NEWS_CAT_PRESSPASSDATA_SUCCESS =
  "GET_NEWS_CAT_PRESSPASSDATA_SUCCESS";
export const GET_NEWS_CAT_PRESSPASSDATA_FAIL =
  "GET_NEWS_CAT_PRESSPASSDATA_FAIL";

export const UPDATE_SUBCAT_COUNT = "UPDATE_SUBCAT_COUNT";
export const UPDATE_LANG_PAGE_TITLE = "UPDATE_LANG_PAGE_TITLE";

export const UPDATE_DATE_TEXT_FILTER = "UPDATE_DATE_TEXT_FILTER";
