import { Link } from "react-router-dom";
import { FormattedDate, FormattedTime } from "react-intl";
import { CURR_TZ } from "./constants";

export const updateTextLenght = (text, number) => {
  return text.length > number ? `${text.slice(0, number)}...` : text;
};

export const formatNewsDetail = (newsData) => {
  let newsDetail = [];

  //Right heading logo
  if (newsData.associations) {
    newsData.associations.map((data) => {
      if (data.name === "Logo") {
        data.renditions.map((data1) => {
          if (data1.name === "Lowres") {
            newsDetail["logo"] = data1.href;
          }
        });
      }

      if (
        data.name === "Video" ||
        data.name === "Photo" ||
        data.name === "Logo" ||
        data.name === "Audio" ||
        data.name === "Slideshow"
      ) {
        if (!newsDetail["media"]) {
          newsDetail["media"] = [];
        }
        newsDetail["media"].push(data);
      }
    });
  }

  //Body, release summary, contact
  if (newsData.bodies) {
    newsData.bodies.map((data) => {
      if (data.role === "Abstract") {
        newsDetail["relesesummary"] = {
          title: "Release Summary",
          description: data.value,
        };
      }

      if (data.role === "Contact") {
        newsDetail["contact"] = {
          title: "Contact",
          description: data.value,
        };
      }

      if (data.role === "Body") {
        let parser = new DOMParser();
        let doc = parser.parseFromString(data.value, "text/html");
        let elements = doc.activeElement.childNodes;
        let eleData = [];
        if (elements) {
          elements.forEach((data, index) => {
            if (data.tagName === "TABLE") {
              let ele =
                `<div class="bw-release-table-js" id='table-${index}'>` +
                data.outerHTML +
                "</div>";
              eleData.push(ele);
            } else {
              if (
                data.getElementsByTagName("img") &&
                data.getElementsByTagName("img")[0]
              ) {
                // do nothing
              } else if (data.id === "news-body-cta") {
                // do nothing
              } else if (data.id.includes("mmgallerylink")) {
                //do nothing
              } else {
                eleData.push(data.outerHTML);
              }
            }
          });
        }

        newsDetail["body"] = eleData;
      }

      if (data.role === "TweetText") {
        newsDetail["tweettext"] = {
          title: "Tweet Text",
          description: data.value,
        };
      }

      // pull quotes for twitter
      if (data.role === "PullQuote") {
        if (!newsDetail["pullquote"]) {
          newsDetail["pullquote"] = [];
        }
        newsDetail["pullquote"].push(data.value);
      }
    });
  }

  // headline and subheadline
  if (newsData.headlines) {
    newsData.headlines.map((data) => {
      if (data.role === "HeadLine" && data.contenttype === "text/xhtml") {
        newsDetail["headline"] = data.value;
      }

      if (data.role === "SubHeadLine") {
        newsDetail["subheadline"] = data.value;
      }
    });
  }

  // hashtags, cashtags and social media profiles
  if (newsData.descriptions) {
    newsData.descriptions.map((data) => {
      if (data.role === "BWHashTag") {
        if (!newsDetail["bwhashtags"]) {
          newsDetail["bwhashtags"] = [];
        }
        newsDetail["bwhashtags"].push(data.value);
      }

      if (data.role === "BWCashTag") {
        if (!newsDetail["bwcashtags"]) {
          newsDetail["bwcashtags"] = [];
        }
        newsDetail["bwcashtags"].push(data.value);
      }
    });
  }

  //Media Profiles
  if (newsData.organisations) {
    if (newsData.organisations[0] && newsData.organisations[0].contactinfo) {
      newsData.organisations[0].contactinfo.map((data) => {
        if (
          data.role === "Pinterest" ||
          data.role === "LinkedIn" ||
          data.role === "YouTube" ||
          data.role === "Twitter" ||
          data.role === "Instagram" ||
          data.role === "Facebook"
        ) {
          if (!newsDetail["profiles"]) {
            newsDetail["profiles"] = [];
          }
          let obj = data;
          let val = { value: data.value, name: data.name };
          obj.value = val;
          newsDetail["profiles"].push(obj);
        }
      });
    }
  }

  //social media account is identified as belonging to an Individual
  if (newsData.people) {
    if (newsData.people[0] && newsData.people[0].contactinfo) {
      newsData.people[0].contactinfo.map((data) => {
        if (
          data.role === "Pinterest" ||
          data.role === "LinkedIn" ||
          data.role === "YouTube" ||
          data.role === "Twitter" ||
          data.role === "Instagram" ||
          data.role === "Facebook"
        ) {
          if (!newsDetail["profiles"]) {
            newsDetail["profiles"] = [];
          }
          let obj = data;
          let val = { value: data.value, name: data.name };
          obj.value = val;
          newsDetail["profiles"].push(obj);
        }
      });
    }
  }

  //Release date
  if (newsData.versioncreated) {
    newsDetail["releaseDate"] = newsData.versioncreated;
  }

  // logo title
  if (newsData.infosources) {
    newsData.infosources.map((data) => {
      if (!data.uri) {
        newsDetail["logoTitle"] = data.name;
      }
    });
  }

  //location
  if (newsData.located) {
    newsDetail["located"] = newsData.located;
  }

  //copy right holder
  if (newsData.copyrightholder) {
    newsDetail["copyrightholder"] = newsData.copyrightholder;
  }

  // id
  if (newsData.altids) {
    newsData.altids.map((data) => {
      if (data.role === "NewsItemId") {
        newsDetail["id"] = data.value;
      }
    });
  }

  // language
  if (newsData.language) {
    newsDetail["language"] = newsData.language;
  }

  return newsDetail;
};

export const formatNewsData = (newsData) => {
  let newsDetail = [];
  //Cover Image
  if (newsData.associations) {
    newsData.associations.map((data) => {
      if (data.name === "Logo") {
        data.renditions.map((data1) => {
          if (data1.name === "Lowres") {
            newsDetail["logo"] = data1.href;
          }
        });
      }

      if (data.name === "Photo") {
        data.renditions.map((data1) => {
          if (data1.name === "Lowres") {
            newsDetail["cover"] = data1.href;
          }
        });
      }

      if (data.name === "Video") {
        data.renditions.map((data1) => {
          if (data1.name === "Broadband") {
            newsDetail["video"] = data1.href;
          }
        });
      }
    });
  }

  //Body
  if (newsData.bodies) {
    newsData.bodies.map((data) => {
      if (data.role === "Body") {
        let doc = new DOMParser().parseFromString(data.value, "text/html");
        newsDetail["body"] = {
          title: "body",
          description: doc.querySelector("p").innerText,
        };
      }

      if (data.role === "TweetText") {
        newsDetail["tweettext"] = {
          title: "Tweet Text",
          description: data.value,
        };
      }
    });
  }

  // headline and subheadline
  if (newsData.headlines) {
    newsData.headlines.map((data) => {
      if (data.role === "HeadLine" && data.contenttype === "text/plain") {
        newsDetail["headline"] = data.value;
      }

      if (data.role === "SubHeadLine") {
        newsDetail["subheadline"] = data.value;
      }
    });
  }

  // date
  if (newsData.versioncreated) {
    newsDetail["date"] = newsData.versioncreated;
  }

  // id
  if (newsData.altids) {
    newsData.altids.map((data) => {
      if (data.role === "NewsItemId") {
        newsDetail["id"] = data.value;
      }
    });
  }

  // language
  if (newsData.language) {
    newsDetail["language"] = newsData.language;
  }

  return newsDetail;
};

export const generateLink = (title, url, target, heading, active) => {
  if (target === "" && !heading) {
    return <Link to={url}>{title}</Link>;
  } else if (heading) {
    return (
      <a
        className={active ? "" : "isDisabled"}
        href={
          url?.includes("/services") &&
          localStorage.getItem("lang") &&
          localStorage.getItem("lang") !== "en"
            ? "/" + localStorage.getItem("lang") + url
            : url
        }
        target={target}
      >
        <h3>{title}</h3>
      </a>
    );
  } else {
    return (
      <a
        className={active ? "" : "isDisabled"}
        href={
          url?.includes("/services") &&
          localStorage.getItem("lang") &&
          localStorage.getItem("lang") !== "en"
            ? "/" + localStorage.getItem("lang") + url
            : url
        }
        target={target}
      >
        {title}
      </a>
    );
  }
};

export const formatMoreNews = (newsData) => {
  let moreNews = [];

  // headline and subheadline
  if (newsData.headlines) {
    newsData.headlines.map((data) => {
      if (data.role === "HeadLine" && data.contenttype === "text/plain") {
        moreNews["headline"] = data.value;
      }
    });
  }

  // id
  if (newsData.altids) {
    newsData.altids.map((data) => {
      if (data.role === "NewsItemId") {
        moreNews["id"] = data.value;
      }
    });
  }
  // language
  if (newsData.language) {
    moreNews["language"] = newsData.language;
  }

  // date
  if (newsData.versioncreated) {
    moreNews["date"] = newsData.versioncreated;
  }
  return moreNews;
};

export const formatDate = (datestr) => {
  return (
    <>
      <FormattedDate
        value={new Date(datestr)}
        year="numeric"
        month="short"
        day="2-digit"
        timeZone={CURR_TZ}
      ></FormattedDate>
    </>
  );
};

export const formatTime = (datestr) => {
  return (
    <>
      <FormattedTime value={new Date(datestr)} timeZone={CURR_TZ} />
    </>
  );
};

export const genRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  let array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

export const formatSearchNewsData = (newsData) => {
  let newsDetail = [];
  //Cover Image
  if (newsData.associations) {
    newsData.associations.map((data) => {
      if (data.name === "Logo") {
        data.renditions.map((data1) => {
          if (data1.name === "Lowres") {
            newsDetail["logo"] = data1.href;
          }
        });
      }

      if (data.name === "Photo") {
        data.renditions.map((data1) => {
          if (data1.name === "Lowres") {
            newsDetail["cover"] = data1.href;
          }
        });
      }

      if (data.name === "Video") {
        data.renditions.map((data1) => {
          if (data1.name === "Broadband") {
            newsDetail["video"] = data1.href;
          }
        });
      }
    });
  }

  //Body
  if (newsData.body) {
    let doc = new DOMParser().parseFromString(newsData.body, "text/html");
    newsDetail["body"] = {
      title: "body",
      description: doc.body.innerHTML,
    };
  }

  // headline and subheadline
  if (newsData.headline) {
    newsDetail["headline"] = newsData.headline;
  }

  // date
  if (newsData.versioncreated) {
    newsDetail["date"] = newsData.versioncreated;
  }

  // id
  if (newsData.newsItemId) {
    newsDetail["id"] = newsData.newsItemId;
  }

  // language
  if (newsData.language) {
    newsDetail["language"] = newsData.language;
  }

  return newsDetail;
};
